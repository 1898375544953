import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Pguid, Jguid } from "../apiConfig/axiosDev";
import {
  sendDeleteRestRequestAppJson,
  // sendGetRestRequestAssessment,
  sendGetRestRequest,
  sendGetRestRequestJson,
  sendPostRestRequest,
  sendPostRestRequestAppJson,
  sendPutRestRequestAppJson,
} from "../apiConfig/axiosInstance";
import { log_debug } from "../apiHelpers/logger";
import { getGuid } from "../apiConfig/globalvariables";

const initialState = {
  data: {},
  status: "idle",
  loading: false,
  error: "",
};
// ============================Assessment records===========================

// assessment
export const dummyAssessment = createAsyncThunk(
  "partner/dummyAssessment",
  async (assGuid) => {
    try {
      const response = await sendGetRestRequest(`v1/assessment/${assGuid}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching dummy Assessment:", error);
      throw error; // Rethrow the error to be handled by the caller
    }
  },
);
// assessment
export const assessmentMCQ = createAsyncThunk(
  "partner/assessmentMCQ",
  async (guid) => {
    try {
      const response = await sendGetRestRequest(
        `v1/assessment/assessmentquestion/${guid}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching assessment MCQ:", error);
      throw error;
    }
  },
);

// assessment
export const assessmentCoding = createAsyncThunk(
  "partner/assessmentCoding",
  async (guid) => {
    try {
      const response = await sendGetRestRequest(
        `v1/assessment/assessmentquestion/${guid}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching assessment Coding:", error);
      throw error;
    }
  },
);

// assessment
export const assessmentBehavioural = createAsyncThunk(
  "partner/assessmentBehavioural",
  async (guid) => {
    try {
      const response = await sendGetRestRequest(
        `v1/assessment/assessmentquestion/${guid}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching assessment Behavioural:", error);
      throw error;
    }
  },
);

// ============================Assessment records===========================

// To get list of my candidate
export const mycandidates = createAsyncThunk(
  "partner/individual",
  async ({ skills, locations, currentPage, filtervalue }) => {
    try {
      log_debug("currentPageapi", skills);

      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/individual/?skills=${skills}&locations=${locations}&limit=10&offset=${currentPage}&searchText=${filtervalue}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching my candidates:", error);
      throw error;
    }
  },
);

// To get list of my candidate
export const candidatesOverview = createAsyncThunk(
  "partner/candidatesOverview",
  async (Iguid) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${getGuid() || Pguid}/individual/${Iguid}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching candidates overview:", error);
      throw error;
    }
  },
);

// Upload resume to add candidate by resume parser
export const addCandidateResume = createAsyncThunk(
  "partner/uploadResumeAddcandidate",
  async (data) => {
    try {
      log_debug("addcandidateinfo", data);
      const response = await sendPostRestRequest(
        `v1/partner/parse/resume`,
        data,
      );
      return response.data;
    } catch (error) {
      console.error("Error uploading candidate resume:", error);
      throw error;
    }
  },
);

export const CandidateResumeProfileEdit = createAsyncThunk(
  "partner/CandidateResumeProfileEdit",
  async (data) => {
    try {
      log_debug("CandidateResumeProfileEditdata", data.individual.guid);
      const response = await sendPostRestRequestAppJson(
        `v1/individual/${data.individual.guid}`,
        data,
      );
      return response.data;
    } catch (error) {
      console.error("Error editing candidate resume profile:", error);
      throw error;
    }
  },
);

export const CandidateResumeAddEditData = createAsyncThunk(
  "partner/CandidateResumeAddEditData",
  async (data) => {
    try {
      // log_debug("resumeresumeresume", resume);
      const response = await sendPostRestRequestAppJson(
        `v1/resume/${data.resume.guid}`,
        data,
      );
      return response.data;
    } catch (error) {
      console.error("Error adding/editing candidate resume data:", error);
      throw error;
    }
  },
);

// addCandidatesLocation dropdown
export const addCandidatesLocation = createAsyncThunk(
  "partner/addCandidatesLocation",
  async () => {
    try {
      const response = await sendGetRestRequest(`v1/enumcity?limit=2000`);
      return response.data;
    } catch (error) {
      console.error("Error fetching candidates location dropdown data:", error);
      throw error;
    }
  },
);

// addCandidatesSkills dropdown
export const addCandidatesSkills = createAsyncThunk(
  "partner/addCandidatesSkills",
  async () => {
    try {
      const response = await sendGetRestRequest(`/v1/enumskill?limit=2000`);
      return response.data;
    } catch (error) {
      console.error("Error fetching candidates skills dropdown data:", error);
      throw error;
    }
  },
);

// addCandidatesStatus dropdown
export const addCandidatesStatus = createAsyncThunk(
  "partner/addCandidatesStatus",
  async () => {
    try {
      const response = await sendGetRestRequest(`v1/enumindividualstatus`);
      return response.data;
    } catch (error) {
      console.error("Error fetching candidates status dropdown data:", error);
      throw error;
    }
  },
);

// form Submission
export const submitIndividualForm = createAsyncThunk(
  "partner/submitIndividualForm",
  async (data, { rejectWithValue }) => {
    try {
      const response = await sendPostRestRequestAppJson(
        `/v1/partner/${getGuid() || Pguid}/individual`,
        data,
      );
      return response.data;
    } catch (error) {
      console.error("Error submitting individual form:", error);
      return rejectWithValue(error.response.data); // Pass the error response to the payload
    }
  },
);

// To get list of machingCandidate
export const machingJob = createAsyncThunk(
  "partner/machingJob",
  async ({ skills, locations, currentPage, filtervalue }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/matchingjobs?skills=${skills}&locations=${locations}&limit=10&offset=${currentPage}&search_text=${filtervalue}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching matching jobs:", error);
      throw error;
    }
  },
);

export const machingJobApplyJob = createAsyncThunk(
  "partner/machingJobApplyJob",
  async (data) => {
    try {
      log_debug("individualmapping", data);
      const response = await sendPostRestRequestAppJson(
        `v1/individualjobmapping/${getGuid() || Pguid}/applyjobs`,
        data,
      );
      return response.data;
    } catch (error) {
      console.error("Error applying for matching job:", error);
      throw error;
    }
  },
);

// export const machingCandidateApplyJob = createAsyncThunk(
//   "partner/machingCandidateApplyJob",
//   async (data) => {
//     try {
//       log_debug("individualmapping", data);
//       const response = await sendPostRestRequestAppJson(
//         `v1/individualjobmapping/${getGuid() || Pguid}/applyjobs`,
//         data,
//       );
//       return response.data;
//     } catch (error) {
//       console.error("Error applying for matching candidate job:", error);
//       throw error;
//     }
//   },
// );

export const Shortlistedjobsdata = createAsyncThunk(
  "partner/Shortlistedjobsdata",
  async ({ skills, locations, currentPage, filtervalue }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/jobs/?skills=${skills}&locations=${locations}&status=shortlisted&limit=10&offset=${currentPage}&search_text=${filtervalue}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching shortlisted jobs data:", error);
      throw error;
    }
  },
);

export const Invitedjobsdata = createAsyncThunk(
  "partner/Invitedjobsdata",
  async ({ skills, locations, currentPage, filtervalue }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/jobs/?skills=${skills}&locations=${locations}&status=invited&limit=10&offset=${currentPage}&search_text=${filtervalue}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching invited jobs data:", error);
      throw error;
    }
  },
);

//To get the list of all jobs
export const Alljobsdata = createAsyncThunk(
  "partner/alljobs",
  async ({ skills, locations, currentPage, filtervalue,jobType }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/fetch-all-jobs/?skills=${skills}&locations=${locations}&jobType=${jobType}&limit=10&offset=${
          currentPage ? currentPage : 0
        }&searchText=${filtervalue}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching all jobs data:", error);
      throw error;
    }
  },
);

// --------------------My Candidate Offcanvas Tabs start-----------------

//To get the list of shortlistedIndividualCandidate
export const shortlistedIndividualCandidate = createAsyncThunk(
  "partner/shortlistedIndividualCandidate",
  async ({ Indguid, currentPage }) => {
    try {
      const response = await sendGetRestRequest(
        `/v1/individual/${Indguid}/jobdescription/status/shortlisted?limit=10&offset=${
          currentPage ? currentPage : 0
        }`,
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching shortlisted individual candidate data:",
        error,
      );
      throw error;
    }
  },
);

//To get the list of InvitationIndividualCandidate
export const InvitationIndividualCandidate = createAsyncThunk(
  "partner/InvitationIndividualCandidate",
  async ({ Indguid, currentPage }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/individual/${Indguid}/jobdescription/status/invited?limit=10&offset=${currentPage}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching invited individual candidate data:", error);
      throw error;
    }
  },
);

//To get the list of MachingIndividualCandi
export const MachingIndividualCandi = createAsyncThunk(
  "partner/MachingIndividualCandi",
  async ({ Indguid, currentPage }) => {
    try {
      log_debug("individualguid", Indguid);
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/individual/${Indguid}/jobdescription?limit=10&offset=${currentPage}`,
      );
      // const response = await sendGetRestRequest(
      //   `v1/partner/${getGuid() || Pguid}/fetch-all-jobs/?skills=&locations=&limit=10&offset=${currentPage}&searchText=`,
      // );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching matching individual candidates:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

//To get the list of AssessmentIndividualCandi
export const AssessmentIndividualCandi = createAsyncThunk(
  "partner/AssessmentIndividualCandi",
  async ({ Indguid, currentPage }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/individual/${Indguid}/assessment?limit=10&offset=${currentPage}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error(
        "Error fetching assessment for individual candidate:",
        error,
      );
      throw error; // Rethrow the error to indicate failure
    }
  },
);

//To get the list of BGVIndividualCandi
export const BGVIndividualCandi = createAsyncThunk(
  "partner/BGVIndividualCandi",
  async (indguid) => {
    try {
      const response = await sendGetRestRequest(
        `v1/individual/${indguid}/backgroundverification`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching BGV individual candidate:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

//To get the list of BGVTypeDropdown
export const BGVTypeDropdown = createAsyncThunk(
  "partner/BGVTypeDropdown",
  async () => {
    try {
      const response = await sendGetRestRequest(`/v1/enumbgvtype`);
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching BGV type dropdown:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

//To get the list of BGVTypeDropdown
export const BGVTypeDropdownVerifiers = createAsyncThunk(
  "partner/BGVTypeDropdownVerifiers",
  async () => {
    try {
      const response = await sendGetRestRequest(`/v1/backgroundverifier`);
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching BGV type dropdown verifiers:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

// myCandiDetailsShortlistedRole
export const myCandiDetailsShortlistedRole = createAsyncThunk(
  "partner/myCandiDetailsShortlistedRole",
  async () => {
    try {
      const response = await sendGetRestRequest(`v1/enumjobrole?limit=2000`);
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error(
        "Error fetching candidate details for shortlisted roles:",
        error,
      );
      throw error; // Rethrow the error to indicate failure
    }
  },
);

// --------------------My Candidate Offcanvas Tabs END-----------------
//---------------------Job Invitations offcanvas Tabs--------------

export const JobDescriptionJobInvitation = createAsyncThunk(
  "partner/JobDescriptionJobInvitation",
  async () => {
    try {
      const response = await sendGetRestRequest(`/v1/jobdescription/${Jguid}`);
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching job description job invitation:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

//---------------------Job Invitations offcanvas Tabs--------------
//---------------------Interview start-----------------

// To get list of candidateInterview
export const candidateInterview = createAsyncThunk(
  "partner/candidateInterview",
  async ({ currentPage, filtervalue }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/interview?limit=10&offset=${currentPage}&searchText=${filtervalue}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching candidate interview:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

//---------------------Interview end-----------------
//---------------------Job Offer start-----------------

// To get list of my candidate Dashboard Tabs Mycandidate
export const DashboardTabsMycandidate = createAsyncThunk(
  "dashboard/DashboardTabsMycandidate",
  async ({ from_date, to_date, currentPage }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/individual?from_date=${from_date}&to_date=${to_date}&limit=10&offset=${currentPage}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching dashboard my candidate tabs:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

// To get list of Dashboard Tabs Shortlisted
export const DashboardTabsShortlisted = createAsyncThunk(
  "dashboard/DashboardTabsShortlisted",
  async ({ from_date, to_date, currentPage }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/jobs/?status=shortlisted&from_date=${from_date}&to_date=${to_date}&limit=10&offset=${currentPage}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching dashboard shortlisted tabs:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

// To get list of DashboardTabsInterview
export const DashboardTabsInterview = createAsyncThunk(
  "dashboard/DashboardTabsInterview",
  async ({ from_date, to_date, currentPage }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/interview?from_date=${from_date}&to_date=${to_date}&limit=10&offset=${currentPage}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching dashboard interview tabs:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

// To get list of DashboardTabsContract
export const DashboardTabsContract = createAsyncThunk(
  "dashboard/DashboardTabsContract",
  async ({ from_date, to_date, currentPage }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/partner_contract?from_date=${from_date}&to_date=${to_date}&limit=10&offset=${currentPage}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching dashboard contract tabs:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

// To get list of dashboardOffer
export const dashboardOffer = createAsyncThunk(
  "partner/dashboardOffer",
  async ({ currentPage, filtervalue }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/offer?limit=10&offset=${currentPage}&searchText=${filtervalue}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching dashboard offers:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

export const CandidatureHistory = createAsyncThunk(
  "partner/CandidatureHistory",
  async (ind_his) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${getGuid() || Pguid}/individual_history/${ind_his}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching candidature history:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

//---------------------Job Offer end-----------------

// MatchingCandidates--------
export const matchingCandidates = createAsyncThunk(
  "partner/matchingCandidates",
  async ({ JguidUser, currentPage }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/individual?jobdescription_guid=${JguidUser}&limit=10&offset=${
          currentPage ? currentPage : 0
        }`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching matching candidates:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

// shortlistedCandidates--------
export const shortlistedCandidates = createAsyncThunk(
  "partner/shortlistedCandidates",
  async ({ JguidUser, currentPage }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/individual/jobdescription/${JguidUser}/status/shortlisted?limit=10&offset=${
          currentPage ? currentPage : 0
        }`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching shortlisted candidates:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

export const invitedCandidates = createAsyncThunk(
  "partner/invitedCandidates",
  async ({ JguidUser, currentPage }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/individual/jobdescription/${JguidUser}/status/invited?limit=10&offset=${currentPage}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching invited candidates:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

// ================History Tabs start=====================================

export const CandidateHistory = createAsyncThunk(
  "partner/CandidateHistory",
  async (Iguid) => {
    try {
      const response = await sendGetRestRequest(
        `v1/individualhistory/individual/${Iguid}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching candidate history:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

export const JobHistory = createAsyncThunk(
  "partner/JobHistory",
  async (Jobguid) => {
    try {
      const response = await sendGetRestRequest(
        `v1/jobdescriptionhistory/jobdescription/${Jobguid}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching job history:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

export const interviewHistory = createAsyncThunk(
  "partner/interviewHistory",
  async (Iguid) => {
    try {
      const response = await sendGetRestRequest(
        `v1/interview/individual/${Iguid}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching interview history:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

// ================Partner Contract start=====================================

export const Contract = createAsyncThunk(
  "partner/Contract",
  async ({ currentPage, filtervalue }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/partner_contract?limit=10&offset=${currentPage}&searchText=${filtervalue}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching partner contracts:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

export const ContractHistory = createAsyncThunk(
  "partner/ContractHistory",
  async (ConGuid) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/partner_contract_history/${ConGuid}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching contract history:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

export const IndividualDetail = createAsyncThunk(
  "partner/IndividualDetail",
  async (indvGuid) => {
    try {
      const response = await sendGetRestRequest(`v1/individual/${indvGuid}`);
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching individual details:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

export const JobDetail = createAsyncThunk(
  "partner/JobDetail",
  async (jobGuid) => {
    try {
      const response = await sendGetRestRequest(`v1/jobdescription/${jobGuid}`);
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching job details:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

export const getProfileDetails = createAsyncThunk(
  "Parter/profile",
  async () => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${getGuid() || Pguid}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching partner profile details:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

//update partner profile details
export const postPartnerProfileDetails = createAsyncThunk(
  "partner/postPartnerProfileDetails",
  async (fData) => {
    try {
      log_debug("received form data", fData);
      const response = await sendPostRestRequestAppJson(
        `v1/partner/${getGuid() || Pguid}`,
        fData,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error posting partner profile details:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

// ============Dashboard start==========================

export const DashboardCounter = createAsyncThunk(
  "partner/DashboardCounter",
  async ({ from_date, to_date }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/dashboard/count?from_date=${from_date}&to_date=${to_date}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching dashboard counter data:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

export const DashboardApexchart = createAsyncThunk(
  "partner/DashboardApexchart",
  async ({ from_date, to_date }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/dashboard/graph?from_date=${from_date}&to_date=${to_date}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching dashboard apex chart data:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

// ============Dashboard end==========================

export const GlobalSearchRecord = createAsyncThunk(
  "partner/GlobalSearchRecord",
  async ({ globalSearch }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/individual/search/${globalSearch}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error performing global search:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

// ================Partner Contract end=====================================

export const CandidateDetailscanvasInterview = createAsyncThunk(
  "partner/CandidateDetailscanvasInterview",
  async ({ indiguid, currentPage }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/individual/${indiguid}/interview_details?limit=10&offset=${currentPage}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching candidate interview details:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

export const CandidateDetailscanvasOffer = createAsyncThunk(
  "partner/CandidateDetailscanvasOffer",
  async ({ indiguid, currentPage }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/individual/${indiguid}/offer_details?limit=10&offset=${currentPage}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching candidate offer details:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

export const CandidateDetailscanvasContract = createAsyncThunk(
  "partner/CandidateDetailscanvasContract",
  async ({ indiguid, currentPage }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/individual/${indiguid}/contract_details?limit=10&offset=${currentPage}`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching candidate contract details:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

export const CandidateAssessmentInvitation = createAsyncThunk(
  "partner/CandidateAssessmentInvitation",
  async (data) => {
    try {
      log_debug("assessment invite data", data);
      const response = await sendPostRestRequestAppJson(
        `v1/partner/${getGuid() || Pguid}/send_invite`,
        data,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error sending assessment invitation:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

export const getCountries = createAsyncThunk("Country/Details", async () => {
  try {
    const response = await sendGetRestRequest(`v1/enumcountry?limit=2000`);
    return response.data;
  } catch (error) {
    // Handle the error here
    console.error("Error fetching country details:", error);
    throw error; // Rethrow the error to indicate failure
  }
});

export const getCityCountry = createAsyncThunk(
  "CountryCity/Details",
  async (countryName) => {
    try {
      const response = await sendGetRestRequest(
        `v1/enumcountry/country/${countryName}?limit=2000`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching city-country details:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

export const RoleBasedSkill = createAsyncThunk(
  "partner/RoleBasedSkill",
  async (role) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${getGuid() || Pguid}/role/${role}/get_skills`,
      );
      return response.data;
    } catch (error) {
      // Handle the error here
      console.error("Error fetching role-based skills:", error);
      throw error; // Rethrow the error to indicate failure
    }
  },
);

// ContractOfferList dropdown
export const ContractOfferList = createAsyncThunk(
  "partner/ContractOfferList",
  async () => {
    try {
      const response = await sendGetRestRequest(
        `v1/offer?limit=2000&partner_guid=${getGuid() || Pguid}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching offers list dropdown data:", error);
      throw error;
    }
  },
);

// ContractOfferList dropdown
export const CreateContractAotofillForm = createAsyncThunk(
  "partner/CreateContractAotofillForm",
  async ({ OfferID }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/fetch_offers_partner/${OfferID}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching CreateContractAotofillForm data:", error);
      throw error;
    }
  },
);

export const AddContractSubmit = createAsyncThunk(
  "partner/AddContractSubmit",
  async (data) => {
    try {
      log_debug("AddContractSubmitinfo", data);
      const response = await sendPostRestRequestAppJson(
        `v1/partnercontract`,
        data,
      );
      return response.data;
    } catch (error) {
      console.error("Error editing partnercontract", error);
      throw error;
    }
  },
);

export const CreateInvoice = createAsyncThunk(
  "partner/CreateInvoice",
  async (data) => {
    log_debug("CreateInvoicedata", data);
    try {
      const response = await sendPostRestRequestAppJson(`v1/invoice`, data);
      return response.data;
    } catch (error) {
      console.error("Error CreateInvoice", error);
      throw error;
    }
  },
);

// createInvoiceCandidatedropdown dropdown
export const createInvoiceCandidatedropdown = createAsyncThunk(
  "partner/createInvoiceCandidatedropdown",
  async () => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${getGuid() || Pguid}/fetch_name`,
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching createInvoiceCandidatedropdown dropdown data:",
        error,
      );
      throw error;
    }
  },
);

// createInvoiceEmail dropdown
export const createInvoiceEmail = createAsyncThunk(
  "partner/createInvoiceEmail",
  async ({ Iguiddata }) => {
    try {
      log_debug("createInvoiceEmailinfo", Iguiddata);

      const response = await sendGetRestRequest(
        `v1/partner/${Iguiddata}/fetch_email`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching createInvoiceEmail dropdown data:", error);
      throw error;
    }
  },
);

// InvoiceListTable
export const InvoiceListTable = createAsyncThunk(
  "partner/InvoiceListTable",
  async ({ currentPage, filtervalue }) => {
    try {
      const response = await sendGetRestRequest(
        `v1/partner/${
          getGuid() || Pguid
        }/fetch_invoice?limit=10&offset=${currentPage}&searchText=${filtervalue}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching InvoiceListTable dropdown data:", error);
      throw error;
    }
  },
);

export const SendInvitation = createAsyncThunk(
  "partner/SendInvitation",
  async (data) => {
    try {
      log_debug("SendInvitationdata", data);
      const response = await sendPostRestRequestAppJson(
        `v1/partner/${getGuid() || Pguid}/send_invite_partner`,
        data,
      );
      return response.data;
    } catch (error) {
      console.error("Error SendInvitation:", error);
      throw error;
    }
  },
);

export const GenerateResume = createAsyncThunk(
  "partner/GenerateResume",
  async ({ IndividualGuid, maskstatus }) => {
    try {
      const response = await sendPostRestRequestAppJson(
        `v1/individual/${IndividualGuid}/generate/resume?mask=${maskstatus}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error SendInvitation:", error);
      throw error;
    }
  },
);

export const SaveGenerateResume = createAsyncThunk(
  "partner/SaveGenerateResume",
  async ({ IndividualGuid, body }) => {
    try {
      const response = await sendPostRestRequest(
        `v1/individual/${IndividualGuid}/resume/url`,
        body,
      );
      return response.data;
    } catch (error) {
      console.error("Error SendInvitation:", error);
      throw error;
    }
  },
);

export const IndividualActiveContracts = createAsyncThunk(
  "partner/IndividualActiveContracts",
  async ({ individualguid }) => {
    try {
      const response = await sendGetRestRequestJson(
        `v1/individual/${individualguid}/individual_contracts`,
      );
      return response.data;
    } catch (error) {
      console.error(
        "Error fetching IndividualActiveContracts dropdown data:",
        error,
      );
      throw error;
    }
  },
);

export const getPartnerTimesheet = createAsyncThunk(
  "Partner/Timesheet",
  async ({ weeknumber, year, individualguid }) => {
    const response = await sendGetRestRequest(
      `v1/timesheet/individual-timesheet/${individualguid}?week=${weeknumber}&year=${year}`,
    );
    return response.data;
  },
);

export const getPartnerAdminIndividualContract = createAsyncThunk(
  "PartnerAdminIndividual/Contract",
  async ({ contractid, individualguid }) => {
    const response = await sendGetRestRequest(
      `v1/partnercontract/${contractid}/individual_contract_details/${individualguid}`,
    );
    return response.data;
  },
);

export const postSendInvoiceEmail = createAsyncThunk(
  "partner/postSendInvoiceEmail",
  async ({ body }) => {
    try {
      const response = await sendPostRestRequest(
        `v1/partner/${getGuid() || Pguid}/send_invoice`,
        body,
      );
      return response.data;
    } catch (error) {
      console.error("Error postSendInvoiceEmail:", error);
      throw error;
    }
  },
);

export const getPartnerTimesheets = createAsyncThunk(
  "PartnerAdminIndividual/getPartnerTimesheets",
  async () => {
    const response = await sendGetRestRequest(
      `v1/partner/${getGuid() || Pguid}/fetch_timesheets`,
    );
    return response.data;
  },
);

export const getFilterCandididates = createAsyncThunk(
  "PartnerAdminIndividual/getFilterCandididates",
  async ({ value, skills, locations }) => {
    log_debug("globalsearchvalue34", value);
    const response = await sendGetRestRequest(
      `v1/partner/${
        getGuid() || Pguid
      }/individual/?skills=${skills}&locations=${locations}&limit=10&offset=0&searchText=${value}`,
    );
    return response.data;
  },
);

export const getFilterJobsByStatus = createAsyncThunk(
  "PartnerAdminIndividual/getFilterJobsByStatus",
  async ({ status, value }) => {
    const response = await sendGetRestRequest(
      `v1/partner/${
        getGuid() || Pguid
      }/jobs/?status=${status}&limit=10&offset=0&search_text=${value}`,
    );
    return response.data;
  },
);

export const getFilterMatchingJobs = createAsyncThunk(
  "PartnerAdminIndividual/getFilterMatchingJobs",
  async ({ value, skills, locations }) => {
    const response = await sendGetRestRequest(
      `v1/partner/${
        getGuid() || Pguid
      }/matchingjobs?skills=${skills}&locations=${locations}&limit=10&offset=0&search_text=${value}`,
    );
    return response.data;
  },
);

export const getFilterAllJobs = createAsyncThunk(
  "PartnerAdminIndividual/getFilterAllJobs",
  async ({ value, skills, locations }) => {
    const response = await sendGetRestRequest(
      `v1/partner/${
        getGuid() || Pguid
      }/fetch-all-jobs/?skills=${skills}&locations=${locations}&limit=10&offset=0&searchText=${value}`,
    );
    return response.data;
  },
);

export const getFilterInterview = createAsyncThunk(
  "PartnerAdminIndividual/getFilterInterview",
  async ({ value }) => {
    const response = await sendGetRestRequest(
      `v1/partner/${
        getGuid() || Pguid
      }/interview?limit=10&offset=0&searchText=${value}`,
    );
    return response.data;
  },
);

export const getFilterOffer = createAsyncThunk(
  "PartnerAdminIndividual/getFilterOffer",
  async ({ value }) => {
    const response = await sendGetRestRequest(
      `v1/partner/${
        getGuid() || Pguid
      }/offer?limit=10&offset=0&searchText=${value}`,
    );
    return response.data;
  },
);

export const getFilterContract = createAsyncThunk(
  "PartnerAdminIndividual/getFilterContract",
  async ({ value }) => {
    const response = await sendGetRestRequest(
      `v1/partner/${
        getGuid() || Pguid
      }/partner_contract?limit=10&offset=0&searchText=${value}`,
    );
    return response.data;
  },
);

export const getFilterInvoice = createAsyncThunk(
  "PartnerAdminIndividual/getFilterInvoice",
  async ({ value }) => {
    const response = await sendGetRestRequest(
      `v1/partner/${
        getGuid() || Pguid
      }/fetch_invoice?searchText=${value}`,
    );
    return response.data;
  },
);

export const createPartnerIndividualResume = createAsyncThunk(
  "PartnerIndividualResumeUpload/parsedatasave",
  async(data) => {
    const response = await sendPostRestRequestAppJson(
      `v1/partner/${getGuid() || Pguid}/create/individual`,data
    );
    return response.data;
  }
);

export const editIndividualProfile = createAsyncThunk(
  "partner/CandidateEditProfile",
  async ({data,guid,resume_guid}) => {
    try {
      log_debug("editIndividualProfile", guid);
      const response = await sendPostRestRequestAppJson(
        `v1/individual/${guid}/update/individual/${resume_guid}`,
        data,
      );
      return response.data;
    } catch (error) {
      console.error("Error editing candidate resume profile:", error);
      throw error;
    }
  },
);

export const getVendorSummary = createAsyncThunk(
  "Partner/VendorSummary",
  async () => {
    const response = await sendGetRestRequest(
      `v1/partner/${
        getGuid() || Pguid
      }/vendorSummary`,
    );
    return response.data;
  },
);

export const getJobSubmissionCount = createAsyncThunk(
  "jobs/SubmissionCount", 
  async ({jobguid}) => {
  try {
    const response = await sendGetRestRequest(`v1/jobdescription/${jobguid}/job-submission-count`);
    return response.data;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
});


export const PartnerAppliedJobs = createAsyncThunk(
  "partner/AppliedJobs",
  async ({currentPage}) => {
    try {
      const response = await sendGetRestRequestJson(
        `v1/individualjobmapping/jobSubmission/${getGuid() || Pguid}?limit=10&offset=${currentPage}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error applying for matching job:", error);
      throw error;
    }
  },
);

export const allunappliedcandidates = createAsyncThunk(
  "partner/unappliedIndividuals",
  async ({jobguid,filtervalue,currentPage}) => {
    try {
      log_debug("currentPageapi", jobguid);

      const response = await sendGetRestRequestJson(
        `v1/partner/${getGuid() || Pguid}/job/${jobguid}/unapplied-individuals?name=${filtervalue}&limit=10&offset=${currentPage}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching my candidates:", error);
      throw error;
    }
  },
);

export const fetchSubscriptionPlan = createAsyncThunk(
  "partner/SubscriptionPlans",
  async ({duration}) => {
    try {
      const response = await sendGetRestRequest(
        `v1/SubscriptionPlan/type/${duration}`
      )
      return response.data;
    } catch (error) {
      console.log("Error",error)
      throw error;
    }
  }
);

export const CreateOrder = createAsyncThunk(
  "partner/CreateOrder",
  async ({amount}) => {
    log_debug("amount",amount)
    const obj = {amount : amount};
    try {
      const response = await sendPostRestRequestAppJson(
        `v1/order`,
        obj
      )
      return response.data;
    } catch (error) {
      console.log("Error",error)
      throw error;
    }
  }
);

export const VerifyPayment = createAsyncThunk(
  "partner/VerifyPayment",
  async ({data}) => {
    log_debug("verify data",data)
    // const obj = {amount : amount};
    try {
      const response = await sendPostRestRequestAppJson(
        `v1/order/verify-payment`,
        data
      )
      return response.data;
    } catch (error) {
      console.log("Error",error)
      throw error;
    }
  }
);

export const checkPlan = createAsyncThunk(
  "partner/checkPlan",
  async () => {
    try {
      const response = await sendGetRestRequestJson(`v1/user/checkplan/${localStorage.getItem('userguid')}`) 
      return response.data;
    } catch (error) {
      console.error("Error",error);
    }
  }
);


export const getUsers = createAsyncThunk(
  "partner/getUsers",
  async () => {
    try {
      const response = await sendGetRestRequestJson(`v1/partner/${localStorage.getItem('userguid')}/getUsers`)
      return response.data;      
    } catch (error) {
      console.error("Error",error)
    }
  }
);

export const addUsers = createAsyncThunk(
  "partner/addUsers",
  async ({data}) => {
    try {
      const response = await sendPostRestRequestAppJson(`v1/partner/${localStorage.getItem('userguid')}/addUser`,data)
      return response.data;      
    } catch (error) {
      console.error("Error",error)
    }
  }
);

export const editUser = createAsyncThunk(
  "partner/editUser",
  async ({data,guid}) => {
    try {
      const response = await sendPutRestRequestAppJson(`v1/partner/${guid}/editUser`,data)
      return response.data;      
    } catch (error) {
      console.error("Error",error)
    }
  }
);


export const deleteUser = createAsyncThunk(
  "partner/deleteUser",
  async ({guid}) => {
    try {
      const response = await sendDeleteRestRequestAppJson(`v1/partner/${guid}/deleteUser`)
      return response.data;      
    } catch (error) {
      console.error("Error",error)
    }
  }
);

const partnerSlice = createSlice({
  name: "partner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(mycandidates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(mycandidates.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.partnerdata = action.payload;
      })
      .addCase(mycandidates.rejected, (state, action) => {
        state.status = "idle";
        state.data.partnerdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(DashboardTabsMycandidate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(DashboardTabsMycandidate.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.DashboardTabsMycandidatedata = action.payload;
      })
      .addCase(DashboardTabsMycandidate.rejected, (state, action) => {
        state.status = "idle";
        state.data.DashboardTabsMycandidatedata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(DashboardTabsShortlisted.pending, (state) => {
        state.status = "loading";
      })
      .addCase(DashboardTabsShortlisted.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.DashboardTabsShortlisteddata = action.payload;
      })
      .addCase(DashboardTabsShortlisted.rejected, (state, action) => {
        state.status = "idle";
        state.data.DashboardTabsShortlisteddata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(DashboardTabsInterview.pending, (state) => {
        state.status = "loading";
      })
      .addCase(DashboardTabsInterview.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.DashboardTabsInterviewdata = action.payload;
      })
      .addCase(DashboardTabsInterview.rejected, (state, action) => {
        state.status = "idle";
        state.data.DashboardTabsInterviewdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(DashboardTabsContract.pending, (state) => {
        state.status = "loading";
      })
      .addCase(DashboardTabsContract.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.DashboardTabsContractdata = action.payload;
      })
      .addCase(DashboardTabsContract.rejected, (state, action) => {
        state.status = "idle";
        state.data.DashboardTabsContractdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(candidatesOverview.pending, (state) => {
        state.status = "loading";
      })
      .addCase(candidatesOverview.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.candidatesOverviewdata = action.payload;
      })
      .addCase(candidatesOverview.rejected, (state, action) => {
        state.status = "idle";
        state.data.candidatesOverviewdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(addCandidateResume.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCandidateResume.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CandidateResumeData = action.payload;
      })
      .addCase(addCandidateResume.rejected, (state, action) => {
        state.status = "idle";
        state.data.CandidateResumeData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(CandidateResumeProfileEdit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(CandidateResumeProfileEdit.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CandidateResumeProfileEditData = action.payload;
      })
      .addCase(CandidateResumeProfileEdit.rejected, (state, action) => {
        state.status = "idle";
        state.data.CandidateResumeProfileEditData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(CandidateResumeAddEditData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(CandidateResumeAddEditData.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CandidateResumeAddEditDataData = action.payload;
      })
      .addCase(CandidateResumeAddEditData.rejected, (state, action) => {
        state.status = "idle";
        state.data.CandidateResumeAddEditDataData = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(addCandidatesLocation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCandidatesLocation.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CandidatesLocation = action.payload;
      })
      .addCase(addCandidatesLocation.rejected, (state, action) => {
        state.status = "idle";
        state.data.CandidatesLocation = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(addCandidatesSkills.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCandidatesSkills.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CandidatesSkills = action.payload;
      })
      .addCase(addCandidatesSkills.rejected, (state, action) => {
        state.status = "idle";
        state.data.CandidatesSkills = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(submitIndividualForm.pending, (state) => {
        state.status = "loading";
      })
      .addCase(submitIndividualForm.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.submitIndividual = action.payload;
      })
      .addCase(submitIndividualForm.rejected, (state, action) => {
        state.status = "idle";
        state.data.submitIndividual = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(addCandidatesStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addCandidatesStatus.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CandidatesStatus = action.payload;
      })
      .addCase(addCandidatesStatus.rejected, (state, action) => {
        state.status = "idle";
        state.data.CandidatesStatus = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(machingJob.pending, (state) => {
        state.status = "loading";
      })
      .addCase(machingJob.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.machingJobRecords = action.payload;
      })
      .addCase(machingJob.rejected, (state, action) => {
        state.status = "idle";
        state.data.machingJobRecords = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(machingJobApplyJob.pending, (state) => {
        state.status = "loading";
      })
      .addCase(machingJobApplyJob.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.machingJobApplyJobRecords = action.payload;
      })
      .addCase(machingJobApplyJob.rejected, (state, action) => {
        state.status = "idle";
        state.data.machingJobApplyJobRecords = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      // .addCase(machingCandidateApplyJob.pending, (state) => {
      //   state.status = "loading";
      // })
      // .addCase(machingCandidateApplyJob.fulfilled, (state, action) => {
      //   state.status = "idle";
      //   state.data.machingCandidateApplyJobRecords = action.payload;
      // })
      // .addCase(machingCandidateApplyJob.rejected, (state, action) => {
      //   state.status = "idle";
      //   state.data.machingCandidateApplyJobRecords = {
      //     status: "failed ",
      //     message: `${action.error.name} : ${action.error.message}`,
      //   };
      // })

      .addCase(Shortlistedjobsdata.pending, (state) => {
        state.status = "loading";
      })
      .addCase(Shortlistedjobsdata.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.ShortlistedjobsRecords = action.payload;
      })
      .addCase(Shortlistedjobsdata.rejected, (state, action) => {
        state.status = "idle";
        state.data.ShortlistedjobsRecords = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(Invitedjobsdata.pending, (state) => {
        state.status = "loading";
      })
      .addCase(Invitedjobsdata.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.InvitedjobsRecords = action.payload;
      })
      .addCase(Invitedjobsdata.rejected, (state, action) => {
        state.status = "idle";
        state.data.InvitedjobsRecords = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(Alljobsdata.pending, (state) => {
        state.status = "loading";
      })
      .addCase(Alljobsdata.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.Alljobsrecords = action.payload;
      })
      .addCase(Alljobsdata.rejected, (state, action) => {
        state.status = "idle";
        state.data.Alljobsrecords = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(shortlistedIndividualCandidate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(shortlistedIndividualCandidate.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.shortlistedIndividualCandidateRecords = action.payload;
      })
      .addCase(shortlistedIndividualCandidate.rejected, (state, action) => {
        state.status = "idle";
        state.data.shortlistedIndividualCandidateRecords = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(InvitationIndividualCandidate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(InvitationIndividualCandidate.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.InvitationIndividualCandidateRecords = action.payload;
      })
      .addCase(InvitationIndividualCandidate.rejected, (state, action) => {
        state.status = "idle";
        state.data.InvitationIndividualCandidateRecords = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(MachingIndividualCandi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(MachingIndividualCandi.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.MachingIndividualCandiRecords = action.payload;
      })
      .addCase(MachingIndividualCandi.rejected, (state, action) => {
        state.status = "idle";
        state.data.MachingIndividualCandiRecords = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(AssessmentIndividualCandi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(AssessmentIndividualCandi.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.AssessmentIndividualCandiRecords = action.payload;
      })
      .addCase(AssessmentIndividualCandi.rejected, (state, action) => {
        state.status = "idle";
        state.data.AssessmentIndividualCandiRecords = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(BGVIndividualCandi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(BGVIndividualCandi.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.BGVIndividualCandiRecords = action.payload;
      })
      .addCase(BGVIndividualCandi.rejected, (state, action) => {
        state.status = "idle";
        state.data.BGVIndividualCandiRecords = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(BGVTypeDropdown.pending, (state) => {
        state.status = "loading";
      })
      .addCase(BGVTypeDropdown.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.BGVTypeDropdownRecords = action.payload;
      })
      .addCase(BGVTypeDropdown.rejected, (state, action) => {
        state.status = "idle";
        state.data.BGVTypeDropdownRecords = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(BGVTypeDropdownVerifiers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(BGVTypeDropdownVerifiers.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.BGVTypeDropdownVerifiersRecords = action.payload;
      })
      .addCase(BGVTypeDropdownVerifiers.rejected, (state, action) => {
        state.status = "idle";
        state.data.BGVTypeDropdownVerifiersRecords = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(myCandiDetailsShortlistedRole.pending, (state) => {
        state.status = "loading";
      })
      .addCase(myCandiDetailsShortlistedRole.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.myCandiDetailsShortlistedRoleRecords = action.payload;
      })
      .addCase(myCandiDetailsShortlistedRole.rejected, (state, action) => {
        state.status = "idle";
        state.data.myCandiDetailsShortlistedRoleRecords = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(JobDescriptionJobInvitation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(JobDescriptionJobInvitation.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.JobDescriptionJobInvitationRecords = action.payload;
      })
      .addCase(JobDescriptionJobInvitation.rejected, (state, action) => {
        state.status = "idle";
        state.data.JobDescriptionJobInvitationRecords = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(candidateInterview.pending, (state) => {
        state.status = "loading";
      })
      .addCase(candidateInterview.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.candidateInterviewRecords = action.payload;
      })
      .addCase(candidateInterview.rejected, (state, action) => {
        state.status = "idle";
        state.data.candidateInterviewRecords = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(dashboardOffer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(dashboardOffer.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.dashboardOffer = action.payload;
      })
      .addCase(dashboardOffer.rejected, (state, action) => {
        state.status = "idle";
        state.data.dashboardOffer = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(CandidatureHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(CandidatureHistory.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CandidatureHistory = action.payload;
      })
      .addCase(CandidatureHistory.rejected, (state, action) => {
        state.status = "idle";
        state.data.CandidatureHistory = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(Contract.pending, (state) => {
        state.status = "loading";
      })
      .addCase(Contract.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.Contract = action.payload;
      })
      .addCase(Contract.rejected, (state, action) => {
        state.status = "idle";
        state.data.Contract = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(ContractHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ContractHistory.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.ContractHistorydata = action.payload;
      })
      .addCase(ContractHistory.rejected, (state, action) => {
        state.status = "idle";
        state.data.ContractHistorydata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(IndividualDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(IndividualDetail.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.IndividualDetail = action.payload;
      })
      .addCase(IndividualDetail.rejected, (state, action) => {
        state.status = "idle";
        state.data.IndividualDetail = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(JobDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(JobDetail.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.JobDetail = action.payload;
      })
      .addCase(JobDetail.rejected, (state, action) => {
        state.status = "idle";
        state.data.JobDetail = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(matchingCandidates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(matchingCandidates.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.matchingCandidatesdata = action.payload;
      })
      .addCase(matchingCandidates.rejected, (state, action) => {
        state.status = "idle";
        state.data.matchingCandidatesdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(shortlistedCandidates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(shortlistedCandidates.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.shortlistedCandidatesdata = action.payload;
      })
      .addCase(shortlistedCandidates.rejected, (state, action) => {
        state.status = "idle";
        state.data.shortlistedCandidatesdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(invitedCandidates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(invitedCandidates.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.invitedCandidatesdata = action.payload;
      })
      .addCase(invitedCandidates.rejected, (state, action) => {
        state.status = "idle";
        state.data.invitedCandidatesdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(dummyAssessment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(dummyAssessment.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.dummyAssessmentdata = action.payload;
      })
      .addCase(dummyAssessment.rejected, (state, action) => {
        state.status = "idle";
        state.data.dummyAssessmentdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(assessmentMCQ.pending, (state) => {
        state.status = "loading";
      })
      .addCase(assessmentMCQ.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.assessmentMCQdata = action.payload;
      })
      .addCase(assessmentMCQ.rejected, (state, action) => {
        state.status = "idle";
        state.data.assessmentMCQdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(assessmentCoding.pending, (state) => {
        state.status = "loading";
      })
      .addCase(assessmentCoding.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.assessmentCodingdata = action.payload;
      })
      .addCase(assessmentCoding.rejected, (state, action) => {
        state.status = "idle";
        state.data.assessmentCodingdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(assessmentBehavioural.pending, (state) => {
        state.status = "loading";
      })
      .addCase(assessmentBehavioural.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.assessmentBehaviouraldata = action.payload;
      })
      .addCase(assessmentBehavioural.rejected, (state, action) => {
        state.status = "idle";
        state.data.assessmentBehaviouraldata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(CandidateHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(CandidateHistory.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CandidateHistorydata = action.payload;
      })
      .addCase(CandidateHistory.rejected, (state, action) => {
        state.status = "idle";
        state.data.CandidateHistorydata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(interviewHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(interviewHistory.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.interviewHistorydata = action.payload;
      })
      .addCase(interviewHistory.rejected, (state, action) => {
        state.status = "idle";
        state.data.interviewHistorydata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      .addCase(getProfileDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProfileDetails.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.ProfileDetails = action.payload;
      })
      .addCase(getProfileDetails.rejected, (state, action) => {
        state.status = "idle";
        state.data.ProfileDetails = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })
      .addCase(postPartnerProfileDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postPartnerProfileDetails.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.ProfileDetails = action.payload;
      })
      .addCase(postPartnerProfileDetails.rejected, (state, action) => {
        state.status = "idle";
        state.data.ProfileDetails = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(JobHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(JobHistory.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.JobHistorydata = action.payload;
      })
      .addCase(JobHistory.rejected, (state, action) => {
        state.status = "idle";
        state.data.JobHistorydata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(DashboardCounter.pending, (state) => {
        state.status = "loading";
      })
      .addCase(DashboardCounter.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.DashboardCounterdata = action.payload;
      })
      .addCase(DashboardCounter.rejected, (state, action) => {
        state.status = "idle";
        state.data.DashboardCounterdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(DashboardApexchart.pending, (state) => {
        state.status = "loading";
      })
      .addCase(DashboardApexchart.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.DashboardApexchartdata = action.payload;
      })
      .addCase(DashboardApexchart.rejected, (state, action) => {
        state.status = "idle";
        state.data.DashboardApexchartdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(GlobalSearchRecord.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GlobalSearchRecord.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.GlobalSearchRecorddata = action.payload;
      })
      .addCase(GlobalSearchRecord.rejected, (state, action) => {
        state.status = "idle";
        state.data.GlobalSearchRecorddata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(CandidateDetailscanvasInterview.pending, (state) => {
        state.status = "loading";
      })
      .addCase(CandidateDetailscanvasInterview.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CandidateDetailscanvasInterviewdata = action.payload;
      })
      .addCase(CandidateDetailscanvasInterview.rejected, (state, action) => {
        state.status = "idle";
        state.data.CandidateDetailscanvasInterviewdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(CandidateDetailscanvasOffer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(CandidateDetailscanvasOffer.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CandidateDetailscanvasOfferdata = action.payload;
      })
      .addCase(CandidateDetailscanvasOffer.rejected, (state, action) => {
        state.status = "idle";
        state.data.CandidateDetailscanvasOfferdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(CandidateDetailscanvasContract.pending, (state) => {
        state.status = "loading";
      })
      .addCase(CandidateDetailscanvasContract.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CandidateDetailscanvasContractdata = action.payload;
      })
      .addCase(CandidateDetailscanvasContract.rejected, (state, action) => {
        state.status = "idle";
        state.data.CandidateDetailscanvasContractdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(CandidateAssessmentInvitation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(CandidateAssessmentInvitation.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CandidateAssessmentInvitationdata = action.payload;
      })
      .addCase(CandidateAssessmentInvitation.rejected, (state, action) => {
        state.status = "idle";
        state.data.CandidateAssessmentInvitationdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getCityCountry.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCityCountry.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.countryCities = action.payload;
      })
      .addCase(getCityCountry.rejected, (state, action) => {
        state.status = "idle";
        state.data.countryCities = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getCountries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.Countries = action.payload;
      })
      .addCase(getCountries.rejected, (state, action) => {
        state.status = "idle";
        state.data.Countries = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(RoleBasedSkill.pending, (state) => {
        state.status = "loading";
      })
      .addCase(RoleBasedSkill.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.RoleBasedSkill = action.payload;
      })
      .addCase(RoleBasedSkill.rejected, (state, action) => {
        state.status = "idle";
        state.data.RoleBasedSkill = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(ContractOfferList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(ContractOfferList.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.ContractOfferListdata = action.payload;
      })
      .addCase(ContractOfferList.rejected, (state, action) => {
        state.status = "idle";
        state.data.ContractOfferListdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(CreateContractAotofillForm.pending, (state) => {
        state.status = "loading";
      })
      .addCase(CreateContractAotofillForm.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CreateContractAotofillFormdata = action.payload;
      })
      .addCase(CreateContractAotofillForm.rejected, (state, action) => {
        state.status = "idle";
        state.data.CreateContractAotofillFormdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(AddContractSubmit.pending, (state) => {
        state.status = "loading";
      })
      .addCase(AddContractSubmit.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.AddContractSubmitdata = action.payload;
      })
      .addCase(AddContractSubmit.rejected, (state, action) => {
        state.status = "idle";
        state.data.AddContractSubmitdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(CreateInvoice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(CreateInvoice.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.CreateInvoicedata = action.payload;
      })
      .addCase(CreateInvoice.rejected, (state, action) => {
        state.status = "idle";
        state.data.CreateInvoicedata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(createInvoiceCandidatedropdown.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createInvoiceCandidatedropdown.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.createInvoiceCandidatedropdowndata = action.payload;
      })
      .addCase(createInvoiceCandidatedropdown.rejected, (state, action) => {
        state.status = "idle";
        state.data.createInvoiceCandidatedropdowndata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(createInvoiceEmail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createInvoiceEmail.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.createInvoiceEmaildata = action.payload;
      })
      .addCase(createInvoiceEmail.rejected, (state, action) => {
        state.status = "idle";
        state.data.createInvoiceEmaildata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(InvoiceListTable.pending, (state) => {
        state.status = "loading";
      })
      .addCase(InvoiceListTable.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.InvoiceListTabledata = action.payload;
      })
      .addCase(InvoiceListTable.rejected, (state, action) => {
        state.status = "idle";
        state.data.InvoiceListTabledata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(SendInvitation.pending, (state) => {
        state.status = "loading";
      })
      .addCase(SendInvitation.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.SendInvitationdata = action.payload;
      })
      .addCase(SendInvitation.rejected, (state, action) => {
        state.status = "idle";
        state.data.SendInvitationdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(GenerateResume.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GenerateResume.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.GenerateResumedata = action.payload;
      })
      .addCase(GenerateResume.rejected, (state, action) => {
        state.status = "idle";
        state.data.GenerateResumedata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(SaveGenerateResume.pending, (state) => {
        state.status = "loading";
      })
      .addCase(SaveGenerateResume.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.SaveGenerateResumedata = action.payload;
      })
      .addCase(SaveGenerateResume.rejected, (state, action) => {
        state.status = "idle";
        state.data.SaveGenerateResumedata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(IndividualActiveContracts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(IndividualActiveContracts.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.IndividualActiveContractsdata = action.payload;
      })
      .addCase(IndividualActiveContracts.rejected, (state, action) => {
        state.status = "idle";
        state.data.IndividualActiveContractsdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getPartnerTimesheet.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPartnerTimesheet.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.PartnerTimesheetdata = action.payload;
      })
      .addCase(getPartnerTimesheet.rejected, (state, action) => {
        state.status = "idle";
        state.data.PartnerTimesheetdata = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getPartnerAdminIndividualContract.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPartnerAdminIndividualContract.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.PartnerAdminContracts = action.payload;
      })
      .addCase(getPartnerAdminIndividualContract.rejected, (state, action) => {
        state.status = "idle";
        state.data.PartnerAdminContracts = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(postSendInvoiceEmail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(postSendInvoiceEmail.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.InvoiceEmail = action.payload;
      })
      .addCase(postSendInvoiceEmail.rejected, (state, action) => {
        state.status = "idle";
        state.data.InvoiceEmail = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getPartnerTimesheets.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPartnerTimesheets.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.PartnerAllTimesheets = action.payload;
      })
      .addCase(getPartnerTimesheets.rejected, (state, action) => {
        state.status = "idle";
        state.data.PartnerAllTimesheets = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getFilterCandididates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFilterCandididates.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.FilteredCandidates = action.payload;
      })
      .addCase(getFilterCandididates.rejected, (state, action) => {
        state.status = "idle";
        state.data.FilteredCandidates = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getFilterJobsByStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFilterJobsByStatus.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.FilteredJobsByStatus = action.payload;
      })
      .addCase(getFilterJobsByStatus.rejected, (state, action) => {
        state.status = "idle";
        state.data.FilteredJobsByStatus = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getFilterMatchingJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFilterMatchingJobs.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.FilteredMatchingJobs = action.payload;
      })
      .addCase(getFilterMatchingJobs.rejected, (state, action) => {
        state.status = "idle";
        state.data.FilteredMatchingJobs = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getFilterAllJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFilterAllJobs.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.FilteredAllJobs = action.payload;
      })
      .addCase(getFilterAllJobs.rejected, (state, action) => {
        state.status = "idle";
        state.data.FilteredAllJobs = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getFilterInterview.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFilterInterview.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.FilteredInterviews = action.payload;
      })
      .addCase(getFilterInterview.rejected, (state, action) => {
        state.status = "idle";
        state.data.FilteredInterviews = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getFilterOffer.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFilterOffer.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.FilteredOffers = action.payload;
      })
      .addCase(getFilterOffer.rejected, (state, action) => {
        state.status = "idle";
        state.data.FilteredOffers = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getFilterContract.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFilterContract.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.FilteredContracts = action.payload;
      })
      .addCase(getFilterContract.rejected, (state, action) => {
        state.status = "idle";
        state.data.FilteredContracts = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })

      .addCase(getFilterInvoice.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFilterInvoice.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.FilteredInvoices = action.payload;
      })
      .addCase(getFilterInvoice.rejected, (state, action) => {
        state.status = "idle";
        state.data.FilteredInvoices = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      }) 

      .addCase(createPartnerIndividualResume.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createPartnerIndividualResume.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.PartnerIndividualResume = action.payload;
      })
      .addCase(createPartnerIndividualResume.rejected, (state, action) => {
        state.status = "idle";
        state.data.PartnerIndividualResume = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })


      .addCase(fetchSubscriptionPlan.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSubscriptionPlan.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.PartnerSubscriptionPlan = action.payload;
      })
      .addCase(fetchSubscriptionPlan.rejected, (state, action) => {
        state.status = "idle";
        state.data.PartnerSubscriptionPlan = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })


      .addCase(checkPlan.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkPlan.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.PlanDetails = action.payload;
      })
      .addCase(checkPlan.rejected, (state, action) => {
        state.status = "idle";
        state.data.PlanDetails = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      })


      .addCase(getUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.UserDetails = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.status = "idle";
        state.data.UserDetails = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      });
  },
});

export default partnerSlice.reducer;
