import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import CreativeProjectMonth from "../pages/Utility/PagePricing/CreativeProjectMonth";
import CreativeProjectYearly from "../pages/Utility/PagePricing/CreativeProjectYearly";
import LowMonthPlans from "../pages/Utility/PagePricing/LowMonthPlans";
import MediumMonthPlans from "../pages/Utility/PagePricing/MediumMonthPlans";
import HighMonthPlans from "../pages/Utility/PagePricing/HighMonthPlans";
import Breadcrumbs from "../components/Common/Breadcrumb.js"
import PartnerPricingPlans from "./pagesComponents/PartnerPricingPlans.js";
import { fetchSubscriptionPlan } from "./PartnerSlice.js";
import { log_debug } from "../apiHelpers/logger.js";
import Preloader from "../components/Common/Preloader.js";



const PartnerPricing = () => {
  //meta title
  document.title = "Company Bench";

  const [activeTab, toggleTab] = useState("1");
  const [activeTab2, toggleTab2] = useState("1");

  const dispatch = useDispatch();
  const partnerstore = useSelector((state) => state.partnerSlice);
  const [duration, setDuration] = useState("Monthly");
  const [planData, setPlanData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleTabChange = (tab) => {
    setPlanData([]);
    toggleTab(tab);
    if(tab === "1"){
      setDuration("Monthly")
    }else{
      setDuration("Yearly")
    }
  }

  useEffect(() => {
    dispatch(fetchSubscriptionPlan({duration:duration}));
    setLoading(true);
  },[duration])

  useEffect(() => {
    log_debug("parnterstore3423",partnerstore.data?.PartnerSubscriptionPlan?.data);
    const status = partnerstore?.status;
    const data = partnerstore.data?.PartnerSubscriptionPlan?.data?.plans;
    if(status === "idle"){
      setPlanData(data);
      setLoading(false);
    }else if(status === "pending"){
      setLoading(true);
    }else{
      setLoading(false);
    }
  },[partnerstore?.data?.PartnerSubscriptionPlan])



  return (
    <React.Fragment>
      {/* {loading && <Preloader/>} */}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Partner" breadcrumbItem="Pricing" /> */}
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Subscription Plans
                  </h4>
                  <div className="flex-shrink-0 align-self-end">
                    <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames(
                            {
                              active: activeTab === "1",
                            },
                            "px-3 rounded monthly",
                          )}
                          onClick={() => {
                            handleTabChange("1");
                          }}
                        >
                          Monthly
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames(
                            {
                              active: activeTab === "2",
                            },
                            "px-3 rounded yearly",
                          )}
                          onClick={() => {
                            handleTabChange("2");
                          }}
                        >
                          Yearly
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </CardHeader>
                <div className="card-body">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        {/* <PartnerPricingPlans/> */}
                        <CreativeProjectMonth monthData={planData} />
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <CreativeProjectYearly yearData={planData} />
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col xl={12}>
              <Card>
                <CardHeader>
                  <h4 className="card-title">Project Plans</h4>
                  <p className="card-title-desc">
                    Call to action pricing table is really crucial to your for
                    your business website. Make your bids stand-out with amazing
                    options.
                  </p>
                </CardHeader>

                <CardBody>
                  <Row>
                    <Col xl={3}>
                      <Nav pills className="flex-column pricing-tab-box">
                        <NavLink
                          href="#"
                          className={classnames(
                            {
                              active: activeTab2 === "1",
                            },
                            "mb-3",
                          )}
                          onClick={() => {
                            toggleTab2("1");
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <i className="bx bx-check-circle h3 mb-0 me-4"></i>
                            <div className="flex-1">
                              <h2 className="fw-medium">
                                $29{" "}
                                <span className="text-muted font-size-15">
                                  / Month Plans
                                </span>
                              </h2>
                              <p className="fw-normal mb-0 text-muted">
                                For small teams trying out Minia for an
                                unlimited period of time
                              </p>
                            </div>
                          </div>
                        </NavLink>
                        <NavLink
                          href="#"
                          className={classnames(
                            {
                              active: activeTab2 === "2",
                            },
                            "mb-3",
                          )}
                          onClick={() => {
                            toggleTab2("2");
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <i className="bx bx-check-circle h3 mb-0 me-4"></i>
                            <div className="flex-1">
                              <h2 className="fw-medium">
                                $79{" "}
                                <span className="text-muted font-size-15">
                                  / Month Plans
                                </span>
                              </h2>
                              <p className="fw-normal mb-0 text-muted">
                                For larger businesses or those with onal
                                administration needs
                              </p>
                            </div>
                          </div>
                        </NavLink>
                        <NavLink
                          href="#"
                          className={classnames(
                            {
                              active: activeTab2 === "3",
                            },
                            "mb-3",
                          )}
                          onClick={() => {
                            toggleTab2("3");
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <i className="bx bx-check-circle h3 mb-0 me-4"></i>
                            <div className="flex-1">
                              <h2 className="fw-medium">
                                $99{" "}
                                <span className="text-muted font-size-15">
                                  / Month Plans
                                </span>
                              </h2>
                              <p className="fw-normal mb-0 text-muted">
                                For extra large businesses or those in regulated
                                industries
                              </p>
                            </div>
                          </div>
                        </NavLink>
                      </Nav>
                    </Col>
                    <Col xl={9}>
                      <TabContent
                        className="text-muted mt-4 mt-xl-0"
                        activeTab={activeTab2}
                      >
                        <TabPane tabId="1">
                          <LowMonthPlans />
                        </TabPane>
                        <TabPane tabId="2">
                          <MediumMonthPlans />
                        </TabPane>
                        <TabPane tabId="3">
                          <HighMonthPlans />
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PartnerPricing;
