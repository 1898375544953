import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Common/Breadcrumb";
import { Container, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import DropdownWithTwoField from "../customComponents/DropdownWithTwoField";
import { lineStyle, dropdownOptions } from "../data/Data";
import DropDownHeader from "../customComponents/DropDownHeader";
import { useSelector, useDispatch } from "react-redux";
import {
  addCandidatesLocation,
  addCandidatesSkills,
  getFilterJobsByStatus,
  Invitedjobsdata,
} from "./PartnerSlice";
import CustomTable from "../customComponents/reactTable/CustomTable";
import dayjs from "dayjs";
import ComanOffcanvasJobInvitation from "../customComponents/offcanvas/ComanOffcanvasJobInvitation";
import { Link } from "react-router-dom";
import "../../src/assets/css/styleCompanyBench.css";
import CanvasPartnerJobDetails from "../customComponents/offcanvas/CanvasPartnerJobDetails";
import { log_debug } from "../apiHelpers/logger";
import NodataFoundTable from "../customComponents/utilities/NodataFoundTable";
import { Tags } from "../pages/Contacts/ContactList/contactlistCol";
import CustomBreadcrumb from "../customComponents/breadcrumb/CustomBreadcrumb";
import JobsBadge from "../customComponents/utilities/JobsBadge";

function PartnerJobinvitation() {
  //pagination
  const [currentPage, setCurrentPage] = useState(0);

  const [AddCandidateSkills, setAddCandidateSkills] = useState([]);
  const [AddCandidateLocation, setAddCandidateLocation] = useState([]);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [invitationjobsloading, setinvitationjobsloading] = useState(true);

  const [selectedTechnology, setSelectedTechnology] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const [InvitedjobsdataList, setInvitedjobsdataList] = useState([]);
  const [MyCandidateListCount, setMyCandidateListCount] = useState([]);
  const [filtervalue, setFiltervalue] = useState("");
  log_debug("MyCandidateListCount", MyCandidateListCount);
  const InvitedjobsdataListdata = InvitedjobsdataList?.map(
    (job) => job?.job_data,
  );
  const count = InvitedjobsdataList?.length;
  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state?.partnerSlice);

  const candidateName = (guid) => {
    // Do something with the selected candidate, e.g., fetch details
    setSelectedCandidate(guid);
    setOffcanvasOpen(true);
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  const columns = [
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidateName(row.original);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: ({ row }) => (
        <div className="">
          {row.original.location.length >= 1
            ? row.original.location.join(", ")
            : "-"}
        </div>
      ),
    },
    {
      Header: "Skills",
      accessor: "skills",

      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
    },
    {
      Header: "description",
      accessor: "description",
      // Cell: (props) => {
      //   const tidyDate = dayjs(props.value).format("DD-MM-YYYY");
      //   return <span>{tidyDate}</span>;
      // },
    },
    {
      Header: "Experience",
      accessor: "minExperience",
      Cell: ({ row }) => (
        <div className="">
          {row.original?.minExperience ? row.original?.minExperience : 0}
        </div>
      ),
      // Cell: ({ value }) => <JobsBadge status={value} />,
    },
    {
      Header: "Created Date",
      accessor: "createdAt ",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    // {
    //   Header: "Status",
    //   accessor: "status",
    //   Cell: ({ value }) => <JobsBadge status={value} />,
    // },

    // Add more columns as needed
  ];

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore.data.InvitedjobsRecords?.success === true) {
        setinvitationjobsloading(false);
        setInvitedjobsdataList(
          partnerStore.data.InvitedjobsRecords?.data?.jobs,
        );
        setMyCandidateListCount(
          partnerStore?.data?.InvitedjobsRecords?.data?.totalCount,
        );
      } else if (partnerStore.data.InvitedjobsRecords?.success === false) {
        setinvitationjobsloading(false);
        setInvitedjobsdataList([]);
        setMyCandidateListCount(0);
      }
      if (partnerStore.data.CandidatesSkills?.success === true) {
        setAddCandidateSkills(
          partnerStore.data.CandidatesSkills?.data?.enumskills,
        );
      }
      if (partnerStore.data.CandidatesLocation?.success === true) {
        setAddCandidateLocation(
          partnerStore.data.CandidatesLocation?.data?.enumcitys,
        );
      }
    }
  }, [
    partnerStore.data.InvitedjobsRecords,
    partnerStore.data.CandidatesSkills,
    partnerStore.data.CandidatesLocation,
  ]);

  const skillOptions = AddCandidateSkills.map((item) => ({
    value: item.code,
    label: item.title,
  }));

  const locationOptions =
    AddCandidateLocation &&
    AddCandidateLocation?.map((item) => ({
      value: item.code,
      label: item.title,
    }));

  const yourDynamicHeaderData = {
    full_name: "full_name",
    email: "email",
    contact_no: "contact_no",
    skills: "skills",
    prefered_locations: "prefered_locations",
    experience: "experience",
  };

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (
        partnerStore.data.CandidatesSkills?.success === true &&
        partnerStore.data.CandidatesLocation?.success === true
      ) {
        setAddCandidateSkills(
          partnerStore.data.CandidatesSkills?.data?.enumskills,
        );
        setAddCandidateLocation(
          partnerStore.data.CandidatesLocation?.data?.enumcitys,
        );
      } else {
        dispatch(addCandidatesSkills());
        dispatch(addCandidatesLocation());
      }
    }
  }, []);

  const handleTechnologyChange = (selectedOptions) => {
    const selectedValues =
      selectedOptions && selectedOptions?.map((option) => option?.label);
    setSelectedTechnology(selectedValues);
  };

  const handleLocationChange = (selectedOptions) => {
    const selectedValues =
      selectedOptions && selectedOptions?.map((option) => option?.label);
    setSelectedLocations(selectedValues);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        Invitedjobsdata({
          skills: selectedTechnology ? selectedTechnology : "",
          locations: selectedLocations ? selectedLocations : "",
          currentPage: currentPage ? currentPage : currentPage,
          filtervalue: filtervalue ? filtervalue : "",
        }),
      );
    }, 700);
    return () => clearTimeout(timer);
  }, [selectedTechnology, selectedLocations, currentPage, filtervalue]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleValueChange = (newValue) => {
    setinvitationjobsloading(true);
    setFiltervalue(newValue);
  };
  const breadcrumbValues = ["Job invitation"];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <CustomBreadcrumb values={breadcrumbValues} />
          <div className="col-lg-12">
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    {/* <Card
                    style={{
                      backgroundColor: "#5156BE",
                      paddingTop: "15px",
                    }}
                  >
                    <CardBody> */}
                    <Row>
                      <div className="col-12 col-lg-6 col-md-6 d-sm-flex">
                        <div className="col-12 col-lg-6">
                          <DropdownWithTwoField
                            // label="Option 1"
                            options={skillOptions}
                            placeholder="Select Technology"
                            onSelectChange={handleTechnologyChange}
                          />
                        </div>{" "}
                        &nbsp;
                        <div className="col-12 col-lg-6">
                          <DropdownWithTwoField
                            options={locationOptions}
                            placeholder="Select locations"
                            onSelectChange={handleLocationChange}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 col-md-6">
                        <Col md={12}>
                          <CardTitle className="page-title text-muted">
                            <span>Total count : </span>
                            <strong>
                              {MyCandidateListCount ? MyCandidateListCount : 0}
                            </strong>
                          </CardTitle>
                        </Col>
                      </div>
                    </Row>
                    {/* </CardBody> */}
                    {/* </Card> */}
                  </div>
                  <div>
                    <div>
                      <Card>
                        <CardBody>
                          <CustomTable
                            // tableDatas={InvitedjobsdataListdata}
                            // headerData={yourDynamicHeaderData}
                            totalCount={MyCandidateListCount}
                            columns={columns}
                            data={InvitedjobsdataList}
                            isLoading={invitationjobsloading}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            hideCheckbox={true}
                            onChange={handleValueChange}
                            globalsearch={true}
                          />
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </Container>
      </div>

      {
        // <ComanOffcanvasJobInvitation
        //   placement="end"
        //   isOpen={isOffcanvasOpen}
        //   toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
        //   onClose={closeOffcanvas}
        //   content={`Details for candidate with guid: ${selectedCandidate}`}
        // />
        isOffcanvasOpen && (
          //     <CanvasInvitation
          //    placement="end"
          //     isOpen={isOffcanvasOpen}
          //     toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          //     onClose={closeOffcanvas}
          //     content={selectedCandidate}
          // />

          <CanvasPartnerJobDetails
            invitationstatus={true}
            width={50}
            placement="end"
            isOpen={isOffcanvasOpen}
            toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
            onclose={closeOffcanvas}
            data={selectedCandidate}
          />
        )
      }
    </React.Fragment>
  );
}

export default PartnerJobinvitation;
