import PropTypes from "prop-types";
import React, { createContext, useEffect, useState } from "react";

import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";
import { log_debug } from "./apiHelpers/logger";
import { authenticate } from "./AuthenticationSlice/AuthenticationSlice";
import {
  setClientGuid,
  setGuid,
  setIndividualGuid,
  setPartnerGuid,
} from "./apiConfig/globalvariables";
import PageMaintenance from "./pages/Utility/PageMaintenance";
import Preloader from "./components/Common/Preloader";

export const AuthContext = createContext();


const App = (props) => {

  const environment = window.location.hostname;
  const location = useLocation();
  const dispatch = useDispatch();
  const [authenticated, setIsAuthenticated] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const [disableauthenticate, setDisableAuthenticate] = useState(searchParams.get("disableAuthenticate"));
  const [lastVisitedUrl, setLastVisitedUrl] = useState(() => {
    return localStorage.getItem('path');
  });

//   const [userDetails, setUserDetails] = useState(() => {
//     const storedDetails = localStorage.getItem('userdata');
//     return storedDetails ? JSON.parse(storedDetails) : {};
// });

const [userDetails, setUserDetails] = useState(() => {
  const storedDetails = localStorage.getItem('userdata');
  try {
      return storedDetails ? JSON.parse(storedDetails) : {};
  } catch (error) {
      console.error("Error parsing JSON:", error);
      return {};
  }
});

  const [userGuid, setUserGuid] = useState(() => {
    return localStorage.getItem('userguid') || "";
  });

  const [sessionToken, setSessionToken] = useState(() => {
    return localStorage.getItem('sessiontoken') || "";
  });


  useEffect(() => {
    if(searchParams){
    const params = Object.fromEntries([...searchParams]);
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      ...params, 
    }));
    localStorage.setItem('path', window.location.pathname)
  }else if(userDetails){
    log_debug("entered else block")
    localStorage.setItem('userguid', userDetails?.user?.guid);
    localStorage.setItem('guid', userDetails?.user?.parent_guid);
    setGuid(userDetails?.user?.parent_guid);
    localStorage.setItem('persona', userDetails?.user?.parent_type);
    localStorage.setItem('path', window.location.pathname)
    }
  }, []);

  useEffect(() => {
        localStorage.setItem('path', window.location.pathname)
        setLastVisitedUrl(window.location.pathname)
      }, [location.pathname]);


  useEffect(() => {
    localStorage.setItem('userguid', userDetails?.userguid || userDetails?.user?.guid);
    setGuid(userDetails?.partner || userDetails?.individual || userDetails?.client ||  userDetails?.user?.parent_guid);
    localStorage.setItem('guid', userDetails?.partner || userDetails?.individual || userDetails?.client ||  userDetails?.user?.parent_guid);
    localStorage.setItem('persona', userDetails?.persona || userDetails?.user?.parent_type);
    setUserGuid(userDetails?.userguid || userDetails?.user?.guid);
    log_debug("userdetails", userDetails);
  }, [userDetails]);

  useEffect(() => {
    try {
      log_debug("userguid123",userGuid)
        log_debug("entered authentication")
      const timer = setTimeout(() => {
        
          dispatch(authenticate(userGuid)).then((response) => {
            if(response.payload?.success === true){
            const data = response?.payload?.data;
            localStorage.setItem('userdata', JSON.stringify(data));
            localStorage.setItem('sessiontoken', data?.user?.session_token);
            setGuid(data?.user?.parent_guid)
            setIsAuthenticated(true);
          }else if(response.payload?.success === false){
            localStorage.clear();
            if (environment.includes("localhost")) {
              window.location.href = "http://localhost:5173/c/signin";
            } else if (environment.includes("app.companybench.in")) {
              window.location.href = "https://companybench.in/c/signin";
            } else if (environment.includes("app.companybench.com")) {
              window.location.href = "https://companybench.com/c/signin";
            } else if (environment.includes("cb-webapp-dev.netlify.app")) {
              window.location.href = "https://cb-website-dev.netlify.app/c/signin";
            }
          }
          });
        
      }, 500);
   
  
      return () => clearTimeout(timer);
      
    } catch (error) {
      log_debug("error",error)
    }
    
  }, [userGuid]);



  return (
        <React.Fragment>
          {/* <Router> */}
          <Routes>
          {disableauthenticate ? (
            authRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={<NonAuthLayout>{route.component}</NonAuthLayout>}
                key={idx}
                exact={true}
              />
            ))
          ) : (userRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthContext.Provider value={userDetails}>
                  {userDetails && authenticated && (
                    <Authmiddleware
                      persona={userDetails?.user?.parent_type}
                      isAuthenticated={true}
                      lastVisitedUrl={lastVisitedUrl}
                    >
                      <VerticalLayout persona={userDetails?.user?.parent_type || localStorage.getItem("persona")}>
                        {route.component}
                      </VerticalLayout>
                    </Authmiddleware>
                  )}
                </AuthContext.Provider>
              }
              key={idx}
              exact={true}
            />
          )))}
          </Routes>
        </React.Fragment>
      );

  

}
// const App = (props) => {
//   // function getLayout() {
//   //   let layoutCls = VerticalLayout
//   //   // switch (props.layout.layoutType) {
//   //   //   case "horizontal":
//   //   //     layoutCls = HorizontalLayout
//   //   //     break
//   //   //   default:
//   //   //     layoutCls = VerticalLayout
//   //   //     break
//   //   // }
//   //   return layoutCls
//   // }

//   // const Layout = getLayout()

//   const environment = window.location.hostname;

//   log_debug("environment", environment);

//   const location = useLocation();
//   const searchParams = new URLSearchParams(location.search);
//   log_debug("searchParams", searchParams);

//   const [individual, setIndividual] = useState(searchParams.get("individual"));
//   const [partner, setpartner] = useState(searchParams.get("partner"));
//   const [client, setclient] = useState(searchParams.get("client"));
//   const [userid, setUserguid] = useState(searchParams.get("userguid"));
//   const [disableauthenticate, setDisableAuthenticate] = useState(searchParams.get("disableAuthenticate"))

//   const [expirationtime, setExpirationtime] = useState(
//     searchParams.get("expirationtime"),
//   );
//   const [persona, setPersona] = useState(searchParams.get("persona"));
//   //const [CompanyName, setCompanyName] = useState(searchParams.get("company_name"));
//   //log_debug("CompanyNameCompanyName",CompanyName)
//   //localStorage.setItem("UserCompanyName",CompanyName)

//   const [lastVisitedUrl, setLastVisitedUrl] = useState("");
//   const [userData, setUserData] = useState([]);
//   const [UserCompanyName, setUserCompanyName] = useState(
//     searchParams.get("company_name"),
//   );
//   log_debug("UserCompanyName", UserCompanyName);
//   //localStorage.setItem("UserCompanyName",UserCompanyName);
//   const [isAuthenticated, setIsAuthenticated] = useState(
//     !!localStorage.getItem("userguid"),
//   );
//   const [Homepageloading, setHomepageloading] = useState(false);
//   useEffect(() => {
//     setLastVisitedUrl(location.pathname);
//   }, [location.pathname]);

//   // const individual = searchParams.get("individual");
//   // const partner = searchParams.get("partner");
//   // const client = searchParams.get("client");
//   // const userid = searchParams.get("userguid");
//   // const expirationtime = searchParams.get("expirationtime");
//   // const persona = searchParams.get("persona");

//   log_debug("individual guid", individual);
//   log_debug("partner guid", partner);
//   log_debug("client guid", client);
//   log_debug("userid", userid);
//   log_debug("expire time", expirationtime);
//   log_debug("persona", persona);

//   const dispatch = useDispatch();
//   const AuthenticationStore = useSelector((state) => state.authenticationSlice);

//   useEffect(() => {
//     if (individual != null) {
//       localStorage.clear(); // Clear existing local storage
//       localStorage.setItem("guid", individual);
//       setIndividualGuid(individual);
//     } else if (partner != null) {
//       localStorage.clear(); // Clear existing local storage
//       localStorage.setItem("guid", partner);
//       setPartnerGuid(partner);
//     } else if (client != null) {
//       localStorage.clear(); // Clear existing local storage
//       localStorage.setItem("guid", client);
//       setClientGuid(client);
//     }
//     log_debug("on refresh persona", persona);
//     if (userid != null) {
//       localStorage.setItem("userguid", userid);
//     }
//     if (persona != null) {
//       localStorage.setItem("persona", persona);
//       setPersona(persona);
//     }
//     if (expirationtime != null) {
//       localStorage.setItem("expirationtime", expirationtime);
//     }

//     // Authenticate with the new parameters
//     // if (userid != null) {
//     //   dispatch(authenticate(userid));
//     //   setLastVisitedUrl("/");
//     // }
//   }, [individual, partner, client, persona, expirationtime, userid]);

//   // useEffect(() =>{
//   //   log_debug("entered useEffect")
//   //   dispatch(authenticate(userid));
//   // },[]);

//   useEffect(() => {
//     setHomepageloading(true)
//     if (
//       AuthenticationStore.status === "idle"
//     ) {
//       if(AuthenticationStore.data.authenticateuser?.success === true){
//       setHomepageloading(false);
//       const userData = AuthenticationStore.data.authenticateuser?.data?.user;
//       setUserData(userData); // Set state
//       setUserCompanyName(userData?.company_name); // Set state
//       setIsAuthenticated(true); // Mark as authenticated
//       setPersona(userData.parent_type);
//       // Store user data and persona in local storage
//       localStorage.setItem('sessiontoken',userData?.session_token);
//       localStorage.setItem("UserCompanyName", userData.company_name);
//       localStorage.setItem("userguid", userid);
//       localStorage.setItem("userdata", JSON.stringify(userData));
//       localStorage.setItem("persona", userData.parent_type);
//       localStorage.setItem("guid", userData.parent_guid);
//       localStorage.setItem("expirationtime", Date.now() + 24 * 60 * 60 * 1000);
//       setIndividualGuid(userData.parent_guid);
//       }else if(AuthenticationStore.data.authenticateuser?.success === false){
//         if (environment.includes("localhost")) {
//           window.location.href = "http://localhost:5173/c/signin";
//         } else if (environment.includes("app.companybench.in")) {
//           window.location.href = "https://companybench.in/c/signin";
//         } else if (environment.includes("app.companybench.com")) {
//           window.location.href = "https://companybench.com/c/signin";
//         } else if (environment.includes("cb-webapp-dev.netlify.app")) {
//           window.location.href = "https://cb-website-dev.netlify.app/c/signin";
//         }
//       }
//     } else if (AuthenticationStore.status === "loading") {
//       setHomepageloading(true);
//     }
//   }, [AuthenticationStore, userid]);

//   const navigate = useNavigate();

//   useEffect(() => {
//     // Check local storage for user data when component mounts
//     setUserguid(localStorage.getItem("userguid"));
//     const storedUserData = JSON.parse(localStorage.getItem("userdata"));
//     if (storedUserData) {
//       setUserData(storedUserData);
//       setPersona(storedUserData.parent_type);
//       setUserCompanyName(storedUserData?.company_name);
//       //localStorage.setItem("UserCompanyName",storedUserData?.company_name);

//       setIsAuthenticated(true);
//       // setIndividual(storedUserData.parent_guid);
//       setUserguid(storedUserData.guid);
//       setExpirationtime(Date.now() + 24 * 60 * 60 * 1000);
//       setIndividualGuid(storedUserData.parent_guid);
//       if (storedUserData.parent_type === "individual") {
//         setIndividual(storedUserData.parent_guid);
//         setIndividualGuid(storedUserData.parent_guid);
//       } else if (storedUserData.parent_type === "partner") {
//         setpartner(storedUserData.parent_guid);
//         setPartnerGuid(storedUserData.parent_guid);
//       } else if (storedUserData.parent_type === "client") {
//         setclient(storedUserData.parent_guid);
//         setClientGuid(storedUserData.parent_guid);
//       }
//     } else {
//       setIsAuthenticated(false);
//     }
//     const storedUrl = localStorage.getItem("lastVisitedUrl");
//     if (storedUrl) {
//       setLastVisitedUrl(storedUrl);
//     }
//   }, []);

//   useEffect(() => {
//     if(disableauthenticate) {
//       navigate("/individual/resume/upload?disableAuthenticate=true")
//     }else{
//     if (!isAuthenticated) {
//       if (userid) {
//         dispatch(authenticate(userid));
//       }
//       //  else if (environment.includes("localhost")) {
//       //   window.location.href = "http://localhost:5173/c/signin";
//       // } else if (environment.includes("app.companybench.in")) {
//       //   window.location.href = "https://companybench.in/c/signin";
//       // } else if (environment.includes("app.companybench.com")) {
//       //   window.location.href = "https://companybench.com/c/signin";
//       // } else if (environment.includes("cb-webapp-dev.netlify.app")) {
//       //   window.location.href = "https://cb-website-dev.netlify.app/c/signin";
//       // }
//     }
//     }
//   }, [isAuthenticated,disableauthenticate]);

//   useEffect(() => {
//     if (isAuthenticated) {
//       localStorage.setItem("lastVisitedUrl", location.pathname);
//     }
//   }, [isAuthenticated, location.pathname]);

//   //  useEffect(() => {
//   //    // Check expiration time
//   //    const expirationTime = localStorage.getItem("expirationtime");
//   //    if (expirationTime && (Date.now() - parseInt(expirationTime)<0)) {
//   //      setIsAuthenticated(false);
//   //      localStorage.removeItem("userdata");
//   //      localStorage.removeItem("userguid");
//   //      localStorage.removeItem("persona");
//   //      localStorage.removeItem("expirationtime");
//   //    }
//   //  }, []);

//   const authContext = {
//     userData,
//   };

//   return (
//     <React.Fragment>
//       {/* <Router> */}
//       <Routes>
//       {disableauthenticate ? (
//         authRoutes.map((route, idx) => (
//           <Route
//             path={route.path}
//             element={<NonAuthLayout>{route.component}</NonAuthLayout>}
//             key={idx}
//             exact={true}
//           />
//         ))
//       ) : (userRoutes.map((route, idx) => (
//         <Route
//           path={route.path}
//           element={
//             <AuthContext.Provider value={authContext}>
//               {isAuthenticated && localStorage.getItem("guid") != null && (
//                 <Authmiddleware
//                   persona={persona}
//                   userguid={userid}
//                   isAuthenticated={isAuthenticated}
//                   lastVisitedUrl={lastVisitedUrl}
//                 >
//                   <VerticalLayout persona={persona}>
//                     {route.component}
//                   </VerticalLayout>
//                 </Authmiddleware>
//               )}
//             </AuthContext.Provider>
//           }
//           key={idx}
//           exact={true}
//         />
//       )))}
//         {/* {authRoutes.map((route, idx) => (
//           <Route
//             path={route.path}
//             element={<NonAuthLayout>{route.component}</NonAuthLayout>}
//             key={idx}
//             exact={true}
//           />
//         ))}

//         {userRoutes.map((route, idx) => (
//           <Route
//             path={route.path}
//             element={
//               <AuthContext.Provider value={authContext}>
//                 {isAuthenticated && localStorage.getItem("guid") != null && (
//                   <Authmiddleware
//                     persona={persona}
//                     userguid={userid}
//                     isAuthenticated={isAuthenticated}
//                     lastVisitedUrl={lastVisitedUrl}
//                   >
//                     <VerticalLayout persona={persona}>
//                       {route.component}
//                     </VerticalLayout>
//                   </Authmiddleware>
//                 )}
//               </AuthContext.Provider>
//             }
//             key={idx}
//             exact={true}
//           />
//         ))} */}
//       </Routes>
//     </React.Fragment>
//   );
// };

// App.propTypes = {
//   layout: PropTypes.any
// }

// const mapStateToProps = state => {
//   return {
//     layout: state.Layout,
//   }
// }

//export default connect(mapStateToProps, null)(App)
export default App;
