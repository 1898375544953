import React from 'react'
import {
    Alert,
    Button,
    ButtonGroup,
    Card,
    CardBody,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    UncontrolledDropdown,
  } from "reactstrap";
  import { Link } from "react-router-dom";
  import { FiEdit, FiTrash2 } from "react-icons/fi";
  
  //import images
  import avatar from "../../assets/images/users/avatar-2.jpg";
  import techilalogo from "../../assets/images/companyBenchImage/jobgray1.jpg";
  import CandidateDetailsWorkExperienceCanvas from "./CandidateDetailsWorkExperienceCanvas";
  import { log_debug } from "../../apiHelpers/logger";
import { formatDate } from '../../helpers/date';

function CandidateWorkExperience({data,handleDeleteExp}) {
  const handleDeleteWorkExp = (index) => {
    handleDeleteExp(index)
  }
  return (
    <React.Fragment>
      <Row>
        <Col xl={12} lg={12}>
          {data?.length > 0 ? (
            data?.map((workExperience, index) => (
              <Card key={index} style={{ backgroundColor: "ghostwhite" }}>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div className="flex-shrink-0">
                          <div className="avatar-xl me-3">
                            <img
                              src={techilalogo}
                              alt=""
                              className="img-fluid rounded-circle d-block "
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">
                              {workExperience.role}
                            </h5>
                            <h5 className="text-muted font-size-13 mb-1">
                              {workExperience.company_name}
                            </h5>
                            <h5 className="text-muted font-size-13 mb-1">
                              {formatDate(workExperience.from_date)} -{" "}
                              {workExperience.to_date
                                ? formatDate(workExperience.to_date)
                                : "Present"}
                            </h5>
                            {/* <h5 className="text-muted font-size-13 mb-1">
                              <i className="mdi mdi-map-marker me-1 text-primary align-middle"></i>{" "}
                              Pune, India
                            </h5> */}

                            {/* <div className="d-flex flex-wrap align-items-start gap-lg-3 font-size-14 mt-3">
                              <div>
                                <span>
                                  Skills:{" "}
                                  {data &&
                                    data?.workSkillTab1?.map(
                                      (skills, index) => (
                                        <Link
                                          key={index}
                                          to="#"
                                          className="badge bg-primary-subtle text-primary mx-1 p-1"
                                        >
                                          {skills}
                                        </Link>
                                      ),
                                    )}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                              <div>
                                <strong>Znex HRMS :-</strong>
                                <span>
                                  {data.summary}...
                                  <Link to="#">See more</Link>
                                </span>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-auto order-1 order-sm-2">
                      <div className="d-flex align-items-start justify-content-end gap-2">
                        <ButtonGroup>
                          <Button
                            color="danger"
                            onClick={() =>
                              handleDeleteWorkExp(index)
                            }
                          >
                            <FiTrash2 /> Delete
                          </Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </Row>
                  
                </CardBody>
              </Card>
            ))
          ) : (
            <Alert
              color="info"
              className="alert-label-icon label-arrow rounded-2"
            >
              {/* <FeatherIcon icon="info" size={15} className="fs-5 align-middle me-1" /> */}
              No work experience found. Click the 'Add' button to include your
              work experience details and highlight your expertise. 📚🎓
              {/* {/* <i className="mdi mdi-account-circle-outline h4 fs-5 align-middle me-1"></i> */}
              <i className="mdi mdi-alert-circle-outline label-icon"></i>
            </Alert>
          )}
        </Col>
        
      </Row>
    </React.Fragment>
  )
}

export default CandidateWorkExperience