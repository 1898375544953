import React, { useEffect, useState } from "react";
import { FiUserPlus } from "react-icons/fi";
import {
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import CustomTable from "../customComponents/reactTable/CustomTable";
import AddUserCanvas from "../customComponents/offcanvas/AddUserCanvas";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser, getUsers } from "./PartnerSlice";
import { log_debug } from "../apiHelpers/logger";
import { dateyyyymmdd_tohtml } from "../helpers/date";
import CustomAvatar from "../customComponents/CustomAvatar/CustomAvatar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function MyTeamUser() {
  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [deleteData, setDeleteData] = useState({});

  const closeOffcanvas = () => {
    log_debug("ouroeruwe")
    setIsEdit(false);
    setOffcanvasOpen(false);
  };

  useEffect(() => {
    dispatch(getUsers());
    
  }, []);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore.data?.UserDetails?.success === true) {
        setUsersData(partnerStore.data?.UserDetails?.data);
      }
    }

    if(isOffcanvasOpen){
      setOffcanvasOpen(false);
    }
  }, [partnerStore.data?.UserDetails]);

  const columns = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <div style={{ display: "flex", alignItems: "top" }}>
          <CustomAvatar fullName={row.values.full_name} />
          <span>{row.values.full_name}</span>
        </div>
      ),
    },
    {
      Header: "Email",
      accessor: "email_id",
    },
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: (props) => {
        return <span>{dateyyyymmdd_tohtml(props && props.value)}</span>;
      },
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({row}) => {
        return (
          <>
            <div className="btn-group btn-group-example " role="group">
              <button type="button"  className="btn btn-primary w-xs p-1" onClick={() => handleEditUser(row.original)}>
                <i className="mdi mdi-pencil fs-5"></i>
              </button>
              <button 
              type="button" 
              className="btn btn-danger w-xs p-1" 
              onClick={() => tog_center(row.original)}
              data-toggle="modal"
              data-target=".bs-example-modal-center"
                >
                <i className="mdi mdi-delete fs-5"></i>
              </button>
            </div>
          </>
        );
      },
    },
  ];

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_center(data) {
    setDeleteData(data);
    setmodal_center(!modal_center);
    removeBodyCss();
  }


  const handleEditUser = (data) => {
    log_debug("editdata",data);
    setEditData(data);
    setIsEdit(true);
    setOffcanvasOpen(true);
  }

  const handleDeleteUser = () => {
    try {
      if(deleteData){
        dispatch(deleteUser({guid: deleteData?.guid})).then((response) => {
          if(response.payload?.success === true){
            setmodal_center(false);
            setDeleteData({});
            dispatch(getUsers());
            toast.success(response.payload?.data?.message);
          }
        });
      }
    } catch (error) {
      log_debug("error",error)
    }
   
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl={12}>
              <h1>Manage Team</h1>
            </Col>
          </Row>

          <Row>
            <Col xl={12} className="text-end">
              <Button color="primary" onClick={() => setOffcanvasOpen(true)}>
                <i className="fa fa-user-plus mx-1"></i>Add User
              </Button>
            </Col>
          </Row>

          <Row>
            <CustomTable
              columns={columns}
              data={usersData}
              hideCheckbox={true}
            />
          </Row>
        </Container>
      </div>

      <Modal
      size="sm"
      backdrop={"static"}
                      isOpen={modal_center}
                      toggle={() => {
                        tog_center();
                      }}
                      centered={true}
                    >
                      <div className="">
                        {/* <h5 className="modal-title mt-0">Please Confirm</h5> */}
                        {/* <button
                          type="button"
                          onClick={() => {
                            setmodal_center(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button> */}
                      </div>
                      <div className="modal-body text-center p-4">
                        <h4>Are you sure?</h4>
                        <p className="text-muted">Are you sure you want to delete the user?</p>
                        <div className="d-flex justify-content-center my-2">
                        <Button color="success" className="px-3" onClick={() => setmodal_center(false)}>No</Button>
                          <Button color="danger" className="px-3 mx-2" onClick={() => handleDeleteUser()}>Yes</Button>
                          
                        </div>
                      </div>
                    </Modal>
      
      {isOffcanvasOpen && (
        <AddUserCanvas
          usertype={"partner"}
          placement="end"
          width={50}
          isOpen={isOffcanvasOpen}
          toggle={() => closeOffcanvas()}
          onClose={closeOffcanvas}
          data={editData}
          isEdit={isEdit}
        />
      )}
    </React.Fragment>
  );
}

export default MyTeamUser;
