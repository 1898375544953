import axios from "axios";
import { baseURL, common, baseURLassess } from "./axiosDev";
import { log_debug } from "../apiHelpers/logger";

export const sendGetRestRequestAssessment = async function (api_ep) {
  try {
    const response = await axios.get(baseURLassess + api_ep, {
      method: "get",
      headers: {
        "Content-Type": "multipart/form-data",
        service_ref: "b8ilcgos",
      },
      data: "",
    });
    return response;
  } catch (error) {
    // log("Error in sendGetRestRequest:", error);
    throw error;
  }
};

export const sendGetRestRequest = async function (api_ep) {
  try {
    const response = await axios.get(baseURL + api_ep, {
      method: "get",
      headers: {
        "Content-Type": "multipart/form-data",
        service_ref: common.service_ref,
        Authorization: `Bearer ${localStorage.getItem("sessiontoken")}`,
        user_guid: localStorage.getItem("userguid")
      },
      data: "",
    });
    return response;
  } catch (error) {
    log_debug("api response93847",error)
    if(error?.response?.status === 401 && error?.response?.data?.error === "Unauthorized. Invalid or expired session."){
      localStorage.removeItem("userguid");
    localStorage.removeItem("userdata");
    localStorage.removeItem("lastVisitedUrl");
    localStorage.removeItem("guid");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("persona");
    localStorage.removeItem("sessiontoken");
    redirecthomepage();
    }
    console.log("Error in sendGetRestRequest:", error);
    throw error;
  }
};

export const sendGetRestRequestJson = async function (api_ep) {
  try {
    const response = await axios.get(baseURL + api_ep, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        service_ref: common.service_ref,
        Authorization: `Bearer ${localStorage.getItem("sessiontoken")}`,
        user_guid: localStorage.getItem("userguid")
      },
      data: "",
    });
    return response;
  } catch (error) {
    log_debug("api response93847",error)
    if(error?.response?.status === 401 && error?.response?.data?.error === "Unauthorized. Invalid or expired session."){
      localStorage.removeItem("userguid");
    localStorage.removeItem("userdata");
    localStorage.removeItem("lastVisitedUrl");
    localStorage.removeItem("guid");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("persona");
    localStorage.removeItem("sessiontoken");
    redirecthomepage();
    }
    console.log(" in sendGetRestRequest:", error);
    throw error;
  }
};

export const sendPostAuthRequest = async function (api_ep, req_body) {
  try {
    const response = await axios.post(baseURL + api_ep, req_body, {
      method: "post",
      headers: {
        // "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        service_ref: common.service_ref,
      },
    });
    return response;
  } catch (error) {
    console.error("Error in sendPostRestRequest:", error);
    throw error;
  }
};

export const sendPostRestRequest = async function (api_ep, req_body) {
  try {
    const response = await axios.post(baseURL + api_ep, req_body, {
      method: "post",
      headers: {
        // "Content-Type": "application/json",
        "Content-Type": "multipart/form-data",
        service_ref: common.service_ref,
        Authorization: `Bearer ${localStorage.getItem("sessiontoken")}`,
        user_guid: localStorage.getItem("userguid")
      },
    });
    return response;
  } catch (error) {
    if(error?.response?.status === 401 && error?.response?.data?.error === "Unauthorized. Invalid or expired session."){
      localStorage.removeItem("userguid");
    localStorage.removeItem("userdata");
    localStorage.removeItem("lastVisitedUrl");
    localStorage.removeItem("guid");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("persona");
    localStorage.removeItem("sessiontoken");
    redirecthomepage();
    }
    console.error("Error in sendPostRestRequest:", error);
    throw error;
  }
};

export const sendPostRestRequestAppJson = async function (api_ep, req_body) {
  try {
    const response = await axios.post(baseURL + api_ep, req_body, {
      method: "post",
      headers: {
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
        service_ref: common.service_ref,
        Authorization: `Bearer ${localStorage.getItem("sessiontoken")}`,
        user_guid: localStorage.getItem("userguid")
      },
    });
    return response;
  } catch (error) {
    if(error?.response?.status === 401 && error?.response?.data?.error === "Unauthorized. Invalid or expired session."){
      localStorage.removeItem("userguid");
    localStorage.removeItem("userdata");
    localStorage.removeItem("lastVisitedUrl");
    localStorage.removeItem("guid");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("persona");
    localStorage.removeItem("sessiontoken");
    redirecthomepage();
    }
    console.log("Error in sendPostRestRequestAppJson:", error);

    throw error;
  }
};

export const sendPutRestRequestAppJson = async function (api_ep, req_body) {
  try {
    const response = await axios.put(baseURL + api_ep, req_body, {
      method: "put",
      headers: {
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
        service_ref: common.service_ref,
        Authorization: `Bearer ${localStorage.getItem("sessiontoken")}`,
        user_guid: localStorage.getItem("userguid")
      },
    });
    return response;
  } catch (error) {
    if(error?.response?.status === 401 && error?.response?.data?.error === "Unauthorized. Invalid or expired session."){
      localStorage.removeItem("userguid");
    localStorage.removeItem("userdata");
    localStorage.removeItem("lastVisitedUrl");
    localStorage.removeItem("guid");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("persona");
    localStorage.removeItem("sessiontoken");
    redirecthomepage();
    }
    console.log("Error in sendPostRestRequestAppJson:", error);

    throw error;
  }
};

export const sendDeleteRestRequestAppJson = async function (api_ep) {
  try {
    const response = await axios.delete(baseURL + api_ep, {
      method: "delete",
      headers: {
        // "Content-Type": "multipart/form-data",
        "Content-Type": "application/json",
        service_ref: common.service_ref,
        Authorization: `Bearer ${localStorage.getItem("sessiontoken")}`,
        user_guid: localStorage.getItem("userguid")
      },
    });
    return response;
  } catch (error) {
    if(error?.response?.status === 401 && error?.response?.data?.error === "Unauthorized. Invalid or expired session."){
      localStorage.removeItem("userguid");
    localStorage.removeItem("userdata");
    localStorage.removeItem("lastVisitedUrl");
    localStorage.removeItem("guid");
    localStorage.removeItem("expirationTime");
    localStorage.removeItem("persona");
    localStorage.removeItem("sessiontoken");
    redirecthomepage();
    }
    console.log("Error in sendPostRestRequestAppJson:", error);

    throw error;
  }
};

export const generate_guid = function () {
  return common.guid_format.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const redirecthomepage = function() {
  const environment = window.location.hostname;
  log_debug("function called")
  if (environment.includes("localhost")) {
    window.location.href = "http://localhost:5173/c/signin";
  } else if (environment.includes("app.companybench.in")) {
    window.location.href = "https://companybench.in/c/signin";
  } else if (environment.includes("app.companybench.com")) {
    window.location.href = "https://companybench.com/c/signin";
  } else if (environment.includes("cb-webapp-dev.netlify.app")) {
    window.location.href = "https://cb-website-dev.netlify.app/c/signin";
  }
}
