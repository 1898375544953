// CustomOffcanvas.js
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import dayjs from "dayjs";
import { FiDownload, FiFileText, FiPlus, FiUserPlus } from "react-icons/fi";
import { FaBusinessTime } from "react-icons/fa6";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import techilalogo from "../../assets/images/companyBenchImage/jobgray1.jpg";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  Offcanvas,
  TabContent,
  OffcanvasHeader,
  OffcanvasBody,
  TabPane,
  UncontrolledDropdown,
  Button,
  ButtonGroup,
  Badge,
  CardTitle,
  CardText,
} from "reactstrap";
import "../../Partner/partner.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CustomTable from "../reactTable/CustomTable";
import { log_debug } from "../../apiHelpers/logger";
import { toast, ToastContainer } from "react-toastify";
import {
  DashboardCounter,
  JobHistory,
  getJobSubmissionCount,
  invitedCandidates,
  // machingCandidateApplyJob,
  machingJobApplyJob,
  matchingCandidates,
  shortlistedCandidates,
} from "../../Partner/PartnerSlice";
import TimeLineComponent from "../timelineComponents/TimeLineComponent";
import { Tags } from "../../pages/Contacts/ContactList/contactlistCol";
import { Pguid } from "../../apiConfig/axiosDev";
import CustomAvatar from "../CustomAvatar/CustomAvatar";
import JobsBadge from "../utilities/JobsBadge";
import CandidateStatusBadge from "../utilities/CandidateStatusBadge";
import { getPartnerGuid } from "../../apiConfig/globalvariables";
import moment from "moment";
import Preloader from "../../components/Common/Preloader";
import ApplyJobsCanvas from "./ApplyJobCanvas";

export function CanvasPartnerJobDetails({
  isOpen,
  toggle,
  onClose,
  data,
  Matchingstatus,
  shortlistedstatus,
  usertype,
  RecrutmentMatchingstatus,
  Alljobsstatus,
  invitationstatus,
}) {
  const [headerTab, setHeaderTab] = useState("1");
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [MatchingCandidateData, setMatchingCandidateData] = useState([]);
  const [ShortlistedCandidateData, setShortlistedCandidateData] = useState([]);
  const [InvitedCandidateData, setInvitedCandidateData] = useState([]);
  const [jobhistorydata, setjobhistorydata] = useState([]);
  log_debug("datadatadatadata", data);
  const [JobAPply, setJobApply] = useState([]);
  const [selectedCandidateIid, setSelectedCandidate2Iid] = useState(null);
  const [selectedCandidate2Pid, setSelectedCandidatePid] = useState(null);
  const [appliedRows, setAppliedRows] = useState([]);
  const [JobDescriptionGuid, SetJobDescriptionGuid] = useState([]);

  const [isloadingShortlistcandidate, setisloadingShortlistcandidate] =
    useState(true);
  const [isloadingInvitedcandidate, setisloadingInvitedcandidate] =
    useState(true);
  const [isloadingMatchingcandidate, setisloadingMatchingcandidate] =
    useState(true);

  // totalcount
  const [shortlisttotalcount, setshortlisttotalcount] = useState([]);
  const [invitetotalcount, setinvitetotalcount] = useState([]);
  const [matchingtotalcount, setmatchingtotalcount] = useState([]);

  const [currentPageshortlist, setCurrentPageshortlist] = useState(0);
  const [currentPageinvite, setCurrentPageinvite] = useState(0);
  const [currentPagematching, setCurrentPagematching] = useState(0);
  const [jobsubmissioncount, setJobSubmissionCount] = useState(0);
  const [ApplyJobCanvasOpen,setApplyJobCanvasOpen] = useState(false);
  const [ApplyJobGuid,setApplyJobGuid] = useState("");
  const [JobDetailData,setJobDetailsData] = useState("");

  const defaultEndDate = new Date();
  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 90);

  const [formattedStartDate, setformattedStartDate] = useState(
    // moment(defaultStartDate).format("YYYY-MM-DD"),
    moment(defaultStartDate).format("YYYY-MM-DD"),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );

  const [DisableApply, setDisableApply] = useState(false);

  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);

  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };
  const offcanvasStyle = {
    width: `60%`,
    //zoom: '80%',
  };
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const candidateName = (guid) => {
    setSelectedCandidate(guid);
    setOffcanvasOpen(true);
  };
  const candidateNameNew = () => {
    setSelectedCandidate();
    setOffcanvasOpen(true);
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  const closeApplyJobCanvas = () => {
    setApplyJobCanvasOpen(false);
    }

  // log("selected job content", content);

  const columnsShortlistedCanddate = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ value }) => (
        <div className="d-flex" title={value}>
          <CustomAvatar fullName={value} />
          <span>{value?.length > 10 ? `${value.slice(0, 12)}...` : value}</span>
        </div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      width: 100,
    },
    {
      Header: "Skills",
      accessor: "skills",
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
      // Cell: ({ row }) => (
      //   <span
      //     to={"#"}
      //     onClick={() => {
      //       // candidateName(row.original.guid);
      //     }}
      //   >
      //     {row.values.skills.map((skill, index) => (
      //       <Badge key={index} color="" className="bg-primary text-white m-1">
      //         {skill}
      //       </Badge>
      //     ))}
      //   </span>
      // ),
    },
    {
      Header: "Locations",
      accessor: "prefered_locations",
      Cell: ({ row }) => (
        <div className="">
          {row?.original?.prefered_locations?.length >= 1
            ? row?.original?.prefered_locations.join(", ")
            : "-"}
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <CandidateStatusBadge status={value} />,
    },

    // Add more columns as needed
  ];
  const columnsInvitedCandidate = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ value }) => (
        <div className="d-flex">
          <CustomAvatar fullName={value} />
          <span>{value?.length > 10 ? `${value.slice(0, 12)}...` : value}</span>
        </div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      width: 100,
    },
    {
      Header: "Skills",
      accessor: "skills",
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
      // Cell: ({ row }) => (
      //   <span
      //     to={"#"}
      //     onClick={() => {
      //       // candidateName(row.original.guid);
      //     }}
      //   >
      //     {row.values.skills.map((skill, index) => (
      //       <Badge key={index} color="" className="bg-primary text-white m-1">
      //         {skill}
      //       </Badge>
      //     ))}
      //   </span>
      // ),
    },
    {
      Header: "Locations",
      accessor: "prefered_locations",
      Cell: ({ row }) => (
        <div className="">
          {row?.original?.location?.length >= 1
            ? row?.original?.location.join(", ")
            : "-"}
        </div>
      ),
      Cell: ({ value }) => value.join(", "),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <CandidateStatusBadge status={value} />,
    },

    // Add more columns as needed
  ];
  const columnsMatchingcandidate = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ value }) => (
        <div className="d-flex">
          <CustomAvatar fullName={value} />
          <span>{value?.length > 10 ? `${value.slice(0, 10)}...` : value}</span>
        </div>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      width: 100,
    },
    {
      Header: "Skills",
      accessor: "skills",
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
      // Cell: ({ row }) => (
      //   <span
      //     to={"#"}
      //     onClick={() => {
      //       // candidateName(row.original.guid);
      //     }}
      //   >
      //     {row.values.skills.map((skill, index) => (
      //       <Badge key={index} color="" className="bg-primary text-white m-1">
      //         {skill}
      //       </Badge>
      //     ))}
      //   </span>
      // ),
    },
    {
      Header: "Locations",
      accessor: "prefered_locations",
      Cell: ({ row }) => (
        <div className="">
          {row?.original?.location?.length >= 1
            ? row?.original?.location.join(", ")
            : "-"}
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <CandidateStatusBadge status={value} />,
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ({ row }) => (
        <>
          <Link to={"#"} className="text-white">
            <Button
              color={appliedRows.includes(row.id) ? "primary" : "primary"}
              disabled={DisableApply}
              onClick={() => handleButtonClick(row)}
            >
              <i className=" fas fa-paper-plane fs-6"></i>&nbsp; Apply
            </Button>
          </Link>
        </>
      ),
      // Cell: ({ row }) => (
      //   <>
      //     <Link to={"#"} className="text-white">
      //       <Button
      //         color={appliedRows.includes(row.id) ? "success" : "primary"}
      //         disabled={appliedRows.includes(row.id)}
      //         onClick={() => handleButtonClick(row)}
      //       >
      //         {appliedRows.includes(row.id) ? "Applied" : "Apply"}
      //       </Button>

      //     </Link>
      //     <ToastContainer />
      //   </>
      // ),
    },

    // Add more columns as needed
  ];

  const handleButtonClick = async (row) => {
    setDisableApply(true);
    dispatch(
      machingJobApplyJob({
        individualjobmapping: {
          individual_guid: [row.original.guid],
          partner_guid: getPartnerGuid() || Pguid,
          jobdescription_guid: [data && data.guid],
        },
      }),
    ).then((response) => {
      if (response.payload.success === true) {
        dispatch(
          matchingCandidates({
            JguidUser: data.guid,
            currentPage: currentPagematching
              ? currentPagematching
              : currentPagematching,
          }),
        );
        setTimeout(() => {
          // dispatch(matchingCandidates(data && data.guid));

          // dispatch(shortlistedCandidates(data && data.guid));
          dispatch(
            shortlistedCandidates({
              JguidUser: data.guid,
              currentPage: currentPageshortlist
                ? currentPageshortlist
                : currentPageshortlist,
            }),
          );
        }, 2000);
        dispatch(
          DashboardCounter({
            from_date: formattedStartDate,
            to_date: formattedEndDate,
          }),
        );
        toast.success("Job applied successfully!");
        setDisableApply(false);
      } else if(response.payload.success === false) {
        log_debug("4r93",response)
        toast.warning(response?.payload?.error?.message);
        setDisableApply(false);
      }
    });
  };

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (
        partnerStore.data.matchingCandidatesdata?.success === true &&
        partnerStore.data.machingJobApplyJobRecords?.success === true
      ) {
        setMatchingCandidateData(
          partnerStore.data.matchingCandidatesdata?.data?.matchingIndividuals,
        );
      }
      if (partnerStore.data.shortlistedCandidatesdata?.success === true) {
        setShortlistedCandidateData(
          partnerStore.data.shortlistedCandidatesdata?.data?.individuals,
        );
      }
    }
  }, [JobAPply]);

  useEffect(() => {
    // dispatch(matchingCandidates(data.guid));
    // dispatch(shortlistedCandidates(data.guid));
    // dispatch(invitedCandidates(data.guid));
    log_debug("data23423",data)
    dispatch(getJobSubmissionCount({jobguid:data?.guid})).then((res) => {
      const count = res.payload?.data;
      setJobSubmissionCount(count);
    })
  }, [data]);

  useEffect(() => {
    dispatch(
      shortlistedCandidates({
        JguidUser: data.guid,
        currentPage: currentPageshortlist
          ? currentPageshortlist
          : currentPageshortlist,
      }),
    );
  }, [currentPageshortlist]);

  useEffect(() => {
    dispatch(
      invitedCandidates({
        JguidUser: data.guid,
        currentPage: currentPageinvite ? currentPageinvite : currentPageinvite,
      }),
    );
  }, [currentPageinvite]);

  useEffect(() => {
    dispatch(
      matchingCandidates({
        JguidUser: data.guid,
        currentPage: currentPagematching
          ? currentPagematching
          : currentPagematching,
      }),
    );
  }, [currentPagematching]);

  useEffect(() => {
    dispatch(JobHistory(data.guid));
  }, [data]);

  let valdata = {
    individual_guid: [selectedCandidateIid],
    partner_guid: [selectedCandidate2Pid],
    job_ids: [data.guid],
  };
  log_debug("valdata", valdata);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore.data.matchingCandidatesdata?.success === true) {
        setMatchingCandidateData(
          partnerStore.data.matchingCandidatesdata?.data?.matchingIndividuals,
        );
        setmatchingtotalcount(
          partnerStore.data.matchingCandidatesdata?.data?.totalCount,
        );
        setisloadingMatchingcandidate(false);
      }
      if (partnerStore.data.shortlistedCandidatesdata?.success === true) {
        setShortlistedCandidateData(
          partnerStore.data.shortlistedCandidatesdata?.data?.individuals,
        );
        setshortlisttotalcount(
          partnerStore.data.shortlistedCandidatesdata?.data?.totalCount,
        );
        setisloadingShortlistcandidate(false);
      }
      if (partnerStore.data.invitedCandidatesdata?.success === true) {
        setInvitedCandidateData(
          partnerStore.data.invitedCandidatesdata?.data?.individuals,
        );
        setinvitetotalcount(
          partnerStore.data.invitedCandidatesdata?.data?.totalCount,
        );
        setisloadingInvitedcandidate(false);
      }
      if (partnerStore.data.JobHistorydata?.success === true) {
        setjobhistorydata(
          partnerStore.data.JobHistorydata?.data?.jobdescriptionhistory,
        );
      }
      if (partnerStore.data.machingJobApplyJobRecords?.success === true) {
        setJobApply(
          partnerStore.data.machingJobApplyJobRecords?.data
            .individualjobmapping,
        );
      }
    }
  }, [partnerStore]);

  // Sample timeline data
  const timelineData = [
    {
      date: "25 June",
      icon: "bx bx-briefcase-alt-2",
      title: "Timeline Event One",
      content:
        "Perspitis unde omnis it voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae explicabo.",
    },
    {
      date: "25 June",
      icon: "bx bx-user-pin",
      title: "Timeline Event Two",
      content:
        "At vero eos dignissimos ducimus quos dolores chooses to enjoy pleasure that has no annoying.",
      images: ["img1.jpg", "img2.jpg"],
    },
    // Add more timeline events as needed
  ];

  useEffect(() => {
    SetJobDescriptionGuid(data.guid);
  }, []);

  const listItemStyle = {
    color: "#28a745",
    listStyleType: "none",
    position: "relative",
    paddingLeft: "1.5em",
  };

  const handlePageChangeshortlist = (newPage) => {
    setCurrentPageshortlist(newPage);
  };
  const handlePageChangeinvite = (newPage) => {
    setCurrentPageinvite(newPage);
  };

  const handlePageChangematching = (newPage) => {
    setCurrentPagematching(newPage);
  };

  const handleapplyjob = (data) => {
    setApplyJobGuid(data?.guid);
    setJobDetailsData(data);
    setApplyJobCanvasOpen(true);
    
  }

  return (
    <>
      {DisableApply && <Preloader />}
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          {/* <p className="text-light">Candidate Details</p> */}
          <h5 className="text-primary">
            Job Details&nbsp;&nbsp;
            <span className="font-size-12 text-muted mt-2">
              ⓘ Present comprehensive details for the specifically selected job.
            </span>
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody>
          <Row>
            <Col xl={12} lg={12}>
              <Card>
                <CardBody>
                  <Row>
                    <div className="col-sm order-2 order-sm-1">
                      <div className="d-flex align-items-start mt-3 mt-sm-0">
                        <div className="flex-shrink-0">
                          <div className="avatar-xl me-3">
                            <img
                              src={techilalogo}
                              alt=""
                              className="img-fluid rounded-circle d-block "
                            />
                          </div>
                        </div>
                        <div className="flex-grow-1">
                          <div>
                            <h5 className="font-size-16 mb-1">{data.title}{" "}{data?.jobId && `(${data?.jobId})`}</h5>
                            {/* <h5 className="font-size-16 mb-1">
                             Invitation {data.createdAt}
                            </h5> */}

                            <h5 className="text-muted font-size-13 mb-1">
                              <i className="mdi mdi-map-marker me-1 text-primary align-middle"></i>{" "}
                              {data.location?.length >= 1
                                ? data.location.join(", ")
                                : "-"}
                            </h5>

                            <div className="d-flex flex-wrap align-items-start gap-lg-3 font-size-14 mt-3">
                              <div>
                                <span>
                                  {data &&
                                    data.skills.map((skill, index) => (
                                      <Link
                                        key={index}
                                        to="#"
                                        className="badge bg-primary rounded-pill px-3 mx-1 p-1"
                                      >
                                        {skill}
                                      </Link>
                                    ))}
                                </span>
                              </div>
                            </div>
                            <Row className="mt-2 mx-1 mb-0">
                              <Col lg={3} className="w-auto p-0">
                              <h6 className="m-0">No of Openings :</h6>
                              </Col>
                              <Col lg={1} className="w-auto">
                              <p className="m-0">
                                {" "}{data?.openings}
                                </p>
                              </Col>
                            </Row>
                            <Row className="mt-1 mx-1 mb-0">
                              <Col lg={3} className="w-auto p-0">
                              <h6 className="m-0">Duration of Contract :</h6>
                              </Col>
                              <Col lg={1} className="w-auto">
                              <p className="m-0">
                                {data?.duration === "Not Provided" || data?.duration === "Not Pr6ovided" ?("-") : (data.duration + " months")}
                                </p>
                              </Col>
                            </Row>
                            <Row className="mt-1 mx-1 mb-0">
                              <Col lg={3} className="w-auto p-0">
                              <h6 className="m-0">Job Submissions :</h6>
                              </Col>
                              <Col lg={1} className="w-auto">
                              <p className="m-0">
                                {" "}{jobsubmissioncount}
                                </p>
                              </Col>
                            </Row>
                            <Row className="mt-1 mx-1 mb-0">
                              <Col lg={3} className="w-auto p-0">
                              <h6 className="m-0">Job Type :</h6>
                              </Col>
                              <Col lg={1} className="w-auto">
                              <p className="m-0">
                                {" "}{data?.jobType ? data?.jobType : "-"}
                                </p>
                              </Col>
                            </Row>
                            <Row className="mt-1 mx-1 mb-0">
                              <Col lg={3} className="w-auto p-0">
                              <h6 className="m-0">Min Experience :</h6>
                              </Col>
                              <Col lg={1} className="w-auto">
                              <p className="m-0">
                                {" "}{data?.minExperience ? data?.minExperience : "-"}
                                </p>
                              </Col>
                            </Row>
                            {usertype === "partner" && <Row className="mt-1 mx-1 mb-0">
                              <Col lg={3} className="w-auto p-0">
                              <h6 className="m-0">Rate :</h6>
                              </Col>
                              <Col lg={1} className="w-auto">
                              <p className="m-0">
                                {" "}{data?.budget ? data?.budget +"/-" : "-"}
                                </p>
                              </Col>
                            </Row>}
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-auto order-1 order-sm-2">
                     
                      <div>
                      {data?.jobSubmissionStatus === "Close" ? <JobsBadge status={"Currently not accepting applications."}/> :  <JobsBadge status={data.status} />}
                      </div>
                      {/* <Badge className="bg-success p-1 text-white m-1 font-size-12 float-end">
                        {data.status}
                      </Badge> */}
                      {/* {usertype === "partner" && <div className="d-flex align-items-start justify-content-end ">
                                <Link
                                  to="/addcandidate"
                                  className="btn btn-primary btn-sm m-1"
                                >
                                  <FiUserPlus />{" "}
                                  Add Candidate
                                </Link>
                              </div> } */}

                              {usertype === "partner" && <div className="d-flex align-items-start justify-content-end mt-2 ">
                                <Button color="primary" onClick={() => handleapplyjob(data)}><i className=" fas fa-paper-plane fs-6 me-2"/>Apply</Button>
                              </div> }
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {/* <Card>
                <CardBody> */}
              <Nav tabs className="nav-tabs-custom nav-justified">
                <div>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "1",
                      })}
                      onClick={() => {
                        toggleCustom("1");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">Overview</span>
                    </NavLink>
                  </NavItem>
                </div>
                {/* {(Matchingstatus ||
                  shortlistedstatus ||
                  RecrutmentMatchingstatus ||
                  Alljobsstatus) && (
                  <div>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "2",
                        })}
                        onClick={() => {
                          toggleCustom("2");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="fas fa-home"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Shortlisted Candidates{" "}
                          <Badge color="primary">
                            {(shortlisttotalcount ? shortlisttotalcount : 0)
                              .toString()
                              .padStart(2, "0")}
                          </Badge>
                        </span>
                      </NavLink>
                    </NavItem>
                  </div>
                )}
                {usertype !== "recrutment" &&
                  (Matchingstatus || invitationstatus) && (
                    <div>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customActiveTab === "3",
                          })}
                          onClick={() => {
                            toggleCustom("3");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            Invited Candidates{" "}
                            <Badge color="primary">
                              {(InvitedCandidateData
                                ? InvitedCandidateData.length
                                : 0
                              )
                                .toString()
                                .padStart(2, "0")}
                            </Badge>
                          </span>
                        </NavLink>
                      </NavItem>
                    </div>
                  )}

                {(Matchingstatus ||
                  invitationstatus ||
                  shortlistedstatus ||
                  RecrutmentMatchingstatus ||
                  Alljobsstatus) && (
                  <div>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: customActiveTab === "4",
                        })}
                        onClick={() => {
                          toggleCustom("4");
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="far fa-envelope"></i>
                        </span>
                        <span className="d-none d-sm-block">
                          Matching Candidates{" "}
                          <Badge color="primary">
                            {(matchingtotalcount ? matchingtotalcount : 0)
                              .toString()
                              .padStart(2, "0")}
                          </Badge>
                        </span>
                      </NavLink>
                    </NavItem>
                  </div>
                )}
                <div>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: customActiveTab === "5",
                      })}
                      onClick={() => {
                        toggleCustom("5");
                      }}
                    >
                      <span className="d-block d-sm-none">
                        <i className="fas fa-home"></i>
                      </span>
                      <span className="d-none d-sm-block">History</span>
                    </NavLink>
                  </NavItem>
                </div> */}
              </Nav>
              <Card className="my-3 ">
                <CardBody>
                  <TabContent
                    activeTab={customActiveTab}
                    className=" text-muted"
                  >
                    <TabPane tabId="1">
                      <Row>
                        <Col sm="12">
                          <div className="mb-4" dangerouslySetInnerHTML={{__html: data?.description}}>
                            {/* {data.requirements && (
                              <div>
                                <h5 className="font-size-15">Requirements:</h5>
                                {data.requirements.map((requirement, index) => (
                                  <>
                                    <p className="mb-2" key={index}>
                                      <i className="mdi mdi-circle-medium me-1 text-success align-middle" />
                                      {requirement}
                                    </p>
                                  </>
                                ))}
                              </div>
                            )} */}
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <CustomTable
                              isLoading={isloadingShortlistcandidate}
                              columns={columnsShortlistedCanddate}
                              data={
                                ShortlistedCandidateData &&
                                ShortlistedCandidateData
                              }
                              hideCheckbox={true}
                              totalCount={
                                shortlisttotalcount && shortlisttotalcount
                              }
                              currentPage={currentPageshortlist}
                              onPageChange={handlePageChangeshortlist}
                            />
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="3">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <CustomTable
                              isLoading={isloadingInvitedcandidate}
                              columns={columnsInvitedCandidate}
                              data={
                                InvitedCandidateData && InvitedCandidateData
                              }
                              hideCheckbox={true}
                              totalCount={invitetotalcount && invitetotalcount}
                              currentPage={currentPageinvite}
                              onPageChange={handlePageChangeinvite}
                            />
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="4">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <CustomTable
                              isLoading={isloadingMatchingcandidate}
                              JobDescriptionGuid={JobDescriptionGuid}
                              applyCandidate={true}
                              columns={columnsMatchingcandidate}
                              data={
                                MatchingCandidateData && MatchingCandidateData
                              }
                              totalCount={
                                matchingtotalcount && matchingtotalcount
                              }
                              currentPage={currentPagematching}
                              onPageChange={handlePageChangematching}
                            />
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="5">
                      <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <TimeLineComponent
                              historydata={jobhistorydata && jobhistorydata}
                            />
                          </CardText>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
        </OffcanvasBody>
      </Offcanvas>
      {ApplyJobCanvasOpen && <ApplyJobsCanvas
      placement="end"
      usertype="partner"
      isOpen={ApplyJobCanvasOpen}
      toggle={() => setApplyJobCanvasOpen(!ApplyJobCanvasOpen)}
      onclose={closeApplyJobCanvas}
      jobguid = {ApplyJobGuid}
      data = {JobDetailData}
      />}
    </>
  );
}

export default CanvasPartnerJobDetails;
