import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from 'reactstrap'
import { log_debug } from '../../apiHelpers/logger';
import { useDispatch } from 'react-redux';
import { addUsers, editUser, getUsers } from '../../Partner/PartnerSlice';
import { toast } from 'react-toastify';

function AddUserCanvas({isOpen , onClose , toggle, width, data, isEdit}) {

    const dispatch = useDispatch();
    const [userName, setUserName] = useState("");
    const [userEmail, setUseremail] = useState("");
    const [submitDisable, setSubmitDisable] = useState(false);

    const offcanvasStyle = {
        width: `${width}%`,
        // zoom: '100%',
      };

      const handleNameChange = (e) => {
        setUserName(e.target.value);
        if(e.target.value === data?.full_name || e.target.value === data?.email_id){
          setSubmitDisable(true);
        }else{
          setSubmitDisable(false)
        }
        
      }

      const handleEmailChange = (e) => {
            setUseremail(e.target.value);  
            if(e.target.value === data?.full_name || e.target.value === data?.email_id){
              setSubmitDisable(true);
            }else{
              setSubmitDisable(false)
            }
      }

    const handleUserSubmit = (e) => {
        e.preventDefault();
        log_debug("form submitted",userEmail)
        const data ={
            user_email : userEmail,
            full_name : userName
        };

        dispatch(addUsers({data:data})).then((response) => {
            const status = response.payload?.success;
            if(status){
                toast.success("User added Successfully");
                setTimeout(() => {
                    dispatch(getUsers());
                }, 2000);
            }else{
                toast.warn(response.payload?.error?.message);
            }
        });
    }


    useEffect(() => {
        log_debug("iseditvalue",isEdit)
        if(isEdit){
            setUserName(data?.full_name);
            setUseremail(data?.email_id);
            setSubmitDisable(true);
        }else{
            setUserName("");
            setUseremail("");
        }
    },[isEdit])

    const handleEditUser = (e) => {
        e.preventDefault();
        log_debug("Editeddata",userName + userEmail);
        const editedData = {
            full_name : userName,
            user_email : userEmail
        }
        dispatch(editUser({data : editedData, guid : data?.guid})).then((response) => {
            if(response.payload?.success === true){
                toast.success("User Updated Successfully.")
                setTimeout(() => {
                  dispatch(getUsers());
              }, 2000);
              setSubmitDisable(true);
            }else{
                log_debug("errorupdate",response)
                toast.warn(response?.payload?.error?.message);
            }
        })
    }


  return (
    <>
    <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
        <h5 className="text-primary">
           {isEdit ? "Edit User" : "Add User"}&nbsp;&nbsp;
            {/* <span className="font-size-12 text-muted mt-2">
              <i className="fas fa-info-circle" /> 
            </span> */}
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody>
            <Card className='p-4'>
            <Row>
                <Col xl={12}>
                <Form onSubmit={isEdit ? handleEditUser : handleUserSubmit}>
              <Row className="mb-4">
                <Label
                  htmlFor="horizontal-firstname-input"
                  className="col-sm-3 col-form-label"
                >
                  Full name
                </Label>
                <Col sm={9}>
                  <Input
                    type="text"
                    className="form-control"
                    id="horizontal-firstname-input"
                    placeholder="Enter Your Full Name"
                    onChange={handleNameChange}
                    value={userName}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Label
                  htmlFor="horizontal-email-input"
                  className="col-sm-3 col-form-label"
                >
                  Email
                </Label>
                <Col sm={9}>
                  <Input
                    type="email"
                    className="form-control"
                    id="horizontal-email-input"
                    placeholder="Enter Your Email"
                    onChange={handleEmailChange}
                    value={userEmail}
                    required
                  />
                </Col>
              </Row>

              <Row className="justify-content-center mt-5">
                <Col sm={9} className='text-center'>
                  <div>
                    <button type="submit" className="btn btn-primary w-md" disabled={submitDisable}>
                      Submit
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
                </Col>
            </Row>
            </Card>
        </OffcanvasBody>
        </Offcanvas>
    </>
  )
}

export default AddUserCanvas