import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Col } from 'reactstrap'

function PartnerPricingPlans() {
  return (
    <React.Fragment>
        <Col xl={3} className=''>
        <Card>
        <CardBody className="bg-primary mb-xl-0">
              <div className="p-2">
                {/* <h5
                  className="font-size-16 text-white"
                >
                  {month.title}
                </h5> */}
                <h1 className="mt-3 text-white">
                ₹ 999{" "}
                  <span
                    className="text-white font-size-16 fw-medium"
                  >
                    / Month
                  </span>
                </h1>
                <p
                  className="text-white-50 mt-3 font-size-15"
                >
                  To add extra user so that you can access the account on two devices at a time.
                </p>
                <div
                  className="mt-4 pt-2 text-white"
                >
                  <p className="mb-3 font-size-15">
                    <i
                      className="mdi mdi-check-circle text-light font-size-18 me-2"
                    ></i>
                    Verifide work and reviews
                  </p>
                  {/* <p className="mb-3 font-size-15">
                    <i
                      className={
                        month.isFeatured
                          ? "mdi mdi-check-circle text-light font-size-18 me-2"
                          : "mdi mdi-check-circle text-secondary font-size-18 me-2"
                      }
                    ></i>
                    Dedicated Ac managers
                  </p>
                  <p className="mb-3 font-size-15">
                    <i
                      className={
                        month.isFeatured
                          ? "mdi mdi-check-circle text-light font-size-18 me-2"
                          : "mdi mdi-check-circle text-secondary font-size-18 me-2"
                      }
                    ></i>
                    Unlimited proposals
                  </p>
                  <p className="mb-3 font-size-15">
                    <i
                      className={
                        month.isFeatured
                          ? "mdi mdi-check-circle text-light font-size-18 me-2"
                          : "mdi mdi-check-circle text-secondary font-size-18 me-2"
                      }
                    ></i>
                    Project tracking
                  </p>
                  <p className="mb-3 font-size-15">
                    <i
                      className={
                        month.isFeatured
                          ? "mdi mdi-check-circle text-light font-size-18 me-2"
                          : "mdi mdi-check-circle text-secondary font-size-18 me-2"
                      }
                    ></i>
                    Dedicated Ac managers
                  </p>
                  <p className="mb-3 font-size-15">
                    <i
                      className={
                        month.isFeatured
                          ? "mdi mdi-check-circle text-light font-size-18 me-2"
                          : "mdi mdi-check-circle text-secondary font-size-18 me-2"
                      }
                    ></i>
                    Unlimited proposals
                  </p> */}
                </div>

                <div className="mt-4 pt-2 col-3">
                  <Link
                    to="#"
                    className="btn btn-light w-100"
                  >
                    Choose Plan
                  </Link>
                </div>
              </div>
            </CardBody>
        </Card>
        </Col>
    </React.Fragment>
  )
}

export default PartnerPricingPlans