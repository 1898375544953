import { log_debug } from "../apiHelpers/logger";

export const diff_days = (from_date) => {
  // var diff = Math.abs(new Date() - from_date);
  var diff = Math.floor(from_date - new Date());
  return Math.floor(diff / 1000 / 60 / 60 / 24);
};

export const  calculateDaysToExpiry = (expiryDateString) => {
  // Parse the expiry date from the input string
  const expiryDate = new Date(expiryDateString);
  const currentDate = new Date();
  
  // Calculate the difference in time (in milliseconds)
  const timeDifference = expiryDate - currentDate;
  
  // Convert the difference to days
  const daysToExpiry = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  log_debug("expirydate", expiryDateString)
  
  // Return the number of days (or 0 if already expired)
  return daysToExpiry >= 0 ? daysToExpiry : 0;
}

export const time_since = (date) => {
  var seconds = Math.floor((new Date() - new Date(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
};

export const formatDate = (datestring) => {
  var dt = new Date(datestring);
  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return dt.getDate() + "/" + month[dt.getMonth()] + "/" + dt.getFullYear();
};

export const dateyyyymmdd_tohtml = function (date) {
  if (!date) {
    // Return a default value or handle the case where date is undefined
    return ""; // You can return an empty string or some default value
  }
  return `${date.split("T")[0]}`;
};

export const validateStartEndDate = (startDate, endDate) => {
  if (startDate > endDate) {
    return false;
  }
  return true;
};

const formatDate1 = (dateString) => {
  if (!dateString) return ""; // Return an empty string for undefined/null dates

  const options = { month: "short", year: "numeric" };
  const date = new Date(dateString);

  return date.toLocaleDateString("en-US", options);
};

export const getDatesOfWeek = (weekNumber, year) => {
  // Initialize an array to store the dates of the week
  const datesOfWeek = [];

  // Set the date to the first day of the year
  const firstDayOfYear = new Date(year, 0, 1);

  // Calculate the first day of the specified week
  const firstDayOfWeek = new Date(firstDayOfYear);
  firstDayOfWeek.setDate(
    firstDayOfYear.getDate() +
      (weekNumber - 1) * 7 -
      firstDayOfYear.getDay() +
      1,
  );

  // Push each day of the week (Monday to Sunday) to the array
  for (let i = 0; i < 7; i++) {
    const date = new Date(firstDayOfWeek);
    date.setDate(firstDayOfWeek.getDate() + i);
    datesOfWeek.push(date);
  }

  return datesOfWeek;
};

// export const getDatesOfWeek = (weekNumber, monthNumber, year) => {
//   log_debug("week number",weekNumber)
//   // Initialize an array to store the dates of the week
//   const datesOfWeek = [];

//   // Set the date to the first day of the specified month and year
//   const firstDayOfMonth = new Date(year, monthNumber - 1, 1);

//   // Get the day of the week for the first day of the month (0 for Sunday, 1 for Monday, etc.)
//   const firstDayOfWeek = firstDayOfMonth.getDay();

//   // Calculate the offset needed to get to the first day of the specified week (considering Monday as the first day of the week)
//   let daysToFirstDayOfWeek = 1 - firstDayOfWeek;
//   if (daysToFirstDayOfWeek > 0) daysToFirstDayOfWeek -= 7;
//   // Adjust the offset based on the week number
//   daysToFirstDayOfWeek += (weekNumber - 1) * 7;

//   // Set the date to the first day of the specified week
//   let firstDayOfWeekDate = new Date(year, monthNumber - 1, 1 + daysToFirstDayOfWeek);

//   // Loop through each day of the week and push the date to the array
//   for (let i = 0; i < 7; i++) {
//     const date = new Date(firstDayOfWeekDate);
//     date.setDate(firstDayOfWeekDate.getDate() + i);
//     datesOfWeek.push(date);
//   }

//   return datesOfWeek;
// }

// Function to get the week number for a given date
//  export const getWeekNumber = (date) => {
//   log_debug("date",Math.ceil(date.getDate()/7))
//   const weeknumber = date.getDate()/7;
//   log_debug("function week number",weeknumber)
//   return Math.ceil(weeknumber)
// };

export const getWeekNumber = (date) => {
  const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
  const millisecondsInWeek = 604800000; // milliseconds in a week
  const pastDaysOfYear = (date - firstDayOfYear) / millisecondsInWeek;
  return Math.floor(pastDaysOfYear) + 1;
};

// Function to get the Monday of the current week
export const getMondayOfCurrentWeek = () => {
  const today = new Date();
  const currentDayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, etc.
  const mondayOfCurrentWeek = new Date(today); // clone today
  mondayOfCurrentWeek.setDate(today.getDate() - currentDayOfWeek + 1); // set to Monday
  log_debug("monday of current week", mondayOfCurrentWeek);
  return mondayOfCurrentWeek;
};

// Function to get the first and last day of the week
export const getFirstAndLastDayOfWeek = (datesOfWeek) => {
  log_debug("recieved date", datesOfWeek);
  const firstDay = new Date(datesOfWeek[0]);
  const lastDay = new Date(datesOfWeek[datesOfWeek.length - 1]);
  return { firstDay, lastDay };
};

export const formatDatedd_mmm_yyyy = (date) => {
  const datee = new Date(date);
  const day = datee.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  return `${day < 10 ? "0" + day : day} ${month}`;
};
