import React, { useEffect, useMemo, useState } from "react";
import { Iguid, Pguid } from "../../apiConfig/axiosDev";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useRowSelect,
} from "react-table";
import Select from "react-select";
import { FaBriefcase } from "react-icons/fa6";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { IoMdArrowDown, IoMdArrowUp, BiSearch } from "react-icons/io";
import {
  FiSearch,
  FiMoreHorizontal,
  FiUserPlus,
  FiFilePlus,
  FiClipboard,
  FiInfo,
} from "react-icons/fi";
import { PiMicrosoftExcelLogoLight } from "react-icons/pi";
import { VscFilePdf } from "react-icons/vsc";
import { FaCoffee } from "react-icons/fa";
import { FaFilter } from "react-icons/fa";
// import { FiMoreHorizontal } from "react-icons/fi";
import {
  Row,
  Col,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Tooltip,
  Alert,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import "./CustomTable.css"; // Import your custom CSS
import { Link, useNavigate } from "react-router-dom";
import {
  machingJobApplyJob,
  mycandidates,
  matchingCandidates,
  shortlistedCandidates,
  MachingIndividualCandi,
  shortlistedIndividualCandidate,
} from "../../Partner/PartnerSlice";
import { toast, ToastContainer } from "react-toastify";
import { log_debug } from "../../apiHelpers/logger";
import ErrorBoundary from "../CustomError/ErrorBoundary";
import Customloading from "../Customloading/Customloading";
import NodataFoundTable from "../utilities/NodataFoundTable";
import {
  getJobsCount,
  getMatchingJobs,
  postIndividualMultipleJobApply,
} from "../../individual/individualSlice";
import {
  getIndividualGuid,
  getPartnerGuid,
} from "../../apiConfig/globalvariables";
import AddCandidateButton from "../utilities/AddCandidateButton";
import Preloader from "../../components/Common/Preloader";
import AssessmentInvitationCanvas from "../offcanvas/AssessmentInvitationCanvas";
import {
  getCandidateInvitedJobs,
  getCandidateMatchingJobs,
  getClientDashboardCount,
  getInvitedCandidates,
  getJobMatchingCandidates,
  postClientInviteMultipleCandidatesJob,
  postClientInviteMultipleJob,
} from "../../Client/ClientSlice";
import PostJobButton from "../utilities/PostJobButton";
import moment from "moment";
// import "react-toastify/dist/ReactToastify.css";

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;
  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`filter records...`}
    />
  );
}

const CheckBoxs = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});
CheckBoxs.displayName = "CheckBoxs"; // Add the display name here

const IndeterminateCheckbox2 = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);
IndeterminateCheckbox2.displayName = "IndeterminateCheckbox2"; // Add the display name here

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          className="form-check-input"
          type="checkbox"
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);
IndeterminateCheckbox.displayName = "IndeterminateCheckbox"; // Add the display name here

const CustomTable = ({
  columns,
  ShowPagination,
  ShowGlobalFilter,
  onChange,
  globalsearch,
  multipleInviteJobs,
  ClientJobGuid,
  individualguid,
  ClientIndividualGuid,
  individualMultipleApply,
  hideCheckbox,
  JobDescriptionGuid,
  JobGuid,
  totalCount,
  data,
  isLoading,
  applyCandidate,
  ApplymultiJobs,
  tableDatas,
  redirectTo,
  props,
  headerData,
  jobStatus,
  InvoiceStatus,
  showaddcandidate,
  showopostjob,
  // hideCheckbox,
  postjob,
  newAssessment,
  multipleInvite,
  shortlist,
  addcontract,
  direction,
  optionsnew,
  options,
  onPageChange,
  currentPage,
  placeholderTechnololy,
  placeholderlocations,
  placeholder,
  ...args
}) => {
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
    onUpdatePage,
    pcurrentpage,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,

    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    selectedFlatRows,
    allColumns,
    getToggleHideAllColumnsProps,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    // (hooks) => {
    //   hooks.visibleColumns.push((columns) => [
    //     {
    //       id: "selection",
    //       Header: ({ getToggleAllRowsSelectedProps }) => (
    //         <div>
    //           <IndeterminateCheckbox2 {...getToggleAllRowsSelectedProps()} />
    //         </div>
    //       ),
    //       Cell: ({ row }) => (
    //         <div>
    //           <IndeterminateCheckbox2 {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       ),
    //     },
    //     ...columns,
    //   ]);
    // }
    (hooks) => {
      if (!hideCheckbox) {
        hooks.visibleColumns.push((columns) => [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox2 {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox2 {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      } else {
        hooks.visibleColumns.push((columns) => [...columns]);
      }
    }
  );

  // const [dropdownOpen, setDropdownOpen] = useState(false);
  const { pageIndex, pageSize, globalFilter } = state;
  const [copyData, setCopyData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenDot, setDropdownOpendot] = useState(false);
  const toggledot = () => setDropdownOpendot((prevState) => !prevState);
  const [MyCandidateList, setMyCandidateList] = useState([]);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  // log_debug("canPreviousPage", nextPage);
  // State for Select dropdown value
  // const [selectedOption, setSelectedOption] = useState(null);
  const [selectedGuids, setSelectedGuids] = useState([]);
  const [Guids, setGuids] = useState([]);
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const defaultStartDate = new Date();

  const defaultEndDate = new Date();
  const [formattedStartDate, setformattedStartDate] = useState(
    moment(defaultStartDate.setDate(defaultStartDate.getDate() - 90)).format(
      "YYYY-MM-DD"
    )
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD")
  );
  // -------------Redux-toolkit-----------------
  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);
  // -------------Redux-toolkit-----------------
  // log_debug("totalCount", totalCount);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  useEffect(() => {
    setTableData(data);
  }, []);

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "DataTables | Company Bench";

    // Extract headers from headerData
    const headers = Object.values(headerData);
    const headerdata = Object.keys(headerData);
    const data = tableDatas.map((elt) => headerdata.map((key) => elt[key]));

    const content = {
      startY: 50,
      head: [headers], // Use the provided headers
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("DataTables_Companybench.pdf");
  };

  const tableInstance = useMemo(() => {
    return {
      ...getTableProps(),
      ...getTableBodyProps(),
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
    };
  }, [
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
  ]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore.data.partnerdata?.success === true) {
        setMyCandidateList(partnerStore.data.partnerdata?.data?.individuals);
      }
    }
  }, [partnerStore]);
  // const [currentPage, setCurrentPage] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [addcandidatestatus, setAddCandidateStatus] = useState(false);
  const [addContract, setaddContract] = useState(false);
  const [InvoiceStatusinfo, setInvoiceStatusinfo] = useState(false);

  const [ApplymultiCandidate, setApplymultiCandidate] = useState(false);
  const [ApplyJobs, setApplyJobs] = useState(false);
  const [Viewmore, setViewmore] = useState(false);
  const [addMultiple, setAddMultiple] = useState(false);
  const [newAssessmentstatus, setnewAssessmentstatus] = useState(false);
  const [postjobstatus, setpostjob] = useState(false);

  const itemsPerPage = 10; // Adjust as needed
  const startIndex = currentPage;
  const endIndex = startIndex + itemsPerPage;
  // log_debug("start index", startIndex)
  // log_debug("end index",endIndex);
  // log_debug("data length",data.length)
  // log_debug("current page",currentPage)

  useEffect(() => {
    if (jobStatus) {
      setAddCandidateStatus(true);
    }
    if (newAssessment) {
      setnewAssessmentstatus(true);
    }
    if (postjob) {
      setpostjob(true);
    }
    if (applyCandidate) {
      setApplymultiCandidate(true);
    }
    if (ApplymultiJobs) {
      setApplyJobs(true);
    }
    if (shortlist) {
      setViewmore(true);
    }
    if (addcontract) {
      setaddContract(true);
    }
    if (InvoiceStatus) {
      setInvoiceStatusinfo(true);
    }
  }, []);

  const [DisableMultiplyApplyCandidate, setDisableMultiplyApplyCandidate] =
    useState(false);
  const handleButtonClick = () => {
    // Dispatch the action to apply for multiple jobs
    setDisableMultiplyApplyCandidate(true);
    setloading(true);
    dispatch(
      machingJobApplyJob({
        individualjobmapping: {
          individual_guid: selectedGuids && selectedGuids,
          partner_guid: getPartnerGuid() || Pguid,
          jobdescription_guid: [JobDescriptionGuid && JobDescriptionGuid],
        },
      })
    ).then((response) => {
      if (response.payload.success === true) {
        dispatch(
          matchingCandidates({
            JguidUser: JobDescriptionGuid,
          })
        );
        setTimeout(() => {
          dispatch(
            shortlistedCandidates({
              JguidUser: JobDescriptionGuid,
            })
          );
        }, 2000);

        toast.success("Applied Multiple Candidate successfully!", {
          position: "top-right",
          autoClose: 5000, // Close the toast after 5 seconds
          // hideProgressBar: true,
        });
        setDisableMultiplyApplyCandidate(false);
        setloading(false);
      }
    });
    // Introduce a delay of 2000 milliseconds (2 seconds)
  };

  const [disableMultipleApply, setdisableMultipleApply] = useState(false);
  const handleJobClick = () => {
    // Dispatch the action to apply for multiple jobs
    setdisableMultipleApply(true);
    setloading(true);
    dispatch(
      machingJobApplyJob({
        individualjobmapping: {
          individual_guid: [JobGuid && JobGuid],
          partner_guid: getPartnerGuid() || Pguid,
          jobdescription_guid: Guids && Guids,
        },
      })
    ).then((response) => {
      if (response.payload.success === true) {
        dispatch(
          MachingIndividualCandi({
            Indguid: JobGuid,
            // currentPage: currentPagematching ? currentPagematching : currentPage,
          })
        );
        setTimeout(() => {
          dispatch(
            shortlistedIndividualCandidate({
              Indguid: JobGuid,
              // currentPage: currentPage ? currentPage : currentPage,
            })
          );

          // dispatch(MachingIndividualCandi(JobGuid));
          // dispatch(shortlistedIndividualCandidate(JobGuid));
        }, 2000);
        toast.success("Applied Multiple Job successfully!", {
          position: "top-right",
          autoClose: 5000, // Close the toast after 5 seconds
          // hideProgressBar: true,
        });
        setdisableMultipleApply(false);
        setloading(false);
      }
    });
    // Introduce a delay of 2000 milliseconds (2 seconds)
  };

  useEffect(() => {
    // Extract GUIDs from selectedFlatRows and set in state
    const guids = selectedFlatRows.map((row) => row.original.guid);
    const guidsNew = selectedFlatRows.map((row) => row.original.guid);
    setSelectedGuids(guids);
    setGuids(guidsNew);
  }, [selectedFlatRows]);

  useEffect(() => {
    // log_debug("selected guid", selectedGuids);
  }, [selectedGuids]);

  const handleClick = () => {
    if (redirectTo) {
      navigate(redirectTo);
    }
  };

  const handlePrev = function () {
    onPageChange(currentPage - 10);
  };

  const handleNext = function () {
    onPageChange(currentPage + 10);
  };

  const handlePageClick = (pageNumber) => {
    const newPage = pageNumber * 10;
    onPageChange(newPage);
    // Update other related states like startIndex and endIndex based on the new page number.
  };

  //Individual Multiple Jobs Apply
  const individualStore = useSelector((state) => state.individualSlice);

  const handleIndividualMultiplyJobApply = () => {
    setloading(true);
    dispatch(
      postIndividualMultipleJobApply({
        individualjobmapping: {
          individual_guid: [`${getIndividualGuid() || Iguid}`],
          jobdescription_guid: selectedGuids,
        },
      })
    ).then((response) => {
      if (response.payload.success === true) {
        setloading(false);
        const data = response.payload.data;
        if (data.length != 0) {
          toast.success("Successfully Applied Selected Jobs", {
            position: "top-right",
            autoClose: 4000, // Close the toast after 5 seconds
            // hideProgressBar: true,
          });
          dispatch(
            getMatchingJobs({
              //limit: 100,
              offset: 0,
              skills: "",
              locations: "",
              filtervalue: "",
            })
          );
          dispatch(
            getJobsCount({
              fromDate: formattedStartDate,
              toDate: formattedEndDate,
            })
          );
        }
      }
    });
  };

  const handleClientMultipleCandidatesApply = () => {
    setloading(true);
    dispatch(
      postClientInviteMultipleCandidatesJob({
        formdata: {
          individual_guid: selectedGuids,
          job_guids: ClientJobGuid,
        },
      })
    ).then((response) => {
      if (response.payload.success === true) {
        setloading(false);
        const data = response.payload.data;
        if (data.length != 0) {
          toast.success("Successfully Applied Selected Candidates", {
            position: "top-right",
            autoClose: 4000, // Close the toast after 5 seconds
            // hideProgressBar: true,
          });
          dispatch(
            getJobMatchingCandidates({ guid: ClientJobGuid, offset: 0 })
          );
          setTimeout(() => {
            dispatch(getInvitedCandidates({ guid: ClientJobGuid, offset: 0 }));
          }, 2000);
          dispatch(
            getClientDashboardCount({
              fromDate: formattedStartDate,
              toDate: formattedEndDate,
            })
          );
        }
      }
    });
  };

  const handleClientMultipleJobsApply = () => {
    setloading(true);
    dispatch(
      postClientInviteMultipleJob({
        formdata: {
          individual_guid: [individualguid],
          job_guids: selectedGuids,
        },
      })
    ).then((response) => {
      if (response.payload?.success === true) {
        dispatch(
          getCandidateMatchingJobs({
            guid: individualguid,
            currentPage: 0,
          })
        );

        setTimeout(() => {
          dispatch(
            getCandidateInvitedJobs({
              guid: individualguid,
              currentPage: 0,
            })
          );
        }, 2000);
        dispatch(
          getClientDashboardCount({
            fromDate: formattedStartDate,
            toDate: formattedEndDate,
          })
        );
        setloading(false);
        toast.success("Successfully Invited the Candidate", {
          position: "top-right",
          autoClose: 4000, // Close the toast after 5 seconds
          // hideProgressBar: true,
        });
      }
    });
  };

  const handleAssessmentInvitation = () => {
    setOffcanvasOpen(true);
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  const [inputValue, setInputValue] = useState("");

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    // Call the onChange function passed from parent (Component A)
    onChange(newValue);
  };

  // Function to toggle tooltip for a specific ID
  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <>
      <ErrorBoundary>
        <div className="">
          {loading && <Preloader />}
          <div className="row">
            {!ShowGlobalFilter && (
              <Col className=" mt-2 col-12 col-sm-12 col-md-12 col-lg-6">
                <Row>
                  <Col className="col-12">
                    <div className="input-group ">
                      <div className="search-box">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Global Search"
                          value={
                            globalsearch ? inputValue || "" : globalFilter || ""
                          }
                          onChange={
                            globalsearch
                              ? handleChange
                              : (e) => setGlobalFilter(e.target.value)
                          }
                        />
                        <i
                          className="fa fa-search fa-lg"
                          style={{ color: "#5156BE" }}
                        ></i>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}

            <Col className="col-12 col-sm-12 col-md-12 col-lg-6">
              <Row>
                <Col
                  xs={12}
                  lg={12}
                  sm={12}
                  md={12}
                  className="text-end d-flex justify-content-end"
                >
                  <div>
                    {addcandidatestatus && (
                      <Link to={"/addcandidate"} className="text-white">
                        <Button
                          color="primary"
                          variant="primary"
                          className=" my-2"
                        >
                          <strong>
                            <FiUserPlus />
                          </strong>{" "}
                          Add candidates
                        </Button>
                      </Link>
                    )}

                    {postjobstatus && (
                      <Link to={"/client/post-job"} className="text-white">
                        <Button
                          color="primary"
                          variant="primary"
                          className=" my-2"
                        >
                          {/* <strong>
                            <FiClipboard />

                          </strong>{" "} */}
                          <strong>
                            <i className=" fas fa-paper-plane fs-6"></i>&nbsp;
                          </strong>
                          Post Job
                        </Button>
                      </Link>
                    )}

                    {newAssessmentstatus && (
                      <Link to={"#"} className="text-white">
                        <Button
                          color="primary"
                          variant="primary"
                          className=" my-2"
                          onClick={handleAssessmentInvitation}
                        >
                          <i className="fas fa-brain mx-1"></i>
                          New Assessment
                        </Button>
                      </Link>
                    )}
                    {ApplymultiCandidate && (
                      <>
                        <Link to={"#"} className="text-white">
                          <Button
                            color="primary"
                            variant="primary"
                            className="my-2"
                            id="applyMultipleJobButton"
                            onClick={handleButtonClick}
                            disabled={
                              selectedGuids.length < 2 ||
                              DisableMultiplyApplyCandidate
                            } // Disable the button if no GUIDs are selected
                          >
                            <strong>
                              <i className=" fas fa-paper-plane fs-6"></i>&nbsp;
                            </strong>{" "}
                            Apply Multiple Candidates
                          </Button>
                        </Link>
                        <ToastContainer />
                      </>
                    )}
                    {ApplyJobs && (
                      <>
                        <Link to={"#"} className="text-white">
                          <Button
                            color="primary"
                            variant="primary"
                            className="my-2"
                            id="applyMultipleJobButton"
                            onClick={handleJobClick}
                            disabled={
                              selectedGuids.length < 2 || disableMultipleApply
                            } // Disable the button if no GUIDs are selected
                          >
                            <strong>
                              <i className=" fas fa-paper-plane fs-6"></i>&nbsp;
                            </strong>{" "}
                            Apply Multiple Jobs
                          </Button>
                        </Link>
                        <ToastContainer />
                      </>
                    )}

                    {individualMultipleApply && (
                      <>
                        <Link
                          onClick={handleIndividualMultiplyJobApply}
                          className="text-white"
                        >
                          <Button
                            color="primary"
                            variant="primary"
                            className="my-2"
                            id="applyMultipleJobButton"
                            // onClick={handleJobClick}
                            disabled={selectedGuids.length < 2} // Disable the button if no GUIDs are selected
                          >
                            <strong>
                              <FaBriefcase />
                            </strong>{" "}
                            Apply Multiple Jobs
                          </Button>
                        </Link>
                        <ToastContainer />
                      </>
                    )}
                    {multipleInvite && (
                      <>
                        <Link to={"#"} className="text-white">
                          <Button
                            color="primary"
                            variant="primary"
                            className="my-2"
                            id="applyMultipleJobButton"
                            onClick={handleClientMultipleCandidatesApply}
                            disabled={selectedGuids.length < 2} // Disable the button if no GUIDs are selected
                          >
                            <strong>
                              <i className="mdi mdi-email-send mx-1 btn-lg"></i>
                            </strong>{" "}
                            Invite Multiple Candidates
                          </Button>
                        </Link>
                        {/* <ToastContainer /> */}
                      </>
                    )}

                    {multipleInviteJobs && (
                      <>
                        <Link to={"#"} className="text-white">
                          <Button
                            color="primary"
                            variant="primary"
                            className="my-2"
                            id="applyMultipleJobButton"
                            onClick={handleClientMultipleJobsApply}
                            disabled={selectedGuids.length < 2} // Disable the button if no GUIDs are selected
                          >
                            <strong>
                              <FaBriefcase />
                            </strong>{" "}
                            Invite Multiple Jobs
                          </Button>
                        </Link>
                        {/* <ToastContainer /> */}
                      </>
                    )}
                    {/* {addContract && (
                      <Link to={"/add-contract"} className="text-white">
                        <Button
                          color="primary"
                          variant="primary"
                          className=" my-2"
                        >
                          <strong>
                            <FiUserPlus />
                          </strong>{" "}
                          Create Contract
                        </Button>
                      </Link>
                    )} */}
                    {InvoiceStatus && (
                      <Link to={"/add-invoice"} className="text-white">
                        <Button
                          color="primary"
                          variant="primary"
                          className=" my-2"
                        >
                          <strong>
                            <FiUserPlus />
                          </strong>{" "}
                          Create Invoice
                        </Button>
                      </Link>
                    )}

                    {Viewmore && (
                      <>
                        <i
                          id="tooltipViewMore"
                          onClick={handleClick}
                          className="mdi mdi-eye btn btn-primary mt-2"
                          title="View More"
                          onMouseEnter={() => setTooltipOpen(true)}
                          onMouseLeave={() => setTooltipOpen(false)}
                        />
                      </>
                    )}
                  </div>
                  {!ShowGlobalFilter && (
                    <div>
                      <Dropdown
                        outline
                        color="info"
                        className="mx-2  my-2"
                        isOpen={dropdownOpen}
                        toggle={toggle}
                        {...props}
                        direction="down"
                      >
                        <DropdownToggle
                          style={{
                            backgroundColor: "#5156BE",
                            color: "#ffffff",
                          }}
                        >
                          <FaFilter />{" "}
                        </DropdownToggle>
                        <DropdownMenu {...args}>
                          <div
                            className="exportdata filterhover"
                            style={{
                              borderBottom: "1px solid #ccc",
                              width: "100%", // Add your desired width
                              // backgroundColor: "#F2F2F2", // Add your desired background color
                              alignItems: "center",
                              padding: "8px", // Add padding if needed
                              marginTop: "0px",
                              cursor: "pointer",
                            }}
                          >
                            <IndeterminateCheckbox
                              style={{ marginRight: "8px" }}
                              {...getToggleHideAllColumnsProps()}
                            />{" "}
                            <strong> &nbsp;&nbsp;Show All Column</strong>
                          </div>
                          {allColumns.map((column) => (
                            <div
                              key={column.id}
                              className="filterhover"
                              style={{
                                borderBottom: "1px solid #ccc",
                                height: "40px", // Add your desired height
                                width: "100%", // Add your desired width
                                // backgroundColor: "#F2F2F2", // Add your desired background color
                                display: "flex",
                                alignItems: "center",
                                padding: "0px", // Add padding if needed
                                cursor: "pointer",
                              }}
                            >
                              <label
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  {...column.getToggleHiddenProps()}
                                  style={{
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                />{" "}
                                <span style={{ cursor: "pointer" }}>
                                  {column.id === "selection"
                                    ? "Checkbox"
                                    : `${column.Header}`}
                                </span>
                              </label>
                            </div>
                          ))}
                          <br />
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  )}
                  {/* <div>
                  <Dropdown
                    outline
                    color="info"
                    className="  my-2"
                    isOpen={dropdownOpenDot}
                    toggle={toggledot}
                    {...props}
                    direction="down"
                  >
                    <DropdownToggle
                      style={{ backgroundColor: "#5156BE", color: "#ffffff" }}
                    >
                      <FiMoreHorizontal />
                    </DropdownToggle>
                    <DropdownMenu className="border mt-1">
                      <DropdownItem className=" filterhover border-bottom">
                        <CSVLink data={data} filename="DataTables companybench">
                          <strong className="text-muted">
                            <PiMicrosoftExcelLogoLight
                              style={{ color: "#5156BE" }}
                            />{" "}
                            Export Excel
                          </strong>
                        </CSVLink>
                      </DropdownItem>
                      <DropdownItem>
                        <Link onClick={exportPDF}>
                          <strong className="text-muted">
                            <VscFilePdf style={{ color: "#5156BE" }} /> Export
                            Pdf
                          </strong>
                        </Link>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div> */}
                </Col>
              </Row>
            </Col>
          </div>
        </div>
        <div className="">
          <Row>
            <div className="table-responsive ">
              <Col xl={12} lg={12} md={12} sm={12}>
                <table
                  {...tableInstance}
                  className="table table-bordered  table-striped"
                >
                  <thead className="thead-custom-color">
                    {headerGroups.map((headerGroup) => (
                      <tr
                        key={headerGroup.id}
                        {...headerGroup.getHeaderGroupProps()}
                        className="table  "
                      >
                        {headerGroup.headers.map((column) => (
                          <th
                            key={column.id}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            className="bg-primary text-white"
                          >
                            {column.render("Header")}
                            {column.id !== "selection" && ( // Check the column id to exclude sorting for multiselect checkbox column
                              <>
                                <span className="float-end">
                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <i className="fas fa-sort-up bg-primary text-light p-0"></i>
                                    ) : (
                                      <i className="fas fa-sort-down bg-primary p-0 text-light"></i>
                                    )
                                  ) : (
                                    <i className="fas fa-sort bg-primary p-0 text-light"></i>
                                  )}
                                </span>
                              </>
                            )}

                            {/* Render the columns filter UI */}
                            {/* <div>
                      {" "}
                      {column.id !== "action" && column.canFilter
                        ? column.render("Filter")
                        : null}
                    </div> */}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className="bg-white align-items-center">
                    {isLoading ? (
                      <tr>
                        <td
                          colSpan={columns.length + 1}
                          className="text-center"
                        >
                          <div className="overflow-hidden">
                            <Customloading />
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {!page?.length && (
                          <tr>
                            <td
                              colSpan={columns.length + 1}
                              className="text-center"
                            >
                              <div className="overflow-hidden">
                                {showaddcandidate ? (
                                  <AddCandidateButton />
                                ) : showopostjob ? (
                                  <PostJobButton />
                                ) : (
                                  <NodataFoundTable />
                                )}
                              </div>
                            </td>
                          </tr>
                        )}

                        {page?.map((row, rowIndex) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} key={rowIndex}>
                              {row.cells.map((cell, cellIndex) => {
                                const columnAccessor = cell.column.id; // Get the column accessor
                                const tooltipId = `tooltip-${rowIndex}-${cellIndex}`; // Unique ID for each tooltip

                                return (
                                  <td
                                    {...cell.getCellProps()}
                                    key={cellIndex}
                                    style={{
                                      maxWidth: "100px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      textAlign: columnAccessor === "actions" && "center"
                                    }}
                                  >
                                    {columnAccessor === "submissionNotes" ||
                                    columnAccessor === "feedback" ? (
                                      <>
                                        
                                        <span>
                                          {cell.render("Cell")}{" "}
                                        </span>
                                        <FiInfo id={tooltipId} size={20} style={{float:"right",cursor:"pointer"}}/>
                                        

                                        <Tooltip
                                          placement="bottom"
                                          target={tooltipId}
                                          isOpen={
                                            tooltipOpen[tooltipId] || false
                                          }
                                          toggle={() =>
                                            toggleTooltip(tooltipId)
                                          }
                                        >
                                          {cell.value}
                                        </Tooltip>
                                      </>
                                    ) : cellIndex === 7 ? (
                                      <span className="badge">
                                        {cell.render("Cell")}
                                      </span>
                                    ) : (
                                      cell.render("Cell")
                                    )}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}

                        {/* {page?.map((row, rowIndex) => {
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps()} key={rowIndex}>
                              {row.cells.map((cell, cellIndex) => (
                                <td
                                  {...cell.getCellProps()}
                                  key={cellIndex}
                                  style={{
                                    //width: "auto",
                                    maxWidth: "100px", // Set your desired fixed width
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {cellIndex === 7 ? (
                                    <span className="badge">
                                      {cell.render("Cell")}
                                    </span>
                                  ) : (
                                    cell.render("Cell")
                                  )}
                                </td>
                              ))}
                            </tr>
                          );
                        })} */}
                      </>
                    )}
                  </tbody>

                  {/* <pre>
                    <code>
                      {JSON.stringify(
                        {
                          selectedFlatRows: selectedFlatRows.map(
                            (row) => row.original,
                            // (row) => row.original.guid
                          ),
                        },
                        null,
                        2,
                      )}
                    </code>
                  </pre> */}
                </table>
              </Col>
            </div>

            {/* {!ShowGlobalFilter && (
  <div className="d-flex justify-content-flexend">
    {/* Pagination 
    <div className="w-100 text-end">
      {/* Previous Button 
      <button
        className="btn btn-primary m-1"
        onClick={handlePrev}
        disabled={currentPage === 0}
      >
        <i className="fas fa-chevron-left" />
      </button>

      {/* Dynamic Page Numbers
      {Array.from(
        { length: Math.min(4, Math.ceil(totalCount / itemsPerPage)) },
        (_, index) => {
          const startPage = Math.max(
            0,
            Math.floor(currentPage / 10) - Math.floor(4 / 2)
          );
          const pageIndex = startPage + index;

          // Ensure pageIndex is within bounds
          if (pageIndex < 0 || pageIndex >= Math.ceil(totalCount / itemsPerPage)) {
            return null;
          }

          return (
            <button
              key={pageIndex}
              className={`btn btn-${
                currentPage === pageIndex * 10 ? "primary" : "outline-primary"
              } m-1`}
              onClick={() => handlePageClick(pageIndex)}
            >
              {pageIndex + 1}
            </button>
          );
        }
      )}

      {/* Next Button 
      <button
        className="btn btn-primary m-1"
        onClick={handleNext}
        disabled={(currentPage + itemsPerPage) >= totalCount}
      >
        <i className="fas fa-chevron-right" />
      </button>
    </div>
  </div>
)} */}

            {!ShowGlobalFilter && totalCount > 0 && (
              <div className="d-flex justify-content-flexend">
                {/* Pagination */}
                <div className="w-100 text-end">
                  {/* Previous Button */}
                  <button
                    className="btn btn-primary m-1"
                    onClick={handlePrev}
                    disabled={currentPage === 0}
                  >
                    <i className="fas fa-chevron-left" />
                  </button>

                  {/* First Page Button */}
                  {Math.ceil(totalCount / itemsPerPage) > 1 && (
                    <button
                      className={`btn btn-${
                        currentPage === 0 ? "primary" : "outline-primary"
                      } m-1`}
                      onClick={() => handlePageClick(0)}
                    >
                      1
                    </button>
                  )}

                  {/* Dots after First Page */}
                  {Math.ceil(totalCount / itemsPerPage) > 5 &&
                    currentPage / 10 > 2 && <span className="m-1">...</span>}

                  {/* Dynamic Page Numbers */}
                  {Array.from(
                    {
                      length: Math.min(4, Math.ceil(totalCount / itemsPerPage)),
                    },
                    (_, index) => {
                      const startPage = Math.max(
                        1,
                        Math.floor(currentPage / 10) - Math.floor(4 / 2)
                      );
                      const pageIndex = startPage + index;

                      // Ensure pageIndex is within bounds and exclude the first/last page
                      if (
                        pageIndex < 1 ||
                        pageIndex >= Math.ceil(totalCount / itemsPerPage) - 1
                      ) {
                        return null;
                      }

                      return (
                        <button
                          key={pageIndex}
                          className={`btn btn-${
                            currentPage === pageIndex * 10
                              ? "primary"
                              : "outline-primary"
                          } m-1`}
                          onClick={() => handlePageClick(pageIndex)}
                        >
                          {pageIndex + 1}
                        </button>
                      );
                    }
                  )}

                  {/* Dots before Last Page */}
                  {Math.ceil(totalCount / itemsPerPage) > 5 &&
                    currentPage / 10 + 3 <
                      Math.ceil(totalCount / itemsPerPage) - 1 && (
                      <span className="m-1">...</span>
                    )}

                  {/* Last Page Button */}
                  {Math.ceil(totalCount / itemsPerPage) > 1 && (
                    <button
                      className={`btn btn-${
                        currentPage ===
                        (Math.ceil(totalCount / itemsPerPage) - 1) * 10
                          ? "primary"
                          : "outline-primary"
                      } m-1`}
                      onClick={() =>
                        handlePageClick(
                          Math.ceil(totalCount / itemsPerPage) - 1
                        )
                      }
                    >
                      {Math.ceil(totalCount / itemsPerPage)}
                    </button>
                  )}

                  {/* Next Button */}
                  <button
                    className="btn btn-primary m-1"
                    onClick={handleNext}
                    disabled={currentPage + itemsPerPage >= totalCount}
                  >
                    <i className="fas fa-chevron-right" />
                  </button>
                </div>
              </div>
            )}

            {/* {!ShowGlobalFilter &&<div className="d-flex justify-content-flexend">
              {/* Pagination 
              <div className="w-100 text-end ">
                <button
                  className="btn btn-primary m-1"
                  onClick={handlePrev}
                  disabled={currentPage === 0}
                >
                  <i className=" fas fa-chevron-left" />
                </button>

                {Array.from(
                  { length: Math.ceil(totalCount / itemsPerPage) },
                  (_, index) => (
                    <button
                      key={index}
                      className={`btn btn-${
                        currentPage === index * 10
                          ? "primary"
                          : "outline-primary"
                      }`}
                      onClick={() => handlePageClick(index)}
                    >
                      {index + 1}
                    </button>
                  )
                )}

                {/* <span className="p-2">
                Page <strong>{Math.max(1, currentPage / 10 + 1)}</strong>
                </span> 
                <button
                  className="btn btn-primary m-1"
                  onClick={handleNext}
                  disabled={endIndex >= totalCount}
                  // disabled={endIndex - startIndex >= data.length}
                >
                  <i className="fas fa-chevron-right" />
                </button>
              </div>
            </div>} */}
          </Row>
          {isOffcanvasOpen && (
            <AssessmentInvitationCanvas
              isOpen={isOffcanvasOpen}
              toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
              onClose={closeOffcanvas}
            />
          )}
        </div>
      </ErrorBoundary>
    </>
  );
};

export default CustomTable;
