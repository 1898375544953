import { log_debug } from "../apiHelpers/logger";

// GlobalVariable.js
let Iguid = ""; // Initialize the global variable
let guid = "";
export function setGuid(value) {
  guid = value || localStorage.getItem('guid'); // Update the global variable with the userData
  log_debug("global guid", guid);
}

export function getGuid() {
  return guid; // Return the global variable
}

export function setIndividualGuid(value) {
  Iguid = value || localStorage.getItem('guid'); // Update the global variable with the userData
  log_debug("global guid", Iguid);
}

export function getIndividualGuid() {
  return Iguid; // Return the global variable
}

let Pguid = ""; // Initialize the global variable

export function setPartnerGuid(value) {
  Pguid = value || localStorage.getItem('guid');; // Update the global variable with the userData
  log_debug("global guid", Pguid);
}

export function getPartnerGuid() {
  return Pguid; // Return the global variable
}

let ClientGuid = ""; // Initialize the global variable

export function setClientGuid(value) {
  ClientGuid = value || localStorage.getItem('guid');; // Update the global variable with the userData
  log_debug("global guid", ClientGuid);
}

export function getClientGuid() {
  return ClientGuid; // Return the global variable
}
