import React, { useEffect, useState } from "react";
import {
  CardBody,
  Container,
  Card,
  Row,
  Col,
  CardTitle,
  Badge,
} from "reactstrap";
import Breadcrumbs from "../components/Common/Breadcrumb";
import { candidateData, jobs } from "../data/Data";
import CustomTable from "../customComponents/reactTable/CustomTable";
import DropdownWithTwoField from "../customComponents/DropdownWithTwoField";
import {
  IndividualDetail,
  JobDetail,
  addCandidatesLocation,
  addCandidatesSkills,
  candidateInterview,
  getFilterInterview,
} from "./PartnerSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import InterviewDetailsOffcanvas from "./pagesComponents/InterviewDetailsOffcanvas";
import dayjs from "dayjs";
import { dateyyyymmdd_tohtml } from "../helpers/date";
import "../../src/assets/css/styleCompanyBench.css";
import NodataFoundTable from "../customComponents/utilities/NodataFoundTable";
import CustomAvatar from "../customComponents/CustomAvatar/CustomAvatar";
import CanvasPartnerJobDetails from "../customComponents/offcanvas/CanvasPartnerJobDetails";
import CanvasCandidate from "../customComponents/offcanvas/CanvasCandidate";
import CustomBreadcrumb from "../customComponents/breadcrumb/CustomBreadcrumb";
import { log_debug } from "../apiHelpers/logger";

function PartnerCandidateInterview() {
  //pagination
  const [currentPage, setCurrentPage] = useState(0);

  const [AddCandidateSkills, setAddCandidateSkills] = useState([]);
  const [AddCandidateLocation, setAddCandidateLocation] = useState([]);
  const [candidateInterviewdata, setcandidateInterviewdata] = useState([]);
  const [Allinterviewcount, setAllinterviewcount] = useState([]);

  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [isOffcanvasOpenJobName, setisOffcanvasOpenJobName] = useState(false);
  const [isOffcanvasOpenCandidateName, setisOffcanvasOpenCandidateName] =
    useState(false);

  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [InterviewJob, setInterviewJob] = useState([]);
  const [InterviewCandidateName, setInterviewCandidateName] = useState(null);

  const [individualDetail, setIndividualDetail] = useState({});
  const [jobDetaildata, setJobDetaildata] = useState({});

  const [Interviewloading, setInterviewloading] = useState(true);
  const [filtervalue, setFiltervalue] = useState("");

  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);

  const count = candidateInterviewdata.length;

  const candidateName = (guid) => {
    setSelectedCandidate(guid);
    setOffcanvasOpen(true);
  };

  const JobtitleOnclick = (guid) => {
    // setInterviewJob(guid);
    dispatch(JobDetail(guid?.job_guid)).then((response) => {
      log_debug("jobdetail09348",response.payload)
      setJobDetaildata(response.payload?.data?.jobdescription);
      setisOffcanvasOpenJobName(true);

    });
  };
  const candidateOnclick = (guid) => {
    // setInterviewCandidateName(guid.indv_guid);
    dispatch(IndividualDetail(guid.indv_guid));
    setisOffcanvasOpenCandidateName(true);
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
    setisOffcanvasOpenJobName(false);
    setisOffcanvasOpenCandidateName(false);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        candidateInterview({
          currentPage: currentPage ? currentPage : currentPage,
          filtervalue: filtervalue ? filtervalue : "",
        }),
      );
    }, 700);

    return () => clearTimeout(timer);
  }, [currentPage, filtervalue]);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      // if (partnerStore.data.CandidatesSkills?.success === true) {
      //   setAddCandidateSkills(
      //     partnerStore.data.CandidatesSkills?.data?.enumskills,
      //   );
      // }
      // if (partnerStore.data.CandidatesLocation?.success === true) {
      //   setAddCandidateLocation(
      //     partnerStore.data.CandidatesLocation?.data?.enumlocations,
      //   );
      // }
      if (
        partnerStore.data?.IndividualDetail?.success &&
        partnerStore.data.IndividualDetail.data?.individual
      ) {
        setIndividualDetail(
          partnerStore.data.IndividualDetail.data?.individual,
        );
      }
      if (
        partnerStore.data?.JobDetail?.success &&
        partnerStore.data.JobDetail.data?.jobdescription
      ) {
        setJobDetaildata(partnerStore.data.JobDetail.data?.jobdescription);
      }
      if (partnerStore.data.candidateInterviewRecords?.success === true) {
        setInterviewloading(false);
        setcandidateInterviewdata(
          partnerStore.data.candidateInterviewRecords?.data?.individuals,
        );
        setAllinterviewcount(
          partnerStore.data.candidateInterviewRecords?.data?.totalCount,
        );
      } else if (
        partnerStore.data.candidateInterviewRecords?.success === false
      ) {
        setInterviewloading(false);
        setcandidateInterviewdata([]);
        setAllinterviewcount(0);
      }
    }
  }, [
    partnerStore.data?.IndividualDetail,
    partnerStore.data.candidateInterviewRecords,
    partnerStore.data?.JobDetail,
  ]);

  // const skillOptions = AddCandidateSkills.map((item) => ({
  //   value: item.code,
  //   label: item.title,
  // }));

  // const locationOptions = AddCandidateLocation.map((item) => ({
  //   value: item.code,
  //   label: item.title,
  // }));

  const columns = [
    {
      Header: "Interview ID",
      accessor: "guid",
      width: 100,
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidateName(row.original);
          }}
        >
          {/* {row.values.guid} */}
          {row.original?.guid?.split("-")[0].toUpperCase()}-
          {row.original?.guid?.split("-")[1].toUpperCase()}
        </Link>
      ),
      // Cell: ({ value }) => value.join(', '),
    },
    {
      Header: "Job Title",
      accessor: "job_title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            JobtitleOnclick(row.original);
          }}
        >
          {row.values.job_title}
        </Link>
      ),
    },
    {
      Header: "Candidate",
      accessor: "candidate",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidateOnclick(row.original);
          }}
        >
          <div className="d-flex">
            <CustomAvatar fullName={row.values.candidate} />
            <span>
              {row.values.candidate.length > 10
                ? `${row.values.candidate.slice(0, 12)}...`
                : row.values.candidate}
            </span>
          </div>
        </Link>
      ),
    },

    // {
    //   Header: "Updated Date",
    //   accessor: "updatedAt",
    //   Cell: (props) => {
    //     // const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
    //     return <span>{dateyyyymmdd_tohtml(props?.value)}</span>;
    //   },
    // },
    {
      Header: "Interview Date",
      accessor: "interview_date",
      Cell: (props) => {
        // const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
        return <span>{dateyyyymmdd_tohtml(props?.value)}</span>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (props) => {
        return (
          <Badge className={`badge bg-${props?.value.includes("Passed") ? "success" : props?.value.includes("Rejected") ? "danger" : "primary"} rounded-pill px-3 p-1`}>
            {props?.value}
          </Badge>
        );
      },
    },

    {
      Header: "Remarks",
      accessor: "feedback",
      Cell: ({ row }) => (
        <span>
          {row.original?.feedback ? row.original?.feedback : "-"}
        </span>
      ),
    //   Cell: (props) => {
    //     const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
    //     return <span>{tidyDate}</span>;
    //   },
    },
  ];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleValueChange = (newValue) => {
    setInterviewloading(true);
    setFiltervalue(newValue);
  };

  const breadcrumbValues = ["Candidate Interview"];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div className="row col-lg-12">
            {/* <div className="col-lg-12">
              <CustomBreadcrumb values={breadcrumbValues} />
            </div> */}
            <div className="col-lg-12">
              <>
                <div className="row">
                  <div className="col-lg-12">
                    <div>
                      <Row>
                        <div className="col-12 col-lg-6 col-md-6 d-sm-flex">
                          <div className="col-12 col-lg-6">
                            {/* <DropdownWithTwoField
                                // label="Option 1"
                                options={skillOptions}
                                placeholder="Select Technology"
                              /> */}
                            <div className="col-lg-12">
                              <CustomBreadcrumb values={breadcrumbValues} />
                            </div>
                          </div>{" "}
                          &nbsp;
                          {/* <div className="col-12 col-lg-6">
                              <DropdownWithTwoField
                                options={locationOptions}
                                placeholder="Select Locations"
                              />
                            </div> */}
                        </div>
                        <div className="col-12 col-lg-6 col-md-6">
                          <Col md={12}>
                            <CardTitle className="page-title text-muted">
                              <span>Total count : </span>
                              <strong>
                                {Allinterviewcount ? Allinterviewcount : 0}
                              </strong>
                            </CardTitle>
                          </Col>
                        </div>
                      </Row>
                    </div>
                    <div>
                      <Card>
                        <CardBody>
                          <CustomTable
                            totalCount={Allinterviewcount && Allinterviewcount}
                            columns={columns}
                            data={candidateInterviewdata}
                            isLoading={Interviewloading}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            hideCheckbox={true}
                            globalsearch={true}
                            onChange={handleValueChange}
                          />
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </Container>
      </div>
      {isOffcanvasOpen && (
        <InterviewDetailsOffcanvas
          placement="end"
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onClose={closeOffcanvas}
          data={selectedCandidate}
        />
      )}
      {isOffcanvasOpenJobName && (
        <CanvasPartnerJobDetails
          // shortlistedstatus={true}
          data={jobDetaildata}
          width={50}
          placement="end"
          isOpen={isOffcanvasOpenJobName}
          toggle={() => setisOffcanvasOpenJobName(!isOffcanvasOpenJobName)}
          onclose={closeOffcanvas}
        />
      )}
      {isOffcanvasOpenCandidateName && (
        <CanvasCandidate
          usertype={"partner"}
          placement="end"
          width={60}
          isOpen={isOffcanvasOpenCandidateName}
          toggle={() =>
            setisOffcanvasOpenCandidateName(!isOffcanvasOpenCandidateName)
          }
          onClose={closeOffcanvas}
          data={individualDetail}
        />
      )}
    </React.Fragment>
  );
}

export default PartnerCandidateInterview;
