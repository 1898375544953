import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Badge,
} from "reactstrap";
import classnames from "classnames";
import dayjs from "dayjs";
import ColumnChart from "../pages/Charts/ColumnChart";
import DashboardNavTabs from "../pages/UiElements/DashboardNavTabs";
import DashboardCountCard from "../customComponents/DashboardCountCard";
import ApexChart from "../customComponents/charts/ApexChart";
import PieChart from "../customComponents/charts/PieChart";
import Breadcrumbs from "../components/Common/Breadcrumb";
import { DateRangePicker } from "react-date-range"; // Replace with the actual library you are using
import IconButton from "@mui/material/IconButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment/moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Divider } from "@mui/material";
import {
  Contract,
  DashboardApexchart,
  DashboardCounter,
  DashboardTabsContract,
  DashboardTabsInterview,
  DashboardTabsMycandidate,
  DashboardTabsShortlisted,
  getVendorSummary,
} from "./PartnerSlice";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../customComponents/reactTable/CustomTable";
import CustomAvatar from "../customComponents/CustomAvatar/CustomAvatar";
import { Tags } from "../pages/Contacts/ContactList/contactlistCol";
import { Link } from "react-router-dom";
import InterviewDetailsOffcanvas from "./pagesComponents/InterviewDetailsOffcanvas";
import ContractDetailCanvas from "./pagesComponents/ContractDetailCanvas";
import CanvasPartnerJobDetails from "../customComponents/offcanvas/CanvasPartnerJobDetails";
import CanvasCandidate from "../customComponents/offcanvas/CanvasCandidate";
import NodataFoundTable from "../customComponents/utilities/NodataFoundTable";
import { log_debug } from "../apiHelpers/logger";
import { titles } from "../data/cb_title";
import { dateyyyymmdd_tohtml } from "../helpers/date";
import CustomBreadcrumb from "../customComponents/breadcrumb/CustomBreadcrumb";
import CandidateStatusBadge from "../customComponents/utilities/CandidateStatusBadge";
import JobsBadge from "../customComponents/utilities/JobsBadge";
import OfferAcceptRejectBadge from "../customComponents/utilities/OfferAcceptRejectBadge";
// import Customloading from "../customComponents/Customloading/Customloading";
const userType = "partner";

const PartnerDashboard = () => {
  const { dashboard_chart_title, dashboard_chart_sub_title } =
    titles.en[userType];
  const defaultEndDate = new Date();
  const defaultStartDate = new Date();
  defaultStartDate.setDate(defaultStartDate.getDate() - 90);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectionRange, setSelectionRange] = useState({
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    key: "selection",
  });
  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);
  const loadingdata = useSelector(
    (state) => state?.partnerSlice?.status === "loading",
  );
  // log_debug("loadingdata", loadingdata);

  //dashboard tabs table data
  const [MyCandidateList, setMyCandidateList] = useState([]);
  const [Shortlistjobs, setShortlistjobs] = useState([]);
  const Shortlistjobsdata =
    Shortlistjobs && Shortlistjobs.map((job) => job.job_data);
  const [InterviewjobsdataList, setInterviewjobsdataList] = useState([]);
  const [partnerContracts, setPartnerContracts] = useState([]);

  const [isloadingCandidate, setisloadingCandidate] = useState(false);
  const [isloadingshortlist, setisloadingshortlist] = useState(false);
  const [isloadingInterview, setisloadingInterview] = useState(false);
  const [isloadingContract, setisloadingContract] = useState(false);

  // dashboard tabs table offcanvas data
  const [selectedCandidateinfo, setSelectedCandidateinfo] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [contract, setContract] = useState({});
  const [shortlistCandidate, setshortlistCandidate] = useState({});

  // dashboard chart
  const [ChartDate, setChartDate] = useState([]);
  const [ChartMycandidate, setChartMycandidate] = useState([]);
  const [chartShortlistedJob, setchartShortlistedJob] = useState([]);
  const [ChartscheduledInterview, setChartscheduledInterview] = useState([]);
  const [Chartcontracts, setChartcontracts] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [currentPage2, setCurrentPage2] = useState(0);
  const [currentPage3, setCurrentPage3] = useState(0);
  const [currentPage4, setCurrentPage4] = useState(0);

  const [candidatecount, setcandidatecount] = useState([]);
  const [interviewcount, setinterviewcount] = useState([]);
  const [shortlistcount, setshortlistcount] = useState([]);
  const [contractcount, setcontractcount] = useState([]);
  const [vendorSummary, setVendorSummary] = useState([]);

  //dashboard counter
  const [Dashboardcounterdata, setDashboardcounterdata] = useState([]);
  // log_debug("Dashboardcounterdata", Dashboardcounterdata);
  const [DashboardChartData, setDashboardChartData] = useState([]);
  const [DashboardChartDatahideshow, setDashboardChartDatahideshow] =
    useState(false);

  // log_debug("DashboardChartData", DashboardChartData);
  const [formattedStartDate, setformattedStartDate] = useState(
    // moment(defaultStartDate).format("YYYY-MM-DD"),
    moment(defaultStartDate).format("YYYY-MM-DD"),
  );
  const [formattedEndDate, setformattedEndDate] = useState(
    moment(defaultEndDate).format("YYYY-MM-DD"),
  );
  // log_debug("formattedStartDate", formattedStartDate);
  // log_debug("formattedEndDate", formattedEndDate);
  // dashboard tabs offcanvas open click
  const [isOffcanvasOpenCandidate, setisOffcanvasOpenCandidate] =
    useState(false);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [OffcanvasOpenshortlist, setOffcanvasOpenshortlist] = useState(false);

  const [headerTab, setHeaderTab] = useState("1");
  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };

  // my candidate column
  const CandidateColumn = [
    {
      Header: "Name",
      accessor: "full_name",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            Candidateclick(row.original);
          }}
        >
          <div style={{ display: "flex", alignItems: "top" }}>
            <CustomAvatar fullName={row.values.full_name} />
            <span>
              {row.values.full_name.length > 10
                ? `${row.values.full_name.slice(0, 14)}...`
                : row.values.full_name}
            </span>
          </div>
        </Link>
      ),
    },
    {
      Header: "Email",
      accessor: "email",
      width: 100,
    },
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: (props) => {
        // const tidyDate = dayjs(props.value).format("DD-MMM-YYYY");
        return <span>{dateyyyymmdd_tohtml(props?.value)}</span>;
      },
    },
    {
      Header: "Skills",
      accessor: "skills",
      width: 100,
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
      // Cell: ({ value }) => (
      //   <div>
      //     {/* <div style={{ whiteSpace: "normal", height: "auto" }}> */}
      //     {value && value.map((skill, index) => (
      //       <Badge
      //         key={index}
      //         color="primary"
      //         className="bg-primary text-white m-1"
      //       >
      //         {skill}
      //       </Badge>
      //     ))}
      //   </div>
      // ),
    },
    // {
    //   Header: "Experience",
    //   accessor: "experience",
    //   width: 100,

    //   // Cell: ({ value }) => value.join(", "),
    //   Cell: ({ value }) => (
    //     <>
    //       <div>
    //         {value >= 0 ? (
    //           <>
    //             {value} {value > 1 ? "Years" : "Year"}
    //           </>
    //         ) : (
    //           "-"
    //         )}
    //       </div>
    //     </>
    //   ),
    // },
    {
      Header: "Locations",
      accessor: "prefered_locations",
      Cell: ({ row }) => (
        <div className="">
          {row?.original?.location?.length >= 1
            ? row?.original?.location.join(", ")
            : "-"}
        </div>
      ),
      // width: 100,
      // Cell: (cellProps) => {
      //   return <Tags {...cellProps} />;
      // },
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <CandidateStatusBadge status={value} />,
    },

    // Add more columns as needed
  ];
  // shortlistCandidate column
  const shortlistedcolumns = [
    {
      Header: "Jobs",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidateshortlist(row.original);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },
    {
      Header: "skills",
      accessor: "skills",
      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
    },

    {
      Header: "Date",
      accessor: "updatedAt",
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Location",
      accessor: "location",
      Cell: ({ row }) => (
        <div className="">
          {row?.original?.location?.length >= 1
            ? row?.original?.location.join(", ")
            : "-"}
        </div>
      ),
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <JobsBadge status={value} />,
    },
    // Add more columns as needed
  ];

  // interview column
  const Interviewcolumns = [
    {
      Header: "Interview ID",
      accessor: "guid",
      width: 100,
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidateName(row.original);
          }}
        >
          {/* {row.values.guid} */}
          {row?.values?.guid?.split("-")[0].toUpperCase()}-
          {row?.values?.guid?.split("-")[1].toUpperCase()}
        </Link>
      ),
      // Cell: ({ value }) => value.join(', '),
    },
    {
      Header: "Job Title",
      accessor: "job_title",
    },
    {
      Header: "Candidate",
      accessor: "candidate",
      Cell: ({ row }) => (
        <div style={{ display: "flex", alignItems: "top" }}>
          <CustomAvatar fullName={row?.original?.candidate} />
          <span>
            {row?.original?.candidate.length > 10
              ? `${row?.original?.candidate.slice(0, 12)}...`
              : row?.original?.candidate}
          </span>
        </div>
      ),
    },
    {
      Header: "Interview Date",
      accessor: "interview_date",
      Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (props) => {
        return (
          <Badge className="badge bg-primary rounded-pill font-size-11 m-1">
            {props?.value}
          </Badge>
        );
      },
    },
  ];

  // contract column
  const Contractcolumns = [
    {
      Header: "Contract ID",
      accessor: "contract_id",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            handleContract(row.original);
          }}
        >
          {row?.original?.contract_id.split("-")[0].toUpperCase()}-
          {row?.original?.contract_id?.split("-")[1].toUpperCase()}
        </Link>
      ),
    },
    // {
    //   Header: "Candidate",
    //   accessor: "individuals",

    //   Cell: ({ value }) => (
    //     <div>
    //       {/* <div style={{ whiteSpace: "normal", height: "auto" }}> */}
    //       {value &&
    //         value.map((skill, index) => (
    //           <li
    //             key={index}
    //             color="primary"
    //             // className=" text-white m-1"
    //           >
    //             {skill.full_name}
    //           </li>
    //         ))}
    //     </div>
    //   ),
    // },
    {
      Header: "Candidate",
      accessor: "individuals",
      Cell: ({ value }) => (
        <div>
          {value &&
            value.map((candidate, index) => (
              <div key={index} style={{ display: "flex", alignItems: "top" }}>
                <CustomAvatar fullName={candidate.full_name} />
                <span>{candidate.full_name}</span>
              </div>
            ))}
        </div>
      ),
    },

    {
      Header: "Value",
      accessor: "contract_value",
      Cell: ({ value }) => (
        <div>
          <i className=" mdi mdi-currency-inr"></i>
          {value}
        </div>
      ),
    },
    {
      Header: "Billing Cycle",
      accessor: "billing_cycle",
      Cell: ({ value }) => <div>{value}</div>,
    },
    {
      Header: "Start Date",
      accessor: "started_at",
      width: 100,
      Cell: ({ value }) => (value ? dayjs(value).format("DD-MMM-YYYY") : "--"),
      // Cell: ({ row }) => {row.original?.started_at ? (dayjs(row.original.started_at).format("DD-MM-YYYY")) : '-'}
    },
    {
      Header: "End Date",
      accessor: "end_date",
      width: 100,
      // Cell: ({ value }) => dayjs(value).format("DD-MM-YYYY")
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => <OfferAcceptRejectBadge status={value} />,
    },
  ];

  const series = [
    {
      name: "My Candidate",
      data: ChartMycandidate,
    },
    {
      name: "Shortlisted Job",
      data: chartShortlistedJob,
    },
    {
      name: "Interview",
      data: ChartscheduledInterview,
    },
    {
      name: "Contract",
      data: Chartcontracts,
    },
  ];

  const apexoptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "45%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: ["#52A9CC", "#5156BE", "#2FB6EB"],
    xaxis: {
      categories: ChartDate,
      title: {
        text: "Number of Jobs based on Date range",
      },
    },
    yaxis: {
      title: {
        text: "Number of Jobs",
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          // return val + " Jobs";
          return val;
        },
      },
    },
  };
  const options = {
    chart: {
      height: 50,
      type: "line",
      toolbar: { show: false },
      sparkline: {
        enabled: true,
      },
    },
    colors: ["#5156be"],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
  };
  const Shortlisted = {
    title: "Shortlisted Jobs",
    value:
      Dashboardcounterdata &&
      Dashboardcounterdata?.shortlisted_jobs?.total_shortlisted_jobs,
    type: "line",
    // series: [/* Your series data for the chart */],
    series: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15],
    statusColor: "success", // Change this to your desired color
    // rank: `${
    //   Dashboardcounterdata && Dashboardcounterdata?.shortlisted_jobs?.percentage
    // }% Jobs Applied`, // Provide a rank value
    rank: `${
      Dashboardcounterdata?.shortlisted_jobs?.on_bench_applied_job || 0
    } Jobs Applied`,
  };
  const Interviews = {
    title: "Interviews",
    value:
      Dashboardcounterdata &&
      Dashboardcounterdata?.scheduled_interviews?.total_interviews,
    type: "line",
    sinceLastWeek: "since last week",
    // series: [/* Your series data for the chart */],
    series: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15],
    statusColor: "success", // Change this to your desired color
    // rank: `${
    //   Dashboardcounterdata &&
    //   Dashboardcounterdata?.scheduled_interviews?.percentage
    // }% interview Completed`, // Provide a rank value
    rank: `${
      Dashboardcounterdata?.scheduled_interviews?.completed_interviews || 0
    } Interview Completed`,
  };
  const Candidates = {
    title: "My Candidates",
    value:
      Dashboardcounterdata &&
      Dashboardcounterdata?.my_candidates?.total_my_candidates,
    type: "line",
    sinceLastWeek: "Since last week",
    // series: [/* Your series data for the chart */],
    series: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15],
    statusColor: "success", // Change this to your desired color
    // rank: `${
    //   Dashboardcounterdata && Dashboardcounterdata?.my_candidates?.percentage
    // }% On Bench`, // Provide a rank value
    rank: `${
      Dashboardcounterdata?.my_candidates?.on_bench_candidates || 0
    } On Bench`,
  };
  const Contracts = {
    title: "Contracts",
    value:
      Dashboardcounterdata && Dashboardcounterdata?.contracts?.total_contract,
    type: "line",
    sinceLastWeek: "Since last week",
    // series: [/* Your series data for the chart */],
    series: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15],
    statusColor: "success", // Change this to your desired color
    // rank: `${
    //   Dashboardcounterdata && Dashboardcounterdata?.contracts?.percentage
    // }% active`,
    rank: `${
      Dashboardcounterdata?.contracts?.active_contracts || 0
    } Active Contracts`, // Provide a rank value
  };

  const datePickerStyle = {
    position: "absolute",
    right: "0%",
    boxShadow: "0px 0px 8px 0px grey",
    zIndex: "1",
    backgroundColor: "#fff",
    padding: "5px",
    borderRadius: "8px",
  };
  const Candidateclick = (guid) => {
    setSelectedCandidateinfo(guid);
    setisOffcanvasOpenCandidate(true);
  };
  const candidateName = (guid) => {
    // Do something with the selected candidate, e.g., fetch details
    setSelectedCandidate(guid);
    setOffcanvasOpen(true);
  };
  const handleContract = (row) => {
    setContract(row);
    setIsOpen(true);
  };

  const candidateshortlist = (guid) => {
    setshortlistCandidate(guid);
    setOffcanvasOpenshortlist(true);
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
  };

  //button handlers and clicks
  const handleButtonClick = () => {
    setShowDatePicker(!showDatePicker);
  };
  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
    setformattedStartDate(
      moment(ranges.selection.startDate).format("YYYY-MM-DD"),
    );

    setformattedEndDate(moment(ranges.selection.endDate).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    // dispatch(Contract());
    dispatch(getVendorSummary()).then((response) => {
      const data = response?.payload?.data;
      // log_debug("vendorSummary", data)
      setVendorSummary(data);
    });
  }, []);

  useEffect(() => {
    dispatch(
      DashboardCounter({
        from_date: formattedStartDate,
        to_date: formattedEndDate,
      }),
    );
    dispatch(
      DashboardApexchart({
        from_date: formattedStartDate,
        to_date: formattedEndDate,
      }),
    );
  }, [formattedStartDate, formattedEndDate]);

  useEffect(() => {
    dispatch(
      DashboardTabsMycandidate({
        from_date: formattedStartDate,
        to_date: formattedEndDate,
        currentPage: currentPage ? currentPage : currentPage,
      }),
    );
  }, [formattedStartDate, formattedEndDate, currentPage]);

  useEffect(() => {
    dispatch(
      DashboardTabsShortlisted({
        from_date: formattedStartDate,
        to_date: formattedEndDate,
        currentPage: currentPage2 ? currentPage2 : currentPage2,
      }),
    );
  }, [formattedStartDate, formattedEndDate, currentPage2]);

  useEffect(() => {
    dispatch(
      DashboardTabsInterview({
        from_date: formattedStartDate,
        to_date: formattedEndDate,
        currentPage: currentPage3 ? currentPage3 : currentPage3,
      }),
    );
  }, [formattedStartDate, formattedEndDate, currentPage3]);

  useEffect(() => {
    dispatch(
      DashboardTabsContract({
        from_date: formattedStartDate,
        to_date: formattedEndDate,
        currentPage: currentPage4 ? currentPage4 : currentPage4,
      }),
    );
  }, [formattedStartDate, formattedEndDate, currentPage4]);

  useEffect(() => {
    const first10Records = DashboardChartData;
    // const first10Records = DashboardChartData.slice(0, 11);
    const dates = first10Records.map((dashchartdate) =>
      moment(dashchartdate.date).format("DD MMM"),
    );
    const mycandidatelist = first10Records.map(
      (candidate) => candidate.my_candidates,
    );
    const InterviewList = first10Records.map(
      (interv) => interv.scheduled_interviews,
    );
    const shortlistdata = first10Records.map(
      (shortlist) => shortlist.shortlisted_jobs,
    );
    const contractlist = first10Records.map(
      (contractinfo) => contractinfo.contracts,
    );

    setChartDate(dates);
    setChartMycandidate(mycandidatelist);
    setchartShortlistedJob(shortlistdata);
    setChartscheduledInterview(InterviewList);
    setChartcontracts(contractlist);
  }, [DashboardChartData]);

  useEffect(() => {
    if (partnerStore?.status === "idle") {
      if (
        partnerStore?.data?.DashboardTabsMycandidatedata?.success === true &&
        partnerStore?.data?.DashboardTabsMycandidatedata?.data?.individuals
      ) {
        setMyCandidateList(
          partnerStore?.data?.DashboardTabsMycandidatedata?.data?.individuals,
        );
        setcandidatecount(
          partnerStore?.data?.DashboardTabsMycandidatedata?.data?.totalCount,
        );
      }
      if (partnerStore?.data?.DashboardTabsInterviewdata?.success === true) {
        setInterviewjobsdataList(
          partnerStore?.data?.DashboardTabsInterviewdata?.data?.individuals,
        );
        setinterviewcount(
          partnerStore?.data?.DashboardTabsInterviewdata?.data?.totalCount,
        );
      }
      if (partnerStore?.data?.DashboardTabsContractdata?.success === true) {
        setPartnerContracts(
          partnerStore.data.DashboardTabsContractdata.data.contracts,
        );
        setcontractcount(
          partnerStore.data.DashboardTabsContractdata.data.totalCount,
        );
      }

      if (partnerStore.data.DashboardTabsShortlisteddata?.success === true) {
        setShortlistjobs(
          partnerStore.data.DashboardTabsShortlisteddata?.data?.jobs,
        );
        setshortlistcount(
          partnerStore.data.DashboardTabsShortlisteddata?.data?.totalCount,
        );
      }
      if (partnerStore?.data?.DashboardCounterdata?.success === true) {
        setDashboardcounterdata(
          partnerStore?.data?.DashboardCounterdata?.data?.dashboard_counts,
        );
      }
      if (partnerStore?.data?.DashboardApexchartdata?.success === true) {
        setDashboardChartDatahideshow(true);
        setDashboardChartData(
          partnerStore?.data?.DashboardApexchartdata?.data?.dashboard_counts,
        );
      }
      setisloadingCandidate(false);
      setisloadingInterview(false);
      setisloadingContract(false);
      setisloadingshortlist(false);
    } else if (partnerStore?.status === "loading") {
      setisloadingCandidate(true);
      setisloadingInterview(true);
      setisloadingContract(true);
      setisloadingshortlist(true);
    }
  }, [partnerStore]);
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding padding if necessary
    const day = String(date.getDate()).padStart(2, "0"); // Adding padding if necessary
    return `${year}-${month}-${day}`;
  };
  const breadcrumbValues = ["Dashboard"];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageChangeshortlist = (newPage) => {
    setCurrentPage2(newPage);
  };
  const handlePageChangeinterview = (newPage) => {
    setCurrentPage3(newPage);
  };
  const handlePageChangeContracts = (newPage) => {
    setCurrentPage4(newPage);
  };



  const columns = [
    // {
    //   Header: "Candidate Name",
    //   accessor: "",
    // },

    {
      Header: "Total Job Submissions",
      accessor: "job submission",
      Cell: ({ value }) => <div>{value ? value : 0}</div>
    },
    {
      Header: "Total Screen Select",
      accessor: "Screen Select",
      Cell: ({ value }) => <div>{value ? value : 0}</div>
    },
    {
      Header: "Total L1 Interviews",
      accessor: "L1",
      Cell: ({ value }) => <div>{value ? value : 0}</div>
    },

    {
      Header: "Total L2 Interviews",
      accessor: "L2",
      Cell: ({ value }) => <div>{value ? value : 0}</div>
    },
    {
      Header: "Total Final Select",
      accessor: "HR",
      Cell: ({ value }) => <div>{value ? value : 0}</div>
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={9} className="mt-3 float-left">
              <CustomBreadcrumb
                values={breadcrumbValues}
                style={{ paddingLeft: "0px" }}
              />
            </Col>

            <Col lg={3}>
              <div>
                <form className="app-search d-none d-lg-block">
                  <div className="position-relative">
                    <div>
                      <input
                        type="text"
                        className="form-control  text text-center bg-light"
                        // placeholder={dateRange}
                        value={`${formatDate(
                          selectionRange.startDate,
                        )} - ${formatDate(selectionRange.endDate)}`}
                        // value={`${selectionRange.startDate.toLocaleDateString()} - ${selectionRange.endDate.toLocaleDateString()}`}
                        style={{ cursor: "pointer" }}
                        onClick={handleButtonClick}
                        readOnly
                      />

                      <IconButton
                        className="btn"
                        color="primary"
                        onClick={handleButtonClick}
                      >
                        <i className="align-middle">
                          <CalendarMonthIcon />
                        </i>
                      </IconButton>
                    </div>
                    {/* </button> */}
                  </div>
                </form>

                {showDatePicker && (
                  <div style={datePickerStyle}>
                    <DateRangePicker
                      ranges={[selectionRange]}
                      onChange={handleSelect}
                      maxDate={new Date()}
                    />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={3} md={3} sm={6} lg={2}>
              <DashboardCountCard
                title={Candidates.title}
                CircularProgress={
                  Dashboardcounterdata &&
                  Dashboardcounterdata?.my_candidates?.percentage
                }
                options={options}
                {...Candidates}
                redirectTo="/mycandidate"
              />
            </Col>
            <Col xl={3} md={3} sm={6} lg={2}>
              <DashboardCountCard
                title={Shortlisted.title}
                CircularProgress={
                  Dashboardcounterdata &&
                  Dashboardcounterdata?.shortlisted_jobs?.percentage
                }
                // options={options}
                {...Shortlisted}
                redirectTo="/shortlistedjobs"
              />
            </Col>
            <Col xl={3} md={3} sm={6} lg={2}>
              <DashboardCountCard
                title={Interviews.title}
                CircularProgress={
                  Dashboardcounterdata &&
                  Dashboardcounterdata?.scheduled_interviews?.percentage
                }
                options={options}
                {...Interviews}
                redirectTo="/candidateinterview"
              />
            </Col>
            <Col xl={3} md={3} sm={6} lg={2}>
              <DashboardCountCard
                title={Contracts.title}
                CircularProgress={
                  Dashboardcounterdata &&
                  Dashboardcounterdata?.contracts?.percentage
                }
                options={options}
                {...Contracts}
                redirectTo="/contract"
                backgroundColor="#E9E9EF"
              />
            </Col>
          </Row>
          {/* {DashboardChartDatahideshow && DashboardChartData.length >= 1 && ( */}
            <Row>
              <Col xl={12}>
              <Card>
                <CardTitle>
                  <h4 className="p-2">Vendor Summary</h4>
                </CardTitle>
                      <CardBody className="p-0 px-2">
                        <CustomTable
                          totalCount={0}
                          currentPage={0}
                          columns={columns}
                          data={vendorSummary}
                          hideCheckbox={true}
                          globalsearch={false}
                          ShowGlobalFilter={true}
                        />
                      </CardBody>
                    </Card>
                {/* <ApexChart
                  options={apexoptions}
                  series={series}
                  userType={userType}
                /> */}
              </Col>
            </Row>
          {/* )} */}
          <Row>
            <Col xl={12}>
              <div>
                <CardHeader className="align-items-center d-flex my-2">
                  <div className="flex-shrink-0">
                    <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerTab === "1",
                          })}
                          onClick={() => {
                            toggleHeader("1");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-user"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            Candidates{" "}
                            <Badge color="primary">
                              {(candidatecount ? candidatecount : 0)
                                .toString()
                                .padStart(2, "0")}
                            </Badge>
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerTab === "2",
                          })}
                          onClick={() => {
                            toggleHeader("2");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-envelope"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            Shortlisted{" "}
                            <Badge color="primary">
                              {(shortlistcount ? shortlistcount : 0)
                                .toString()
                                .padStart(2, "0")}
                            </Badge>
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerTab === "3",
                          })}
                          onClick={() => {
                            toggleHeader("3");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            Interviews{" "}
                            <Badge color="primary">
                              {(interviewcount ? interviewcount : 0)
                                .toString()
                                .padStart(2, "0")}
                            </Badge>
                          </span>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({
                            active: headerTab === "4",
                          })}
                          onClick={() => {
                            toggleHeader("4");
                          }}
                        >
                          <span className="d-block d-sm-none">
                            <i className="far fa-envelope"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            Contracts{" "}
                            <Badge color="primary">
                              {(contractcount ? contractcount : 0)
                                .toString()
                                .padStart(2, "0")}
                            </Badge>
                          </span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </CardHeader>

                <div className="card-body">
                  <TabContent className="text-muted" activeTab={headerTab}>
                    <TabPane tabId="1">
                      <>
                        <CustomTable
                          shortlist={true}
                          redirectTo="/mycandidate"
                          isLoading={isloadingCandidate}
                          totalCount={candidatecount}
                          columns={CandidateColumn}
                          hideCheckbox={true}
                          data={MyCandidateList && MyCandidateList}
                          currentPage={currentPage}
                          onPageChange={handlePageChange}
                        />
                        {isOffcanvasOpenCandidate && (
                          <CanvasCandidate
                            usertype={"partner"}
                            placement="end"
                            width={60}
                            isOpen={isOffcanvasOpenCandidate}
                            toggle={() =>
                              setisOffcanvasOpenCandidate(
                                !isOffcanvasOpenCandidate,
                              )
                            }
                            onClose={closeOffcanvas}
                            data={selectedCandidateinfo}
                          />
                        )}
                      </>
                    </TabPane>

                    <TabPane tabId="2">
                      <>
                        <CustomTable
                          shortlist={true}
                          isLoading={isloadingshortlist}
                          redirectTo="/shortlistedjobs"
                          totalCount={shortlistcount}
                          hideCheckbox={true}
                          columns={shortlistedcolumns}
                          data={Shortlistjobs && Shortlistjobs}
                          currentPage={currentPage2}
                          onPageChange={handlePageChangeshortlist}
                        />
                        {OffcanvasOpenshortlist && (
                          <CanvasPartnerJobDetails
                            shortlistedstatus={true}
                            width={50}
                            placement="end"
                            isOpen={OffcanvasOpenshortlist}
                            toggle={() =>
                              setOffcanvasOpenshortlist(!OffcanvasOpenshortlist)
                            }
                            onclose={closeOffcanvas}
                            data={shortlistCandidate}
                          />
                        )}
                      </>
                    </TabPane>
                    <TabPane tabId="3">
                      <>
                        <CustomTable
                          shortlist={true}
                          isLoading={isloadingInterview}
                          redirectTo="/candidateinterview"
                          totalCount={interviewcount}
                          columns={Interviewcolumns}
                          hideCheckbox={true}
                          data={InterviewjobsdataList && InterviewjobsdataList}
                          currentPage={currentPage3}
                          onPageChange={handlePageChangeinterview}
                        />
                        {isOffcanvasOpen && (
                          <InterviewDetailsOffcanvas
                            placement="end"
                            isOpen={isOffcanvasOpen}
                            toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
                            onClose={closeOffcanvas}
                            data={selectedCandidate}
                          />
                        )}
                      </>
                    </TabPane>

                    <TabPane tabId="4">
                      <>
                        <CustomTable
                          shortlist={true}
                          isLoading={isloadingContract}
                          redirectTo="/contract"
                          totalCount={contractcount}
                          columns={Contractcolumns}
                          hideCheckbox={true}
                          data={partnerContracts ? partnerContracts : []}
                          currentPage={currentPage4}
                          onPageChange={handlePageChangeContracts}
                        />
                        {isOpen && (
                          <ContractDetailCanvas
                            isOpen={isOpen}
                            toggle={() => setIsOpen(!isOpen)}
                            onClose={closeOffcanvas}
                            data={contract}
                          />
                        )}
                      </>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PartnerDashboard;
