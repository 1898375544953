import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";

// Calendar
import Calendar from "../pages/Calendar/index";

//Chat
import Chat from "../pages/Chat/Chat";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

//Invoice
import InvoicesList from "../pages/Invoices/invoices-list";
import InvoiceDetail from "../pages/Invoices/invoices-detail";

//Contact
import ContactsGrid from "../pages/Contacts/contacts-grid";
import ContactsList from "../pages/Contacts/ContactList/contacts-list";
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile";

//blog
import BlogGrid from "../pages/Blog/blogGrid";
import BlogList from "../pages/Blog/blogList";
import BlogDetails from "../pages/Blog/blogDetails";

//Utility
import PagesStarter from "../pages/Utility/StarterPage";
import PageMaintenance from "../pages/Utility/PageMaintenance";
import PagesComingsoon from "../pages/Utility/PageComingsoon";
import PageTimeline from "../pages/Utility/PageTimeline";
import PageFaqs from "../pages/Utility/PageFaqs";
import PagePricing from "../pages/Utility/PagePricing/index";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

// Ui Components
import UiAlert from "../pages/UiElements/UiAlert";
import UiButton from "../pages/UiElements/UiButton";
import UiCard from "../pages/UiElements/UiCard";
import UiCarousel from "../pages/UiElements/UiCarousel";
import UiDropdowns from "../pages/UiElements/UiDropdowns";
import UiGrid from "../pages/UiElements/UiGrid";
import UiImages from "../pages/UiElements/UiImages";
import UiModal from "../pages/UiElements/UiModals";
import UiOffCanvas from "../pages/UiElements/UiOffCanvas";
import UiProgressbar from "../pages/UiElements/UiProgressbar";
import UiPlaceholders from "../pages/UiElements/UiPlaceholders";
import UiTabsAccordions from "../pages/UiElements/UiTabsAccordions";
import UiTypography from "../pages/UiElements/UiTypography";
import UiToasts from "../pages/UiElements/UiToast";
import UiVideo from "../pages/UiElements/UiVideo";
import UiGeneral from "../pages/UiElements/UiGeneral";
import UiColors from "../pages/UiElements/UiColors";
import UiUtilities from "../pages/UiElements/UiUtilities";

//Extended pages
import Lightbox from "../pages/Extended/Lightbox";
import SessionTimeout from "../pages/Extended/SessionTimeout";
import UiRating from "../pages/Extended/UiRating";
import Notifications from "../pages/Extended/Notifications";

//Forms
import FormElements from "../pages/Forms/FormElements/index";
import FormValidation from "../pages/Forms/FormValidation/";
import AdvancedPlugins from "../pages/Forms/AdvancedPlugins";
import FormEditors from "../pages/Forms/FormEditors";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormMask from "../pages/Forms/FormMask";

//Tables
import BasicTable from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

//Charts
import Apexchart from "../pages/Charts/Apexcharts";
import EChart from "../pages/Charts/EChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import KnobCharts from "../pages/Charts/KnobCharts";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconFontawesomes from "../pages/Icons/Fontawesomes";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//AuthenticationInner related pages
import PageLogin from "../pages/AuthenticationInner/PageLogin";
import PageRegister from "../pages/AuthenticationInner/PageRegister";
import RecoverPassword from "../pages/AuthenticationInner/RecoverPassword";
import LockScreen from "../pages/AuthenticationInner/LockScreen";
import ConfirmMail from "../pages/AuthenticationInner/ConfirmMail";
import EmailVerification from "../pages/AuthenticationInner/EmailVerification";
import TwoStepVerfication from "../pages/AuthenticationInner/TwoStepVerfication";
import AuthLogout from "../pages/AuthenticationInner/Logout";
import UserProfile from "../pages/Authentication/user-profile";
import RangeSlider from "../pages/Extended/RangeSlider/Index";

//PartnerDashboard
import PartnerProfileDetails from "../Partner/PartnerProfileDetails";
import PartnerDashboard from "../Partner/PartnerDashboard";
import AddCandidate from "../Partner/AddCandidate";
import MyCandidate from "../Partner/MyCandidate";
import PartnerAlljobs from "../Partner/PartnerAlljobs";
import PartnerCandidateInterview from "../Partner/PartnerCandidateInterview";
import PartnerJobinvitation from "../Partner/PartnerJobinvitation";
import PartnerMatchingJob from "../Partner/PartnerMatchingJob";
import Shortlistedjobs from "../Partner/Shortlistedjobs";
import DetailCard from "../customComponents/DetailCard";
import MyCandidatesDetails from "../Partner/MyCandidatesDetails";
import JobOffer from "../Partner/JobOffer";
import NewCandidateResumeDetails from "../Partner/pagesComponents/NewCandidateResumeDetails";
import PartnerContract from "../Partner/PartnerContract";
import PartnerInvoice from "../Partner/PartnerInvoice";

// Individual Dashboard
import IndividualDashboard from "../individual/individualDashboard";
import IndividualInterviews from "../individual/IndividualInterviews";
import IndividualProfileDetails from "../individual/IndvidualProfileDetails";
import IndividualApplied from "../individual/IndividualApplied";
import IndividualJobs from "../individual/IndividualJobs";
import IndividualAssessments from "../individual/IndividualAssessments";
import IndividualInvitaions from "../individual/IndividualInvitations";
import IndividualOffers from "../individual/IndividualOffers";
import IndividualMatchingJobs from "../individual/IndividualMatchingJobs";
import IndividualTimesheets from "../individual/IndividualTimesheets";

// Client Dashboard
import ClientJobs from "../Client/ClientJobs";
import ClientPostJob from "../Client/ClientPostJob";
import ShortlistedCandidates from "../Client/ClientShortlistedCandidates";
import Invitations from "../Client/ClientInvitations";
import AllCandidates from "../Client/ClientAllCandidates";
import MatchingCandidates from "../Client/ClientMatchingCandidates";
import AppliedCandidates from "../Client/ClientAppliedCandidates";
import ClientDashboard from "../Client/ClientDashboard";
import ClientInterviews from "../Client/ClientInterviews";
import Authmiddleware from "./middleware/Authmiddleware";
import ClientProfileDetails from "../Client/ClientProfileDetails";
import ClientOffers from "../Client/ClientOffers";
import ClientContract from "../Client/ClientContract";
import PartnerIndividualTimesheets from "../Partner_Individual/PartnerIndividualTimesheet";
import PartnerIndividualContracts from "../Partner_Individual/PartnerIndividualContracts";
import AddContract from "../Partner/pagesComponents/AddContract";
import AddInvoice from "../Partner/AddInvoice";
import EditInvoice from "../Partner/EditInvoice";
import RecruiteeAllJobs from "../Recrutment/RecruiteeAllJobs";
import RecruiteeCandidate from "../Recrutment/RecruiteeCandidate";
import RecruiteeDashboard from "../Recrutment/RecruiteeDashboard";
import RecruiteeSubmission from "../Recrutment/RecruiteeSubmission";
import RecruiteeMatchingJobs from "../Recrutment/RecruiteeMatchingJobs";
import PartnerIndividualApprovedTimesheets from "../Partner_Individual/PartnerIndividualApprovedTimesheets";
import IndividualContracts from "../individual/IndividualContracts";
import PartnerTimesheets from "../Partner/PartnerTimesheets";
import Cb_accounts_dashboard from "../CB_Accounts/CB_Account_dashboard";
import CandidateProfileDetails from "../Partner/pagesComponents/CandidateProfileDetails";
import IndividualParseResume from "../customComponents/individualSignup/IndividualUploadResume";
import IndividualProfileSignupDetails from "../customComponents/individualSignup/IndividualDetailsPage";
import AppliedJobs from "../Partner/AppliedJobs";
import PartnerPricing from "../Partner/PartnerPricing";
import MyPlans from "../Partner/MyPlan";
import MyTeamUser from "../Partner/MyTeam";

// New pages Imports

// import Interviews from "../individual/interviews";
// import JobInvitaion from "../individual/jobInvitaion";
// import MatchingJobs from "../individual/matchingJobs";
// import IndProfile from "../individual/profile";
// import ShortlistedJobs from "../individual/shortlistedJobs";

const userRoutes = [
  //dashboard
  // { path: "/dashboard", component: <Dashboard/> },
  // { path: "/dashboard", component: <Dashboard/> },
  { path: "/individualdashboard", component: <IndividualDashboard /> },

  //profile
  { path: "/profile", component: <UserProfile /> },

  //Calendar
  { path: "/apps-calendar", component: <Calendar /> },

  //Chat
  { path: "/apps-chat", component: <Chat /> },

  //Email
  { path: "/email-inbox", component: <EmailInbox /> },
  { path: "/email-read", component: <EmailRead /> },

  //Invoice
  { path: "/invoices-list", component: <InvoicesList /> },
  { path: "/invoices-detail", component: <InvoiceDetail /> },

  //Contact
  { path: "/contacts-grid", component: <ContactsGrid /> },
  { path: "/contacts-list", component: <ContactsList /> },
  { path: "/contacts-profile", component: <ContactsProfile /> },

  //blog
  { path: "/blog-grid", component: <BlogGrid /> },
  { path: "/blog-list", component: <BlogList /> },
  { path: "/blog-details", component: <BlogDetails /> },

  //Utility
  { path: "/pages-starter", component: <PagesStarter /> },
  { path: "/pages-timeline", component: <PageTimeline /> },
  { path: "/pages-faqs", component: <PageFaqs /> },
  { path: "/pages-pricing", component: <PagePricing /> },

  //Components
  { path: "/ui-alerts", component: <UiAlert /> },
  { path: "/ui-buttons", component: <UiButton /> },
  { path: "/ui-cards", component: <UiCard /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-dropdowns", component: <UiDropdowns /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-modals", component: <UiModal /> },
  { path: "/ui-offcanvas", component: <UiOffCanvas /> },
  { path: "/ui-progressbars", component: <UiProgressbar /> },
  { path: "/ui-placeholders", component: <UiPlaceholders /> },
  { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-toasts", component: <UiToasts /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-general", component: <UiGeneral /> },
  { path: "/ui-colors", component: <UiColors /> },
  { path: "/ui-utilities", component: <UiUtilities /> },

  //Extended pages
  { path: "/extended-lightbox", component: <Lightbox /> },
  { path: "/extended-rangeslider", component: <RangeSlider /> },
  { path: "/extended-session-timeout", component: <SessionTimeout /> },
  { path: "/extended-rating", component: <UiRating /> },
  { path: "/extended-notifications", component: <Notifications /> },

  //Forms
  { path: "/form-elements", component: <FormElements /> },
  { path: "/form-validation", component: <FormValidation /> },
  { path: "/form-advanced", component: <AdvancedPlugins /> },
  { path: "/form-editors", component: <FormEditors /> },
  { path: "/form-uploads", component: <FormUpload /> },
  { path: "/form-wizard", component: <FormWizard /> },
  { path: "/form-mask", component: <FormMask /> },

  //tables
  { path: "/tables-basic", component: <BasicTable /> },
  { path: "/tables-datatable", component: <DatatableTables /> },
  { path: "/tables-responsive", component: <ResponsiveTables /> },
  { path: "/tables-editable", component: <EditableTables /> },

  //Charts
  { path: "/charts-apex", component: <Apexchart /> },
  { path: "/charts-echart", component: <EChart /> },
  { path: "/charts-chartjs", component: <ChartjsChart /> },
  { path: "/charts-knob", component: <KnobCharts /> },

  //Icons
  { path: "/icons-boxicons", component: <IconBoxicons /> },
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-dripicons", component: <IconDripicons /> },
  { path: "/icons-fontawesome", component: <IconFontawesomes /> },

  // Maps
  { path: "/maps-google", component: <MapsGoogle /> },
  { path: "/maps-vector", component: <MapsVector /> },
  { path: "/maps-leaflet", component: <MapsLeaflet /> },

  //PartnerDashboard

  { path: "/dashboard", component: <PartnerDashboard /> },
  { path: "/partner-profile", component: <PartnerProfileDetails /> },
  { path: "/addcandidate", component: <AddCandidate /> },
  { path: "/mycandidate", component: <MyCandidate /> },
  { path: "/alljobs", component: <PartnerAlljobs /> },
  { path: "/candidateinterview", component: <PartnerCandidateInterview /> },
  { path: "/jobinvitations", component: <PartnerJobinvitation /> },
  { path: "/matchingjobs", component: <PartnerMatchingJob /> },
  { path: "/shortlistedjobs", component: <AppliedJobs /> },
  { path: "/mycandidatesdetails/:id", component: <MyCandidatesDetails /> },
  { path: "/joboffer", component: <JobOffer /> },
  { path: "/candidatedetails", component: <CandidateProfileDetails /> },
  { path: "/contract", component: <PartnerContract /> },
  { path: "/add-contract", component: <AddContract /> },
  { path: "/invoice", component: <PartnerInvoice /> },
  { path: "/add-invoice", component: <AddInvoice /> },
  { path: "/edit-invoice", component: <EditInvoice /> },
  { path: "/timesheets", component: <PartnerTimesheets /> },

  //IndividualDashboard
  { path: "/individual-assessments", component: <IndividualAssessments /> },
  { path: "/job-detail", component: <DetailCard /> },
  { path: "/individual-jobs", component: <IndividualJobs /> },
  { path: "/individual-invitations", component: <IndividualInvitaions /> },
  {
    path: "/individual-offers",
    component: <IndividualOffers />,
  },
  { path: "/individual-interviews", component: <IndividualInterviews /> },
  { path: "/individual-profile", component: <IndividualProfileDetails /> },
  { path: "/individual-applied", component: <IndividualApplied /> },
  { path: "/individual-matchingjobs", component: <IndividualMatchingJobs /> },
  { path: "/individual-timesheets", component: <IndividualTimesheets /> },

  // Recrutment firm
  { path: "/recruitee-dashboard", component: <RecruiteeDashboard /> },
  { path: "/recruitee-alljobs", component: <RecruiteeAllJobs /> },
  { path: "/recruitee-candidate", component: <RecruiteeCandidate /> },
  { path: "/recruitee-shortlistedjob", component: <RecruiteeSubmission /> },
  { path: "/recruitee-matchingjobs", component: <RecruiteeMatchingJobs /> },
  { path: "/individual-timesheets", component: <IndividualTimesheets /> },
  { path: "/individual-contracts", component: <IndividualContracts /> },

  // ClientDashboard
  { path: "/client/dashboard", component: <ClientDashboard /> },
  { path: "client/jobs", component: <ClientJobs /> },
  { path: "client/post-job", component: <ClientPostJob /> },
  {
    path: "client/shortlistedcandidates",
    component: <ShortlistedCandidates />,
  },
  { path: "client/invitations", component: <Invitations /> },
  { path: "client/allcandidates", component: <AllCandidates /> },
  { path: "client/matchingcandidates", component: <MatchingCandidates /> },
  { path: "client/appliedcandidates", component: <AppliedCandidates /> },
  { path: "client/interviews", component: <ClientInterviews /> },
  { path: "/authentication", component: <Authmiddleware /> },
  { path: "/client/profile", component: <ClientProfileDetails /> },
  { path: "/client/offers", component: <ClientOffers /> },
  { path: "/client/contracts", component: <ClientContract /> },

  //Partner Individual Persona
  {
    path: "/partner/individual/timesheets",
    component: <PartnerIndividualTimesheets />,
  },
  {
    path: "/partner/individual/contracts",
    component: <PartnerIndividualContracts />,
  },
  {
    path: "/partner/individual/timesheets-approved",
    component: <PartnerIndividualApprovedTimesheets />,
  },

  { path : "/partner/pricing" , component : <PartnerPricing/>},
  { path : "/partner/myplan", component: <MyPlans/>},
  { path : "/partner/team", component: <MyTeamUser/>},

  { path: "/accounts/dashboard", component: <Cb_accounts_dashboard/>},

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
];

const authRoutes = [
  //   //authencation page
  //   { path: "/logout", component: <Logout /> },
  //   { path: "/login", component: <Login /> },
  //   { path: "/forgot-password", component: <ForgetPwd /> },
  //   { path: "/register", component: <Register /> },

  //   //AuthenticationInner pages
  //   { path: "/page-login", component: <PageLogin /> },
  //   { path: "/page-register", component: <PageRegister /> },
  //   { path: "/page-recoverpw", component: <RecoverPassword /> },
  //   { path: "/page-lock-screen", component: <LockScreen /> },
  //   { path: "/page-confirm-mail", component: <ConfirmMail /> },
  //   { path: "/page-email-verification", component: <EmailVerification /> },
  //   { path: "/page-two-step-verification", component: <TwoStepVerfication /> },
  //   { path: "/page-logout", component: <AuthLogout /> },

  //   //Utility page
  { path: "/individual/resume/upload", component: <IndividualParseResume /> },
  { path: "/individual-details", component: <CandidateProfileDetails/>}
  //   { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  //   { path: "/pages-404", component: <Error404 /> },
  //   { path: "/pages-500", component: <Error500 /> },

  //   //New Routes
  //   // { path: "/dashboard", component: <Dashboard /> },
  //   // { path: "/interviews", component: <Interviews/> },
  //   // { path: "/job-invitation", component: <JobInvitaion/> },
  //   // { path: "/matching-jobs", component: <MatchingJobs/> },
  //   // { path: "/my-profile", component: <IndProfile/> },
  //   // { path: "/shortlisted-jobs", component: <ShortlistedJobs/> },
];

export { userRoutes, authRoutes };
