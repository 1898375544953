// CustomOffcanvas.js
import React, { useEffect, useState } from "react";

import {
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Button,
  CardText,
  Col,
  Row,
} from "reactstrap";
import "../../Partner/partner.css";
import { log_debug } from "../../apiHelpers/logger";
import { useDispatch } from "react-redux";
import { allunappliedcandidates, DashboardCounter, machingJobApplyJob, mycandidates } from "../../Partner/PartnerSlice";
import { Link } from "react-router-dom";
import CandidateStatusBadge from "../utilities/CandidateStatusBadge";
import CustomAvatar from "../CustomAvatar/CustomAvatar";
import { Tags } from "../../pages/Contacts/ContactList/contactlistCol";
import CustomTable from "../reactTable/CustomTable";
import { getPartnerGuid } from "../../apiConfig/globalvariables";
import { Pguid } from "../../apiConfig/axiosDev";
import { toast } from "react-toastify";
import moment from "moment";
import Preloader from "../../components/Common/Preloader";


export function ApplyJobsCanvas({
  isOpen,
  toggle,
  onClose,
  jobguid,
  data
}) {

    log_debug("recievedapplyjobguid", jobguid)

    const dispatch = useDispatch();
    const [IndividualData, setIndividualData] = useState([]);
    const [DisableApply, setDisableApply] = useState(false);
    const [RecordCount, setRecordCount] = useState(0);
    const [CurrentPage, setCurrentPage] = useState(0);
    const [filtervalue, setFiltervalue] = useState("");
    const [Loading, setLoading] = useState(false);
    const defaultEndDate = new Date();
  const defaultStartDate = new Date();
    const [formattedStartDate, setformattedStartDate] = useState(
        // moment(defaultStartDate).format("YYYY-MM-DD"),
        moment(defaultStartDate).format("YYYY-MM-DD"),
      );
      const [formattedEndDate, setformattedEndDate] = useState(
        moment(defaultEndDate).format("YYYY-MM-DD"),
      );


    useEffect(() => {
        try {
                setLoading(true);
                const timer = setTimeout(() => {
                dispatch(allunappliedcandidates({
                    jobguid:jobguid,
                    filtervalue:filtervalue,
                    currentPage:CurrentPage
                  })).then((response) => {
                    setLoading(false)
                    const data = response?.payload?.data?.unappliedIndividuals;
                    setIndividualData(data);
                    setRecordCount(response?.payload?.data?.pagination?.totalCount);
                  })
                },700)
                  return () => clearTimeout(timer);
        } catch (error) {
            setLoading(false)
            log_debug(error)
        }
        
    },[jobguid,CurrentPage, filtervalue])

    const offcanvasStyle = {
        width: `60%`,
        //zoom: '80%',
      };

      const columns = [
        {
          Header: "Name",
          accessor: "full_name",
          Cell: ({ value }) => (
            <div className="d-flex" title={value}>
              <CustomAvatar fullName={value} />
              <span>{value?.length > 10 ? `${value.slice(0, 10)}...` : value}</span>
            </div>
          ),
        },
        {
          Header: "Email",
          accessor: "email",
          Cell: ({ value }) => (
              <span title={value}>{value}</span>
          ),
          //width: 100,
        },
        {
          Header: "Skills",
          accessor: "skills",
          Cell: (cellProps) => {
            return <Tags {...cellProps} />;
          },
        },
        {
            Header: "Location",
            accessor: "prefered_locations",
            Cell: ({ row }) => (
              <div className="">
                {row?.original?.prefered_locations?.length >= 1
                  ? row?.original?.prefered_locations.join(", ")
                  : "-"}
              </div>
            ),
        },
        // {
        //   Header: "Status",
        //   accessor: "status",
        //   Cell: ({ value }) => <CandidateStatusBadge status={value} />,
        // },
        {
          Header: "Action",
          accessor: "action",
          Cell: ({ row }) => (
            <>
              <Link to={"#"} className="text-white">
                <Button
                color="primary"
                disabled={DisableApply}
                onClick={() => handleButtonClick(row)}
                >
                  <i className=" fas fa-paper-plane fs-6"></i>&nbsp; Apply
                </Button>
              </Link>
            </>
          ),
        },
    
      ];

      const handleButtonClick = async (row) => {
        try {
            log_debug("dsjfauie",row.original.guid)
            setDisableApply(true);
            setLoading(true)
            dispatch(
              machingJobApplyJob({
                individualjobmapping: {
                  individual_guid: [row.original.guid],
                  partner_guid: getPartnerGuid() || Pguid,
                  jobdescription_guid: [jobguid],
                },
              }),
            ).then((response) => {
              if (response.payload.success === true) {
                setLoading(true)
                dispatch(allunappliedcandidates({
                    jobguid:jobguid
                  })).then((response) => {
                    setLoading(false)
                    const data = response?.payload?.data?.unappliedIndividuals;
                    setIndividualData(data);
                    setRecordCount(response?.payload?.data?.pagination?.totalCount);
                  });
                dispatch(
                  DashboardCounter({
                    from_date: formattedStartDate,
                    to_date: formattedEndDate,
                  }),
                );
                toast.success("Job applied successfully!");
                setDisableApply(false);
              } else if(response.payload.success === false) {
                log_debug("4r93",response)
                toast.warning(response?.payload?.error?.message);
                setDisableApply(false);
                setLoading(false)
              }
            });
        } catch (error) {
            setDisableApply(false);
            setLoading(false)
            log_debug("error",error)
        }
       
      };

      const handlePageChangematching = (newPage) => {
        setCurrentPage(newPage);
      };

      const handleValueChange = (newValue) => {
        setFiltervalue(newValue);
      };

  return (
    <>
    {/* {Loading && <Preloader/>} */}
      <Offcanvas
        direction="end"
        isOpen={isOpen}
        className="custom-offcanvas"
        contentClassName="custom-offcanvas-content"
        onClose={onClose}
        style={offcanvasStyle}
      >
        <OffcanvasHeader className="border-bottom" toggle={toggle}>
          <h5 className="text-primary">
            Apply Job
          </h5>
        </OffcanvasHeader>
        <OffcanvasBody>
            <Row>
                <Col lg={12} md={6}>
                <h6>{data && data?.title}{" "}{data?.jobId && `(${data?.jobId})`}</h6>
                </Col>
            </Row>
        <Row>
                        <Col sm="12">
                          <CardText className="mb-0">
                            <CustomTable
                              //JobDescriptionGuid={jobguid}
                              isLoading={Loading}
                              columns={columns}
                              data={
                                IndividualData && IndividualData
                              }
                              totalCount={RecordCount}
                              hideCheckbox={true}
                              currentPage={CurrentPage}
                              onPageChange={handlePageChangematching}
                              onChange={handleValueChange}
                                globalsearch={true}
                            />
                          </CardText>
                        </Col>
                      </Row>
        </OffcanvasBody>
      </Offcanvas>
    </>
  );
}

export default ApplyJobsCanvas;
