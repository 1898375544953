import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { sendPostAuthRequest, sendPostRestRequest } from "../apiConfig/axiosInstance";
import { log_debug } from "../apiHelpers/logger";

const initialState = {
  data: {},
  status: "idle",
  loading: false,
  error: "",
};

export const authenticate = createAsyncThunk(
  "Authentication/CompanyBench",
  async (userid) => {
    log_debug("recieved userid", userid);
    const response = await sendPostAuthRequest(
      `v1/user/${userid}/authenticate/cred`,
    );
    return response.data;
  },
);

const AuthenticationSlice = createSlice({
  name: "authenticate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(authenticate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(authenticate.fulfilled, (state, action) => {
        state.status = "idle";
        state.data.authenticateuser = action.payload;
      })
      .addCase(authenticate.rejected, (state, action) => {
        state.status = "idle";
        state.data.authenticateuser = {
          status: "failed ",
          message: `${action.error.name} : ${action.error.message}`,
        };
      });
  },
});

export default AuthenticationSlice.reducer;
