import React, { useEffect, useState } from "react";
import Breadcrumbs from "../components/Common/Breadcrumb";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Badge,
} from "reactstrap";

import DropdownWithTwoField from "../customComponents/DropdownWithTwoField";
import "../../src/assets/css/styleCompanyBench.css";
import DetailsCanvas from "../customComponents/offcanvas/CanvasJobdescription";
import { useDispatch, useSelector } from "react-redux";
import {
  Alljobsdata,
  addCandidatesSkills,
  addCandidatesLocation,
  getFilterAllJobs,
} from "./PartnerSlice";
import CustomTable from "../customComponents/reactTable/CustomTable";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import CanvasPartnerJobDetails from "../customComponents/offcanvas/CanvasPartnerJobDetails";
import { Tags } from "../pages/Contacts/ContactList/contactlistCol";
import NodataFoundTable from "../customComponents/utilities/NodataFoundTable";
import CustomBreadcrumb from "../customComponents/breadcrumb/CustomBreadcrumb";
import { dateyyyymmdd_tohtml } from "../helpers/date";
import JobsBadge from "../customComponents/utilities/JobsBadge";
import { log_debug } from "../apiHelpers/logger";

function PartnerAlljobs() {
  //pagination
  const [currentPage, setCurrentPage] = useState(0);
  // log_debug("currentPage", currentPage);

  const [Alljobs, setAlljobs] = useState([]);
  const [Alljobscount, setAlljobscount] = useState([]);
  const [Skills, setSkills] = useState([]);
  const [Locations, setLocations] = useState([]);
  const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [Alljobsloading, setAlljobsloading] = useState(true);
  const [selectedTechnology, setSelectedTechnology] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [filtervalue, setFiltervalue] = useState("");
  const [SelectedUserType, setSelectedUserType] = useState("");

  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);
  const count = Alljobs.length;

  const yourDynamicHeaderData = {
    full_name: "full_name",
    email: "email",
    contact_no: "contact_no",
    skills: "skills",
    prefered_locations: "prefered_locations",
    experience: "experience",
  };

  const columns = [
    {
      Header: "Job Id",
      accessor: "jobId",
      Cell: ({ row }) => (
        <div className="">
          {row.original?.jobId ? row.original?.jobId : "-"}
        </div>
      ),
    },
    {
      Header: "Jobs",
      accessor: "title",
      Cell: ({ row }) => (
        <Link
          to={"#"}
          onClick={() => {
            candidateName(row.original);
          }}
        >
          {row.values.title}
        </Link>
      ),
    },

    {
      Header: "Skills",
      accessor: "skills",
      // Cell: ({ value }) => value.join(", "),
      // Cell: ({ value }) => (
      //   <div>
      //     {/* <div style={{ whiteSpace: "normal", height: "auto" }}> */}
      //     {value.map((tech, index) => (
      //       <Badge
      //         key={index}
      //         color="primary"
      //         className="bg-primary text-white m-1"
      //       >
      //         {tech}
      //       </Badge>
      //     ))}
      //   </div>
      // ),

      Cell: (cellProps) => {
        return <Tags {...cellProps} />;
      },
    },
    // {
    //   Header: "Job Score",
    //   accessor: "most_viewed_job_score",
    // },
    {
      Header: "Location",
      accessor: "location",
      Cell: ({ row }) => (
        <div className="">
          {row.original.location.length >= 1
            ? row.original.location.join(", ")
            : "-"}
        </div>
      ),

      // Cell: (cellProps) => {
      //   return <Tags {...cellProps} />;
      // },
    },
    {
      Header: "Experience",
      accessor: "minExperience",
      Cell: ({ row }) => (
        <div className="">
          {row.original?.minExperience ? row.original?.minExperience : 0}
        </div>
      ),
      // Cell: ({ value }) => <JobsBadge status={value} />,
    },
    {
      Header: "Created Date",
      accessor: "createdAt",
      Cell: (props) => {
        const tidyDate = dayjs(props.value).format("YYYY-MM-DD");
        return <span>{tidyDate}</span>;
      },
    },
    // {
    //   Header: "Date",
    //   accessor: "updatedAt",
    //   Cell: ({ value }) => (value ? dateyyyymmdd_tohtml(value) : "-"),
    // },

    // {
    //   Header: "Status",
    //   accessor: "status",
    //   Cell: ({ value }) => <JobsBadge status={value} />,
    // },
    // Add more columns as needed
  ];

  const UserTypeOptions = [
    {
      value: "C2C(Contract To Contract)",
      label: "Contract To Contract",
    },
    {
      value: "C2H(Contract To Hiring)",
      label: "Contract To Hiring",
    },
    {
      value: "FTE(Full Time Employment)",
      label: "Full Time Employment",
    },
  ];

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (
        partnerStore.data.CandidatesSkills?.success === true &&
        partnerStore.data.CandidatesLocation?.success === true
      ) {
        setSkills(partnerStore.data.CandidatesSkills?.data?.enumskills);
        setLocations(partnerStore.data.CandidatesLocation?.data?.enumcitys);
      } else {
        dispatch(addCandidatesSkills());
        dispatch(addCandidatesLocation());
      }
    }
  }, []);

  useEffect(() => {
    if (partnerStore.status === "idle") {
      if (partnerStore.data.Alljobsrecords?.success === true) {
        setAlljobsloading(false);
        setAlljobs(partnerStore.data.Alljobsrecords?.data?.jobdescriptions);
        setAlljobscount(partnerStore.data.Alljobsrecords?.data?.totalCount);
      } else if (partnerStore.data.Alljobsrecords?.success === false) {
        setAlljobsloading(false);
        setAlljobs([]);
        setAlljobscount(0);
      }
      if (partnerStore.data.CandidatesSkills?.success === true) {
        setSkills(partnerStore.data.CandidatesSkills?.data?.enumskills);
      }
      if (partnerStore.data.CandidatesLocation?.success === true) {
        setLocations(partnerStore.data.CandidatesLocation?.data?.enumcitys);
      }
    }
  }, [
    partnerStore.data.Alljobsrecords,
    partnerStore.data.CandidatesSkills,
    partnerStore.data.CandidatesLocation,
  ]);
  const skillOptions = Skills.map((item) => ({
    value: item.code,
    label: item.title,
  }));

  const locationOptions = Locations.map((item) => ({
    value: item.code,
    label: item.title,
  }));

  const candidateName = (guid) => {
    setSelectedCandidate(guid);
    setOffcanvasOpen(true);
  };

  const closeOffcanvas = () => {
    setOffcanvasOpen(false);
    // setisOffcanvasOpenBGV(false);
  };

  const handleTechnologyChange = (selectedOptions) => {
    const selectedValues =
      selectedOptions && selectedOptions?.map((option) => option?.label);
    setSelectedTechnology(selectedValues);
  };
  const handleLocationChange = (selectedOptions) => {
    const selectedValues =
      selectedOptions && selectedOptions?.map((option) => option?.label);
    setSelectedLocations(selectedValues);
  };
  const handleUsertypeChange = (selectedOptions) => {
    // log_debug("selectedusertype",selectedOptions)
    setSelectedUserType(selectedOptions?.value)
    // const selectedValues =
    //   selectedOptions && selectedOptions?.map((option) => option?.value);
    // setSelectedUserType(selectedValues);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        Alljobsdata({
          skills: selectedTechnology ? selectedTechnology : "",
          locations: selectedLocations ? selectedLocations : "",
          currentPage: currentPage ? currentPage : currentPage,
          filtervalue: filtervalue ? filtervalue : "",
          jobType : SelectedUserType ? SelectedUserType : "",
        })
      );
    }, 700);

    return () => clearTimeout(timer);
  }, [selectedTechnology, selectedLocations, currentPage, filtervalue,SelectedUserType]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleValueChange = (newValue) => {
    setAlljobsloading(true);
    setFiltervalue(newValue);
  };

  const breadcrumbValues = ["All jobs"];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <CustomBreadcrumb values={breadcrumbValues} />
          <div className="col-lg-12">
            {/* {Alljobs.length >= 1 ? ( */}
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    {/* <Card
                    style={{
                      backgroundColor: "#5156BE",
                      paddingTop: "15px",
                    }}
                  > */}
                    {/* <CardBody> */}
                    <Row>
                      <div className="col-12 col-lg-12 col-md-6 d-sm-flex">
                        <div className="col-12 col-lg-3">
                          <DropdownWithTwoField
                            // label="Option 1"
                            options={skillOptions}
                            placeholder="Select Technology"
                            onSelectChange={handleTechnologyChange}
                          />
                        </div>
                        {/* <div className="col-12 col-lg-3 ms-2">
                          <DropdownWithTwoField
                            options={locationOptions}
                            placeholder="Select locations"
                            onSelectChange={handleLocationChange}
                          />
                        </div> */}
                        <div className="col-12 col-lg-3 mx-2">
                          <DropdownWithTwoField
                            options={UserTypeOptions}
                            placeholder="Select Job Type"
                            singleselect={true}
                            onSelectChange={handleUsertypeChange}
                          />
                        </div>
                        <div className="col-12 col-lg-6 col-md-6 px-4">
                        <Col md={12}>
                          <CardTitle className="page-title text-muted">
                            <span>Total count : </span>
                            <strong>{Alljobscount ? Alljobscount : 0}</strong>
                          </CardTitle>
                        </Col>
                      </div>
                      </div>
                     
                    </Row>
                    {/* </CardBody> */}
                    {/* </Card> */}
                  </div>
                  <div>
                    <Card>
                      <CardBody>
                        <CustomTable
                          // tableDatas={Alljobs}
                          // headerData={yourDynamicHeaderData}
                          totalCount={Alljobscount}
                          columns={columns}
                          data={Alljobs}
                          isLoading={Alljobsloading}
                          hideCheckbox={true}
                          currentPage={currentPage}
                          onPageChange={handlePageChange}
                          onChange={handleValueChange}
                          globalsearch={true}
                        />
                      </CardBody>
                    </Card>
                    {/* <DataTable title="All Jobs" columns={columns} data={jobs} /> */}
                  </div>
                </div>
              </div>
            </>
          </div>
        </Container>
      </div>
      {isOffcanvasOpen && (
        // <DetailsCanvas
        //   usertype="partner"
        //   menuOption="invitation"
        //   width={50}
        //   placement="end"
        //   isOpen={isOffcanvasOpen}
        //   toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
        //   onclose={closeOffcanvas}
        //   data={selectedCandidate}
        // />

        <CanvasPartnerJobDetails
          Alljobsstatus={true}
          width={50}
          placement="end"
          usertype="partner"
          isOpen={isOffcanvasOpen}
          toggle={() => setOffcanvasOpen(!isOffcanvasOpen)}
          onclose={closeOffcanvas}
          data={selectedCandidate}
        />
      )}
    </React.Fragment>
  );
}

export default PartnerAlljobs;
