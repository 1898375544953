import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import { log_debug } from "../../../apiHelpers/logger";
import {useDispatch} from "react-redux"
import axios from "axios";
import { checkPlan, CreateOrder, VerifyPayment } from "../../../Partner/PartnerSlice";

const CreativeProjectMonth = ({ monthData }) => {
  log_debug("monthdata",monthData)

  const [price, setPrice] = useState(0);
  const [users, setUsers] = useState(4);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  useEffect(() => {
    if(monthData && monthData.length > 0){
      const dataprice = monthData[monthData.length - 1];
      setPrice(dataprice?.price);
    }
  },[monthData])

  const handlePriceChange = (e) => {
    log_debug("selectedoption",e.target.value);
    const selectvalue = e.target.value;
    const finalPrice = selectvalue*99+(selectvalue-1);
    setPrice(finalPrice);
    setUsers(Number(e.target.value));
  }


  const handlePayment = async ({price,data}) => {
    log_debug("9384573",data)
    const amount = price * 100; // Amount in currency unit
    const currency = 'INR';
    const planguid = data?.guid;
    const userguid = localStorage.getItem('userguid');
    try {
        // Create order on backend
        // const { data: order } = await axios.post('http://localhost:5000/create-order', {
        //     amount,
        //     currency,
        // });

        dispatch(CreateOrder({amount: amount})).then((response) => {
          const order = response.payload?.data?.order;

          const options = {
            key: 'rzp_test_yOKYbCegxy0WUh',
            amount: order.amount,
            currency: order.currency,
            name: 'Company Bench',
            description: 'Company Bench Buy Plan',
            order_id: order.id,
            handler: function (response) {
                log_debug('Payment successful!', response);
                response.user_guid = userguid;
                response.plan_guid = planguid;
                if (data.name === "Custom") {
                  response.plan_userAccess = users;
              }

                
                dispatch(VerifyPayment({data:response})).then((res) => {
                  log_debug("verified", res.payload?.success);
                  const data = res.payload?.success;
                  if(data){
                    dispatch(checkPlan());
                    navigate("/partner/myplan")
                  }
                });
            },
            // prefill: {
            //     name: 'Customer Name',
            //     email: 'customer@example.com',
            //     contact: '9999999999',
            // },
            // notes: {
            //     address: 'Some Address',
            // },
            theme: {
                color: '#5156BE',
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
        });

        // Razorpay options
        

        // const rzp = new window.Razorpay(options);
        // rzp.open();
    } catch (error) {
        console.error('Error creating order:', error);
    }
};



  return (
    <React.Fragment>
      {(monthData || []).map((month, key) => (
        <Col xl={3} sm={6} key={key}>
          <Card className={month.isFeatured ? "bg-primary mb-xl-0" : "mb-xl-0"}>
            <CardBody className={month.isFeatured ? "shadow-lg" : "shadow-sm"}>
              <div className="p-2">
                {month.isFeatured && (
                  <div className="pricing-badge">
                    <span className="badge">Featured</span>
                  </div>
                )}
                <h5
                  className={
                    month.isFeatured
                      ? "font-size-16 text-white"
                      : "font-size-16 text-primary"
                  }
                >
                  {month.name}
                  <span className="float-end">
                  {month.name === "Custom" && 
                <>
                
                <select onChange={handlePriceChange} className="rounded fw-bold">
                  <option value={4} defaultChecked>4 Users</option>
                  <option value={5}>5 Users</option>
                  <option value={6}>6 Users</option>
                  <option value={7}>7 Users</option>
                  <option value={8}>8 Users</option>
                  <option value={9}>9 Users</option>
                  <option value={10}>10 Users</option>
                </select>
                </>
                } 
                </span>
                </h5>
                
                <h1 className={month.isFeatured ? "mt-3 text-white" : "mt-3"}>
                ₹{month.name === "Custom" ? price: month.price}{" "}
                  <span
                    className={
                      month.isFeatured
                        ? "text-white font-size-16 fw-medium"
                        : "text-muted font-size-16 fw-medium"
                    }
                  >
                    / Month
                  </span>
                  {/* <span className="font-size-16 ms-3">
                  {month.name === "Custom" && 
                <>
                <select onChange={handlePriceChange} className="rounded fw-bold">
                  <option defaultChecked>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                </select>
                </>
                }
                  </span> */}
                </h1>
                
                <p
                  className={
                    month.isFeatured
                      ? "text-white-50 mt-3 font-size-12"
                      : "text-muted-50 mt-3 font-size-12"
                  }
                >
                  {month.description}
                </p>
                <div
                  className={
                    month.isFeatured
                      ? "mt-4 pt-2 text-white"
                      : "mt-4 pt-2 text-muted"
                  }
                >
                  {month?.features.map((feature,index) => (<div key={index} className="d-flex">
                  <i
                      className={
                        month.isFeatured
                          ? "mdi mdi-check-circle text-success font-size-18 me-2"
                          : "mdi mdi-check-circle text-success font-size-18 me-2"
                      }
                    ></i>
                    <p  className="mb-3 font-size-13  ">
                    
                    {feature}
                  </p>
                 </div>))}
                </div>

                <div className="mt-4 pt-2">
                  <Link
                    onClick={() => handlePayment({price: month.name === "Custom" ? price : month.price, data: month})}
                    className={
                      month.isFeatured
                        ? "btn btn-light w-100"
                        : "btn btn-primary w-100"
                    }
                  >
                    Choose Plan
                  </Link>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default CreativeProjectMonth;
