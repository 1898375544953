import React, { useEffect, useState } from "react";
import { FaCircle } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Progress,
  Row,
} from "reactstrap";
import { checkPlan } from "./PartnerSlice";
import { log_debug } from "../apiHelpers/logger";
import dayjs from "dayjs";
import { calculateDaysToExpiry } from "../helpers/date";

function MyPlans() {

  const dispatch = useDispatch();
  const partnerStore = useSelector((state) => state.partnerSlice);
  const [activeUsercount, setActiveUserCount] = useState(0);
  const [plandData, setPlanData] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [progressBarValue, setProgressBarValue] = useState(0);
  const [expiredDate, setExpiredDate] = useState("");

  useEffect(() => {
    dispatch(checkPlan());
  },[dispatch])

  useEffect(() => {
    log_debug("plandetails", partnerStore?.data?.PlanDetails)
    if (partnerStore.status === "idle") {
    if(partnerStore?.data?.PlanDetails?.success === true){
      setActiveUserCount(partnerStore?.data?.PlanDetails?.data?.createdUsersCount);
      setExpiredDate(partnerStore?.data?.PlanDetails?.data?.activePlanData[0]?.expiredDate);
      log_debug("875348",partnerStore?.data?.PlanDetails?.data?.activePlanData[0].expiredDate);
      setPlanData(partnerStore?.data?.PlanDetails?.data?.activePlanData[0]?.planData);
      setTotalUsers(partnerStore?.data?.PlanDetails?.data?.activePlanData[0]?.userAccessAllowed);
    }
    }
  },[partnerStore?.data?.PlanDetails])

  useEffect(() => {
    const value = (activeUsercount * 100)/totalUsers;
    log_debug("activeUsers",value)
    setProgressBarValue(value.toFixed());
  },[activeUsercount,totalUsers])



  return (
    <React.Fragment>
      {/* {loading && <Preloader/>} */}
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Partner" breadcrumbItem="Pricing" /> */}
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h3>Plan & Billing</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <h4>Current plan</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={6}>
                      <Card className="rounded-4">
                        <CardBody>
                          <Row>
                            <Col xl={6}>
                              <p className="fw-bold text-muted">
                                {plandData?.name}
                              </p>
                              <p className="fw-bold fs-3">₹ {plandData?.price} {"/"}{plandData?.type}</p>
                            </Col>
                            <Col xl={6} className="text-end">
                              <Badge color="rounded-pill rounded-5 bg-success-subtle text-success p-2 fs-6">
                                <span>
                                  <i className="fa fa-circle fa-1 ms-2 font-size-10"></i>
                                </span>
                                <span className="mx-2">Active</span>
                              </Badge>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col xl={6}>
                      <Card className="rounded-4">
                        <CardBody>
                          <Row>
                            <Col xl={6}>
                              <p className="fw-bold text-muted">Expiry on</p>
                            </Col>
                            <Col xl={6} className="text-end">
                              <Badge color="rounded-pill rounded-5 bg-danger-subtle  opacity-75 py-2 px-3 fs-6">
                                <span className="text-danger">
                                  {calculateDaysToExpiry(expiredDate)}{" days left"}
                                </span>
                              </Badge>
                            </Col>
                          </Row>

                          <p className="fw-bold fs-3 text-warning">
                          {dayjs(expiredDate).format("MMM DD, YYYY")}
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={12}>
                      <h4>Usage</h4>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={4}>
                      <Card className="rounded-4">
                        <div className="p-3">
                          <i className="fa fa-users fs-1"></i>
                          <p className="text-muted mt-4 mb-1 fw-bold">Users</p>
                          <p className="fw-bold">{activeUsercount} of {totalUsers}</p>
                          <Progress color="success" value={progressBarValue}></Progress>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default MyPlans;
